import React from "react";
import { useState, useEffect } from "react";
import {Dialog} from "@material-ui/core";
import {Toolbar} from "@material-ui/core";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {List} from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import PackageType from "../PackageType/PackageType";
import CouponIcon from "../../_assets/plugins/keenthemes-icons/svg/Add_Coupon_Icon.png";
import AddCouponIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import AddCouponPerImg from "../../_assets/plugins/keenthemes-icons/svg/couponPertageimg.png";
import ActiveCouponIcon from "../../_assets/plugins/keenthemes-icons/svg/Active_Coupons_Icon.png";
import RecommedCouponIcon from "../../_assets/plugins/keenthemes-icons/svg/Coupons_Redeemed_Icon.png";
import EditUser from "../../_assets/plugins/keenthemes-icons/svg/editIcon.png";
import UserPlusIcon from "../../_assets/plugins/keenthemes-icons/svg/Plus-icon.png";
export default function Coupon() {
  const [couponData, setCouponData] = useState({
    name: "",
    type: "",
    value: "",
    expiryDate: "",
    code: "",
    unlimited: "",
    status: "",
  });

  const [addCoupon, setAddCoupon] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllCoupon, setgetAllCoupon] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState();
  const [couponIdForDelete, setCouponIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);
  const [packageType, setPackageType] = useState({});
  const [openPackgeType, setOpenPackageType] = useState(false);
  const [forQuantity, setForQuantity] = useState(false);

  useEffect(() => {
    getAllcouponData();
    getPackageType();
  }, [page, countPerPage]);

  const getPackageType = async () => {
    await ApiGet(`packageType/find`).then((res) => {
      setPackageType(res?.data?.payload);
    });
  };

  const addCouponModelClose = (e) => {
    setAddCoupon(false);
    setIsEditApi(false);
    setErrors(false);
    setCouponData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setCouponData({ ...couponData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (
      !couponData?.name ||
      couponData?.name === "" ||
      !couponData?.name?.trim()
    ) {
      formvalied = false;
      errors["name"] = "*Please enter Name";
    }
    if (!couponData?.type || couponData?.type === "") {
      formvalied = false;
      errors["type"] = "*Please enter Type";
    }
    if (!couponData?.value) {
      formvalied = false;
      errors["value"] = "*Please enter Value";
    }
    if (forQuantity === false) {
      if (!couponData?.limit) {
        formvalied = false;
        errors["limit"] = "*Please enter Limit";
      }
    }
    if (!couponData?.expiryDate || couponData?.expiryDate === "") {
      formvalied = false;
      errors["expiryDate"] = "*Please enter ExpiryDate";
    }
    if (
      !couponData?.code ||
      couponData?.code === "" ||
      couponData?.code?.length < 5
    ) {
      formvalied = false;
      errors["code"] = "*Please enter valied code";
    }
    if (isEditApi === true) {
      if (!couponData?.status || couponData?.status === "") {
        formvalied = false;
        errors["status"] = "*Please select status.";
      }
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };
  const generateRandomNumber = (min, max) => {
    var min = 10000;
    var max = 90000;
    var RandomNumber = Math.floor(Math.random() * min) + max;
    setCouponData({
      name: couponData?.name,
      type: couponData?.type,
      value: couponData?.value,
      expiryDate: couponData?.expiryDate,
      code: RandomNumber,
      unlimited: couponData?.unlimited,
      status: couponData?.status,
    });
  };

  const getAllcouponData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : "";
    await ApiGet(
      `coupon/get-coupon?page=${page}&limit=${countPerPage}&days=30${url}`
    )
      .then((res) => {
        setgetAllCoupon(res?.data?.payload);
        setCount(res?.data?.count);
        setActiveCount(res?.data?.activeCount);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const addcouponData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let body = {
        name: couponData?.name,
        type: couponData?.type,
        value: couponData?.value,
        expiryDate: couponData?.expiryDate,
        code: couponData?.code,
        ...(couponData?.limit && {
          limit: couponData?.limit,
          unlimited: false,
        }),
      };
      let body1 = {
        name: couponData?.name,
        type: couponData?.type,
        value: couponData?.value,
        code: couponData?.code,
        expiryDate: couponData?.expiryDate,
        ...(forQuantity === true && {
          unlimited: true,
        }),
      };

      await ApiPost(
        `coupon/create-coupon`,
        forQuantity === false ? body : body1
      )
        .then((res) => {
          toast.success("Coupon add successfully");
          setAddCoupon(false);
          setIsEditApi(false);
          getAllcouponData();
          setCouponData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editcouponData = async (e) => {
    if (formValidation()) {
      setLoader(true);
      let body = {
        name: couponData?.name,
        type: couponData?.type,
        value: couponData?.value,
        expiryDate: couponData?.expiryDate,
        code: couponData?.code,
        status: couponData?.status,
        ...(couponData?.limit && {
          limit: couponData?.limit,
          unlimited: false,
        }),
      };
      let body1 = {
        name: couponData?.name,
        type: couponData?.type,
        value: couponData?.value,
        code: couponData?.code,
        status: couponData?.status,
        expiryDate: couponData?.expiryDate,
        ...(forQuantity === true && {
          unlimited: true,
        }),
      };

      await ApiPut(
        `coupon/update-coupon?id=${categoryIdForEdit}`,
        forQuantity === false ? body : body1
      )
        .then((res) => {
          toast.success("Coupon update successfully");
          getAllcouponData();
          setAddCoupon(false);
          setIsEditApi(false);
          setCouponData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const deletecouponData = async () => {
    await ApiDelete(`coupon/delete-coupon?id=${couponIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Coupon delete successfully");
          setShow(false);
          getAllcouponData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllcouponData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllcouponData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type?.type,
      sortable: true,
    },
    {
      name: "Coupon Limit",
      selector: (row) => (row?.unlimited === true ? "Unlimited" : row?.limit),
      sortable: true,
    },

    {
      name: "Coupon Value",
      selector: (row) => row?.value,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "active" && (
              <div
                style={{
                  background: "#22cc00",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Active
                </p>
              </div>
            )}
            {row?.status === "paused" && (
              <div
                style={{
                  background: "gray",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Paused
                </p>
              </div>
            )}
            {row?.status === "expired" && (
              <div
                style={{
                  background: " red",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Expired
                </p>
              </div>
            )}

            {row?.status === "outofstock" && (
              <div
                style={{
                  background: "yellow",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Out of Stoke
                </p>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },

    {
      name: "ExpiryDate",
      selector: (row) => moment(row?.expiryDate).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Edit",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setAddCoupon(true);
                  setCategoryIdForEdit(row?._id);
                  setForQuantity(row?.unlimited);
                  setCouponData({
                    name: row?.name?.toString(),
                    type: row?.type?._id?.toString(),
                    value: row?.value?.toString(),
                    code: row?.code,
                    limit: row?.limit?.toString(),
                    expiryDate: moment(row?.expiryDate).format("yyyy-MM-DD"),
                    status: row?.status,
                  });
                }}
              >
                <Tooltip title="edit coupon" arrow>
                  <button
                    style={{
                      background: "#002a5a",
                      color: "white",
                      borderRadius: "20px",
                      border: "2px solid #939393",
                      padding: "5px 10px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) => {
                      setIsEditApi(true);
                      setAddCoupon(true);
                      setCategoryIdForEdit(row?._id);
                      setForQuantity(row?.unlimited);
                      setCouponData({
                        name: row?.name?.toString(),
                        type: row?.type?._id?.toString(),
                        value: row?.value?.toString(),
                        code: row?.code,
                        limit: row?.limit?.toString(),
                        expiryDate: moment(row?.expiryDate).format(
                          "yyyy-MM-DD"
                        ),
                        status: row?.status,
                      });
                    }}
                  >
                    Manage
                  </button>
                </Tooltip>
              </div>

              {/* <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCouponIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete coupon" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div> */}
            </div>
          </>
        );
      },
      width: "10%",
    },

    {
      name: "Delete",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCouponIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete coupon" arrow>
                  <button
                    style={{
                      background: "#ff0000",
                      color: "white",
                      borderRadius: "20px",
                      border: "2px solid #939393",
                      padding: "5px 10px",
                    }}
                  >
                    Delete
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img
              src={ActiveCouponIcon}
              alt="ActiveCouponIcon"
              className="coupon"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">{activeCount}</h1>
              <h6 className="text-white">Active Coupons</h6>
            </div>
          </div>

          <div className="align-items-center d-flex ml-40">
            <img
              src={RecommedCouponIcon}
              alt="RecommedCouponIcon"
              className="coupon"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">217</h1>
              <h6 className="text-white">Coupons Redeemed</h6>
            </div>
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className=" d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Coupons</h2> */}
              {/* <div className="btn generate-random-code">
                <button onClick={() => setOpenPackageType(true)}>
                  create coupon type
                </button>
              </div> */}
            </div>
            <div className="cus-medium-button-style button-height  button-details-alignment">
              <button
                className="btn position-relative center"
                type="button"
                onClick={() => {
                  setAddCoupon(true);
                }}
              >
                <img src={AddCouponIcon} alt={AddCouponIcon} />
                <img src={AddCouponPerImg} alt={AddCouponPerImg} />
                Add Coupon
              </button>
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllCoupon}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Coupon?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deletecouponData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addCoupon === true ? (
        <Dialog fullScreen open={addCoupon}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addCouponModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="heading-banner d-flex justify-content-center">
              {isEditApi === true ? (
                <img src={EditUser} alt="EditUser" className="coupon mr-4" />
              ) : (
                <img
                  src={UserPlusIcon}
                  alt="UserPlusIcon"
                  className="coupon mr-4"
                />
              )}
              <img src={CouponIcon} alt="CouponIcon" className="coupon mr-4" />
            </div>
            <div className="m-auto w-1000px pt-6">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="name"
                      name="name"
                      value={couponData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  select type
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      name="type"
                      id="type"
                      defaultValue={couponData?.type}
                      className="form-control rounded-lg custom-style"
                      aria-label="Default select example"
                      onChange={(e) => onhandleChange(e)}
                    >
                      <option value="select type" selected disabled>
                        select type
                      </option>
                      {packageType &&
                        packageType?.map((data) => (
                          <option value={data._id}>{data?.type}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Value
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="value"
                      name="value"
                      value={couponData?.value}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["value"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  ExpiryDate
                  <p style={{ color: "gray", fontSize: "14px" }}>
                    (coupons expire at midnight on this date)
                  </p>
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="date"
                      className={`form-control rounded-lg custom-style`}
                      id="expiryDate"
                      name="expiryDate"
                      value={couponData?.expiryDate}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["expiryDate"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Coupon Code
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style `}
                      id="code"
                      name="code"
                      value={couponData?.code}
                      minLength="5"
                      maxLength="10"
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <div className="pt-2 text-center or-text">
                    <span>Or</span>
                  </div>

                  <div className="generate-random-code pt-2 d-flex justify-content-center">
                    <button onClick={() => generateRandomNumber()}>
                      Generate Random Code
                    </button>
                  </div>

                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["code"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Quantity
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div className="row">
                    <div className="check-box-alignment col-lg-5 col-xl-5">
                      <input
                        type="checkbox"
                        id="unlimited"
                        name="unlimited"
                        checked={forQuantity}
                        onClick={() => setForQuantity(!forQuantity)}
                      />
                      <label for="unlimited">unlimited</label>
                    </div>
                    {/* <div className="col-lg-7 col-xl-7 pl-10">
                      <input
                        type="text" value="Amount"
                        className={`form-control form-control-lg rounded-lg custom-style `}
                      />
                    </div> */}
                  </div>

                  {forQuantity === false && (
                    <div className="form-group row pt-10">
                      <label className="col-xl-5 col-lg-5 col-form-label label-alignment mt-0">
                        Limit
                      </label>
                      <div className="col-lg-7 col-xl-7">
                        <div>
                          <input
                            type="number"
                            className={`form-control rounded-lg custom-style`}
                            id="limit"
                            name="limit"
                            value={couponData?.limit}
                            onChange={(e) => {
                              onhandleChange(e);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["limit"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {isEditApi === true && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Status
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <select
                        className={`form-control rounded-lg custom-style`}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                        defaultValue={couponData?.status}
                        name="status"
                      >
                        <option value="select for status" selected disabled>
                          {" "}
                          select status{" "}
                        </option>
                        <option value="active">active</option>
                        <option value="expired">expired</option>
                        <option value="outofstock">outofstock</option>
                        <option value="paused">paused</option>
                      </select>
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["winningType"]}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center  button-details-alignment">
                    <button className="btn" type="button">
                      <img src={AddCouponIcon} alt={AddCouponIcon} />
                      <img src={AddCouponPerImg} alt={AddCouponPerImg} />
                      {isEditApi ? "Edit Coupon" : "Add Coupon"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className=" button-details-alignment">
                  <button
                    className="btn"
                    onClick={(e) => {
                      isEditApi ? editcouponData(e) : addcouponData(e);
                    }}
                  >
                    <img src={AddCouponIcon} alt={AddCouponIcon} />
                    <img src={AddCouponPerImg} alt={AddCouponPerImg} />
                    {isEditApi ? "Edit Coupon" : "Add Coupon"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
      {openPackgeType === true ? (
        <>
          <Dialog fullScreen open={openPackgeType}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpenPackageType(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <List>
              <>
                <PackageType />
              </>
            </List>
          </Dialog>
        </>
      ) : null}
    </>
  );
}
