import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import { getUserInfo } from "../../../utils/user.util";

export default function ReservationReport(props) {
  const [getAllOrder, setGetAllOrder] = useState([]);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [downloadLink, setDownloadLink] = useState();
  let item = localStorage.getItem("localStoreId");
  item = item && JSON.parse(item);
  const userInfo = getUserInfo();

  useEffect(() => {
    getAllOrderData();
    reservationReportDownload();
  }, [page, countPerPage]);

  const handelcomplete = async (value) => {

    await ApiGet(
      userInfo?.role === "owner"
      ? `store/get-ordered-store-items?storeId=${(item).trim()}&page=${page}&limit=${countPerPage}&status=${value}`
      : `order/find?page=${page}&limit=${countPerPage}&status=${value}`
    ).then((res) => {
      setGetAllOrder(res?.data?.data);
    });

    await ApiGet(
      userInfo?.role === "owner"
      ? `store/download-report?storeId=${(item).trim()}&page=${page}&limit=${countPerPage}`
      : `order/order-report?page=${page}&limit=${countPerPage}&status=${value}`
    )
      .then((res) => {
        setDownloadLink(res?.data?.downloadLink);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const getAllOrderData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : ""
      await ApiGet(
        userInfo?.role === "owner"
          ? `store/get-ordered-store-items?storeId=${(item).trim()}&page=${page}&limit=${countPerPage}${url}`
          : `order/find?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllOrder(res?.data?.data);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);  
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Item name",
      selector: (row) =>
        row?.Item[0]?.itemId?.itemName ? row?.Item[0]?.itemId?.itemName : "-",
      sortable: true,
    },

    {
      name: "Item description",
      selector: (row) => {
        return (
          <div className="showmore-class  p-2 ">
            {row?.Item[0]?.itemId?.itemDescription
              ? row?.Item[0]?.itemId?.itemDescription?.length > 200
                ? row?.Item[0]?.itemId?.itemDescription?.substring(0, 200) +
                  "..."
                : row?.Item[0]?.itemId?.itemDescription
              : "-"}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Item price",
      selector: (row) =>
        row?.Item[0]?.itemId?.itemPrice ? row?.Item[0]?.itemId?.itemPrice : "-",
      sortable: true,
    },
    {
      name: "Total price",
      selector: (row) => (row?.totalAmount ? row?.totalAmount : "-"),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "pending" && (
              <span class="btn" style={{ background: "yellow" }}>
                {row?.status}
              </span>
            )}
            {row?.status === "canceled" && (
              <span class="btn btn-danger">{row?.status}</span>
            )}
            {row?.status === "complete" && (
              <span class="btn" style={{ background: " #22cc00" }}>
                {row?.status}
              </span>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row?.address ? row?.address : "-"),
      sortable: true,
    },
    {
      name: "city",
      selector: (row) => (row?.city ? row?.city : "-"),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => (row?.state ? row?.state : "-"),
      sortable: true,
    },
  ];

  const reservationReportDownload = async () => {
    await ApiGet(
      userInfo?.role === "owner"
        ? `order/order-report?storeId=${(item).trim()}&page=${page}&limit=${countPerPage}`
        : `order/order-report?page=${page}&limit=${countPerPage}`
    )
      .then((res) => {
        setDownloadLink(res?.data?.data);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Reservations</h2>
            </div>
            <div className="col">
              <label for="cars">Status:</label>

              <select
                name="cars"
                id="cars"
                onChange={(e) => {
                  handelcomplete(e.target.value);
                }}
              >
                <option
                  // value="complete"
                  name="Select"
                >
                  Select
                </option>
                <option
                  value="complete"
                  name="complete"
                >
                  Complete
                </option>
                <option
                  value="canceled"
                  name="canceled"
                >
                  Canceled
                </option>
                <option
                  value="pending"
                  name="pending"
                >
                  Panding
                </option>
                <option
                  value=""
                  name=""
                >
                  All
                </option>
              </select>
            </div>
            <div className="col">
              {/* <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div> */}
               <a href={downloadLink}>
              <button className="btn btn-color mr-2">Download</button>
            </a>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllOrder}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
    </>
  );
}
