import React from "react";
import { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import AddGameIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import GameIcon from "../../_assets/plugins/keenthemes-icons/svg/rupeeIcon.png";
import WinningIcon from "../../_assets/plugins/keenthemes-icons/svg/cupIcon.png";
import CompetitionIcon2 from "../../_assets/plugins/keenthemes-icons/svg/D_Competitions.png";
import AddCompetitionIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";

export default function GameCompetition(props) {
  const { gameIdForCompitition } = props;
  const [compititionData, setCompititionData] = useState({});
  const [errors, setErrors] = useState({});
  const [getAllCompitition, setGetAllCompitition] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [compititionIdForEdit, setCompititionIdForEdit] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [gameData, setGameData] = useState({});
  const [gameData1, setGameData1] = useState([]);
  const [gameImage, setGameImage] = useState([]);
  const [editImg, setEditImg] = useState();
  const [addGame, setAddGame] = useState(false);
  const [gameIdForEdit, setGameIdForEdit] = useState();
  const [openAction, setOpenAction] = useState(false);
  const [getCategoryData, setGetCategoryData] = useState([]);
  const [gameIdForDelete, setgameIdForDelete] = useState();
  const [show, setShow] = useState(false);
  const [competitionData, setCompetitionData] = useState([]);
  const [showCompetitionData, setShowCompetitionData] = useState([]);
  const [competitionDataModel, setCompetitionDataModel] = useState(false);
  const [defineIndeKey, setDefineIndexKey] = useState();
  const [showCompetition, setShowCompetition] = useState(false);
  const [indexValue, setIndexValue] = useState();

  

  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;

    setGameData({ ...gameData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setCompititionData({ ...compititionData, [name]: e.target.files[0] });
    // setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };
  const handleOnChnageAddImgCompetition = (e) => {
    const { name } = e.target;
    setShowCompetitionData({
      ...showCompetitionData,
      [name]: e.target.files[0],
    });
    // setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  useEffect(() => {
    getAllGame();
    getAllCategory();
  }, []);

  const getAllGame = async () => {
    await ApiGet(`game/find?limit=${100}`)
      .then((res) => {
        setGameData1(res?.data?.payload);
        setCompetitionData(res?.data?.payload?.game);
      })

      .catch((err) => {});
  };

  const handleSomthing = (index) => {
     setIndexValue(index)
  }

  const getAllCategory = async () => {
    await ApiGet(`gameType/find`)
      .then((res) => {
        setGetCategoryData(res?.data?.payload);
      })
      .catch((err) => {});
  };

  const editgameData = async (e) => {
    // if (formValidation()) {
    let formData = new FormData();
    formData.append("name", gameData?.name);
    let fileArr = Array.from(gameImage ?? editImg);
    fileArr.forEach((file) => {
      formData.append("pictures", file);
    });
    formData.append("iosStoreLink", gameData?.iosStoreLink);
    formData.append("androidStoreLink", gameData?.androidStoreLink);
    formData.append("description", gameData?.description);
    formData.append("geoSeries", gameData?.geoSeries === true ? true : false);
    setLoader(true);

    await ApiPut(`game/update?id=${gameIdForEdit}`, formData)
      .then((res) => {
        toast.success("Game update successfully");
        getAllGame();
        setAddGame(false);
        setIsEditApi(false);
        setGameData([]);
        setLoader(false);
        setGameImage([]);
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
        setLoader(false);
      });
    // }
  };

  const deletegameData = async () => {
    await ApiDelete(`game/delete?id=${gameIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Delete Game successfully");
          setShow(false);
          getAllGame();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  const addGameModelClose = (e) => {
    setAddGame(false);
    setIsEditApi(false);
    setErrors(false);
    setGameData({});
  };
  const handleClose = () => {
    setShow(false);
  };
  const competitionDataModelClose = (e) => {
    setCompetitionDataModel(false);
  };

  const onhandleChangeofCompetition = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;

    setShowCompetitionData({ ...showCompetitionData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "GeoSeries") {
      setShowCompetitionData({
        ...showCompetitionData,
        [name]: e.target.checked,
      });
      return;
    }
    setShowCompetitionData({ ...showCompetitionData, [name]: value });
  };

  const editPackageData = async (e) => {
    let formData = new FormData();
    formData.append("gameId", showCompetitionData?.gameId);
    formData.append("name", showCompetitionData?.name);
    formData.append("description", showCompetitionData?.description);
    formData.append("image", showCompetitionData?.image);
    formData.append("entryFee", showCompetitionData?.entryFee);
    formData.append(
      "startingDate",
      moment(showCompetitionData?.startingDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSSS[Z]"
      )
    );
    formData.append(
      "endingDate",
      moment(showCompetitionData?.endingDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSSS[Z]"
      )
    );
    formData.append("prizePool", showCompetitionData?.prizePool);
    formData.append("prizeAmount", showCompetitionData?.prizeAmount);
    formData.append("winnerCount", showCompetitionData?.winnerCount);
    formData.append("winningType", showCompetitionData?.winningType);
    formData.append(
      "GeoSeries",
      showCompetitionData?.GeoSeries === true ? true : false
    );
    formData.append(
      "diamond",
      showCompetitionData?.diamond === "diamond" ? true : false
    );
    formData.append(
      "platinum",
      showCompetitionData?.diamond === "platinum" ? true : false
    );
    formData.append(
      "gold",
      showCompetitionData?.diamond === "gold" ? true : false
    );
    formData.append(
      "silver",
      showCompetitionData?.diamond === "silver" ? true : false
    );
    formData.append(
      "bronze",
      showCompetitionData?.diamond === "bronze" ? true : false
    );
    formData.append(
      "iron",
      showCompetitionData?.diamond === "iron" ? true : false
    );
    formData.append(
      "stone",
      showCompetitionData?.diamond === "stone" ? true : false
    );
    formData.append(
      "wood",
      showCompetitionData?.diamond === "wood" ? true : false
    );
    formData.append(
      "paper",
      showCompetitionData?.diamond === "paper" ? true : false
    );

    setLoader(true);
    await ApiPut(`competation/update?id=${compititionIdForEdit}`, formData)
      .then((res) => {
        toast.success("competition update successfully");
        // competitionDataModel();
        setCompetitionDataModel(false);
        getAllGame();
        setIsEditApi(false);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
        setLoader(false);
      });
  };

  const deletePackageData = async () => {
    await ApiDelete(`competation/delete?id=${compititionIdForEdit}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("competition  delete successfully");
          setShowCompetition(false);
          getAllGame();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
      });
  };

  //For Tied Method
  const handlePrizePool = (winnerCount, prizepool) => {
    var amount = prizepool;
    var per = 100;
    var answerArray = [];
    var total = 0;
    for (let index = 1; index <= winnerCount; index++) {
      if (index <= winnerCount - 1) {
        amount = amount / 2;
        per = per / 2;
        answerArray.push({ amount: amount, percentage: per });
        total = total + amount;
      } else {
        answerArray.push({ amount: amount, percentage: per });
        total = total + amount;
      }
    }
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-lg-5 col-form-label label-alignment"></label>

        <div className="col-lg-7 col-xl-7">
          <h4>
            winnner count={winnerCount} <br />
            prize amount={prizepool}
            <div className="game-table pt-4">
              <div className="d-flex justify-content-between">
                <p>Sr.no</p>
                <p>Prize</p>
                <p>Percentage</p>
              </div>

              {answerArray.map((data, i) => (
                <div className="d-flex justify-content-between">
                  <>
                    <p>{i + 1}</p>
                    <p>{data.amount}</p>
                    <p>{data.percentage}</p>
                  </>
                </div>
              ))}

              {/*               
                <p>
                  ------
                </p>
            */}
            </div>
          </h4>
        </div>
      </div>
    );
  };

  //For Even Method
  const handlePrizePoolEven = (winnerCount, prizepool) => {
    var amount = prizepool / winnerCount;
    var per = 100 / winnerCount;
    var answerArray = [];
    for (let index = 1; index <= winnerCount; index++) {
      if (index <= winnerCount) {
        answerArray.push({ amount: amount, percentage: per });
      }
    }

    return (
      <div className="form-group row">
        <label className="col-xl-5 col-lg-5 col-form-label label-alignment"></label>
        <div className="col-lg-7 col-xl-7">
          <h4>
            winnner count={winnerCount ? winnerCount : "0"} <br />
            prize amount={prizepool ? prizepool : "0"}
            <div className="game-table pt-4">
              <div className="d-flex justify-content-between">
                <p>Sr.no</p>
                <p>Prize</p>
                <p>Percentage</p>
              </div>

              {answerArray.map((data, i) => (
                <div className="d-flex justify-content-between">
                  <>
                    <p>{i + 1}</p>
                    <p>{data.amount}</p>
                    <p>{data.percentage}</p>
                  </>
                </div>
              ))}

              {/*               
                <p>
                  ------
                </p>
            */}
            </div>
          </h4>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {/* <div className="d-flex">
          <div className="mr-10"> */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            If you delete this game, lost all the compititions data of this game
            , are you sure that you want to delete ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => deletegameData()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showCompetition} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Alert!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure that you want to delete this competition?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowCompetition(false)}
            >
              No
            </Button>
            <Button variant="danger" onClick={() => deletePackageData()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {gameData1?.map((data, index) => {
          return (
            <>
              <div className="d-flex mb-20">
                <div
                  className="card-details w-50 m-10 p-5"
                  style={{ height: "fit-content" }}
                >
                  <div className="galleryCom-grid-items">
                    <div className="card-image">
                      <img src={data?.pictures[0]} alt="img" />
                    </div>
                  </div>
                  <div>
                    <div className="d-flex ">
                      <p className="badge badge-warning">
                        {" "}
                        <a
                          style={{ color: "black" }}
                          href={data?.iosStoreLink}
                          target="_blank"
                        >
                          iOS
                        </a>
                      </p>
                      <p className="badge badge-warning ml-4">
                        <a
                          style={{ color: "black" }}
                          href={data?.androidStoreLink}
                          target="_blank"
                        >
                          android
                        </a>
                      </p>
                    </div>
                    {data?.geoSeries === true && (
                      <div
                        className="ml-4 cus-medium-button-style button-details-alignment d-flex justify-content-center"
                        style={{ marginBottom: "1rem" }}
                      >
                        <button
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            padding: "5px",
                          }}
                        >
                          <img src={WinningIcon} alt={WinningIcon} />
                          <img
                            src={GameIcon}
                            alt="GameIcon"
                            className="child-img"
                          />
                          Geo-Series
                        </button>
                      </div>
                    )}

                    <h1>{data?.name}</h1>

                    <p>
                      {" "}
                      {data?.description?.length > 50
                        ? data?.description?.substring(0, 50) + "..."
                        : data?.description}
                    </p>
                  </div>
                  <div className="game-action-button-alignment d-flex justify-content-center">
                                  <button
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {data?.category}
                                  </button>
                                </div>

                  {/* <div
                    className="d-flex dropdown justify-content-end"
                    onClick={(e) => {
                      setOpenAction(!openAction);
                      setDefineIndexKey(index);
                    }}
                  >
                    <div>
                      <svg
                        width="4"
                        height="16"
                        cursor="pointer"
                        viewBox="0 0 4 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.96721 3.93443C3.05367 3.93443 3.93443 3.05367 3.93443 1.96721C3.93443 0.880751 3.05367 0 1.96721 0C0.880751 0 0 0.880751 0 1.96721C0 3.05367 0.880751 3.93443 1.96721 3.93443Z"
                          fill="#606060"
                        />
                        <path
                          d="M1.96721 9.96763C3.05367 9.96763 3.93443 9.08688 3.93443 8.00042C3.93443 6.91395 3.05367 6.0332 1.96721 6.0332C0.880751 6.0332 0 6.91395 0 8.00042C0 9.08688 0.880751 9.96763 1.96721 9.96763Z"
                          fill="#606060"
                        />
                        <path
                          d="M1.96721 16.0008C3.05367 16.0008 3.93443 15.1201 3.93443 14.0336C3.93443 12.9472 3.05367 12.0664 1.96721 12.0664C0.880751 12.0664 0 12.9472 0 14.0336C0 15.1201 0.880751 16.0008 1.96721 16.0008Z"
                          fill="#606060"
                        />
                      </svg>
                    </div>
                    {defineIndeKey === index && openAction === true && (
                      <ul className="dropdown-main">
                        <li className="nav-items">
                          <span
                            className="active"
                           
                          >
                            Edit
                          </span>
                          <span className="text-danger">
                            Delete
                          </span>
                        </li>
                      </ul>
                    )}
                  </div> */}
                </div>

                <div className="table-details-alignment">

                  <table>
                    <thead>
                      <tr>
                        <th>Competition Name</th>
                        <th>Competition Duration</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Prize Pool</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.game?.map((item, index) => {
                        const endingDate = moment.utc(item?.endingDate);
                        const startingDate = moment.utc(item?.startingDate);
                        
                        const diffInMinutes = endingDate.diff(startingDate, "minutes");
                        const diffInHours = endingDate.diff(startingDate, "hours");
                        const diffInDays = endingDate.diff(startingDate, "days");
                        const diffInMonths = endingDate.diff(startingDate, "months");
                        
                        let displayText = "";
                        if (diffInMinutes < 60) {
                          displayText = `${diffInMinutes} minute${diffInMinutes === 1 ? "" : "s"} ago`;
                        } else if (diffInHours < 24) {
                          displayText = `${diffInHours} hour${diffInHours === 1 ? "" : "s"} ago`;
                        } else if (diffInDays < 30) {
                          displayText = `${diffInDays} day${diffInDays === 1 ? "" : "s"} ago`;
                        } else {
                          displayText = `${diffInMonths} month${diffInMonths === 1 ? "" : "s"} ago`;
                        }
                        return (
                          <>
                            {item?.isDeleted === false ? (
                              <>
                              <tr>
                                <td>{item?.name}</td>
                                <td>{displayText}</td>
                                <td>
                                  {moment(item?.startingDate).format(
                                    "Do MMM YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.endingDate).format(
                                    "Do MMM YYYY"
                                  )}
                                </td>
                                <td>
                                  {item?.prizeAmount ? item?.prizeAmount : "0"}
                                </td>

                                <td className="d-flex">
                                  {" "}
                                  <Tooltip title="edit competition" arrow>
                                    <button
                                      style={{
                                        color: "#f14f4f",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        fontWeight: 500,
                                        padding: "0px 10px",
                                      }}
                                      onClick={(e) => {
                                        setIsEditApi(true);
                                        setCompititionIdForEdit(item?._id);
                                        setCompetitionDataModel(true);
                                        setShowCompetitionData({
                                          gameId:data?._id,
                                          name: item?.name,
                                          image:item?.image,
                                          description: item?.description,
                                          entryFee: item?.entryFee,
                                          prizeAmount: item?.prizeAmount,
                                          startingDate: moment.utc(
                                            item?.startingDate
                                          ).format("yyyy-MM-DDTHH:mm"),
                                          endingDate: moment.utc(
                                            item?.endingDate
                                          ).format("yyyy-MM-DDTHH:mm"),
                                          payOutDate: moment(
                                            item?.payOutDate
                                          ).format("yyyy-MM-DDTHH:mm"),
                                          winnerCount: item?.winnerCount,
                                          prizePool: item?.prizePool,
                                          prizeAmount:
                                            item?.prizePool === "increment"
                                              ? "0"
                                              : item?.prizeAmount,
                                          winningType: item?.winningType,
                                          GeoSeries: item?.GeoSeries,
                                          diamond: item?.diamond,
                                          platinum: item?.platinum,
                                          gold: item?.gold,
                                          silver: item?.silver,
                                          bronze: item?.bronze,
                                          iron: item?.iron,
                                          stone: item?.stone,
                                          wood: item?.wood,
                                          paper: item?.paper,
                                        });
                                      }}
                                    >
                                      [Manage]
                                    </button>
                                  </Tooltip>
                                  <div
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                      setCompititionIdForEdit(item?._id);
                                      setShowCompetition(true);
                                    }}
                                  >
                                    <Tooltip title="delete competition" arrow>
                                      <DeleteIcon />
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                              </>
                            ) : (
                              <>

                            {/* {index === 0 ? handleSomthing(index) : null} */}
                              <tr >
                                <td colSpan={7} className="noBg">

                                <b
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ marginTop: "20%", fontSize: "23px" , color:"black"}}
                                >
                                    Competition not found in this game
                                </b>
                                </td>
                              </tr>
                              </>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          );
        })}
        {/* </div>
        </div> */}

        <div></div>
      </div>

      {addGame === true ? (
        <Dialog fullScreen open={addGame}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addGameModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {/* <h3>{getAllGame?.name}</h3> */}
            <div className="m-auto w-1000px pt-10">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="name"
                      value={gameData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Category
                </label>

                {getCategoryData && (
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <select
                        name="categoryId"
                        id="categoryId"
                        defaultValue={gameData?.categoryId}
                        className="form-control rounded-lg custom-style"
                        aria-label="Default select example"
                        onChange={(e) => onhandleChange(e)}
                      >
                        <option selected disabled>
                          Select Category
                        </option>
                        {getCategoryData &&
                          getCategoryData?.map((data) => (
                            <option value={data?._id}>{data?.type}</option>
                          ))}
                      </select>

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userId"]}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {isEditApi === false ? (
                <>
                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      Pictures
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="file"
                          multiple
                          className={`form-control rounded-lg custom-style`}
                          name="file"
                          id="imageUpload"
                          onChange={(e) => {
                            handleOnChnageAddImg(e);
                          }}
                        />
                        {gameImage &&
                          gameImage?.map((imgs, i) => {
                            return (
                              <>
                                <img
                                  src={
                                    imgs?.slice(-5) === ".webp" &&
                                    imgs?.slice(-5) === ".jpeg" &&
                                    imgs?.slice(-5) === ".png" &&
                                    imgs?.slice(-5) === ".jpg"
                                      ? imgs
                                      : URL.createObjectURL(imgs)
                                  }
                                  alt=""
                                  // src={img}
                                  height="100px"
                                  width="100px"
                                />
                              </>
                            );
                          })}
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["file"]}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      Edit Pictures
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="file"
                          multiple
                          className={`form-control rounded-lg custom-style`}
                          name="file"
                          id="imageUpload"
                          onChange={(e) => {
                            handleOnChnageAddImg(e);
                          }}
                        />
                        {gameImage && gameImage?.length > 0 ? (
                          <>
                            {gameImage?.map((imgs, i) => {
                              return (
                                <>
                                  <img
                                    src={
                                      imgs?.slice(-5) === ".webp" &&
                                      imgs?.slice(-5) === ".jpeg" &&
                                      imgs?.slice(-5) === ".png" &&
                                      imgs?.slice(-5) === ".jpg"
                                        ? imgs
                                        : URL.createObjectURL(imgs)
                                    }
                                    alt=""
                                    // src={img}
                                    height="100px"
                                    width="100px"
                                  />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {editImg?.map((imgs, i) => {
                              return (
                                <>
                                  <img
                                    src={imgs}
                                    alt=""
                                    // src={img}
                                    height="100px"
                                    width="100px"
                                  />
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["file"]}
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Description
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      className={`form-control rounded-lg custom-style`}
                      id="description"
                      name="description"
                      value={gameData?.description}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  iOS Store Link
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="iosStoreLink"
                      name="iosStoreLink"
                      value={gameData?.iosStoreLink}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["iosStoreLink"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Android Store Link
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="androidStoreLink"
                      name="androidStoreLink"
                      value={gameData?.androidStoreLink}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["androidStoreLink"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Entry Fees
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="entryFee"
                      name="entryFee"
                      value={gameData?.entryFee}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["entryFee"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="coinIcon"
                  className="col-xl-5 col-lg-5 col-form-label label-alignment"
                >
                  Geo-Series compatible{" "}
                </label>

                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="checkbox"
                      className={`form-control form-control-lg `}
                      id="geoSeries"
                      name="geoSeries"
                      value={gameData?.geoSeries}
                      defaultChecked={gameData?.geoSeries}
                      onChange={(e) => onhandleChange(e)}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["GeoSeries"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button
                      className="btn position-relative center"
                      type="button"
                    >
                      <img src={AddGameIcon} alt={AddGameIcon} />
                      Edit Game
                    </button>
                  </div>
                </>
              ) : (
                <div className="button-details-alignment">
                  <button
                    className="btn"
                    onClick={(e) => {
                      editgameData(e);
                    }}
                  >
                    <img src={AddGameIcon} alt={AddGameIcon} />
                    Edit Game
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
      {competitionDataModel === true ? (
        <Dialog fullScreen open={competitionDataModel}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => competitionDataModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="m-auto w-1000px pt-10">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Game
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      name="gameId"
                      id="gameId"
                      value={showCompetitionData?.gameId}
                      className="form-control rounded-lg custom-style"
                      aria-label="Default select example"
                      onChange={(e) => onhandleChangeofCompetition(e)}
                    >
                      <option selected disabled>
                        Select Game
                      </option>

                      {gameData1 &&
                        gameData1?.map((data) => (
                          <option value={data._id}>{data?.name}</option>
                        ))}
                    </select>

                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["userId"]}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="name"
                      value={showCompetitionData?.name}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Description
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      // type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="description"
                      name="description"
                      value={showCompetitionData?.description}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Image
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <div className="labelMainDiv">
                      <label
                        htmlFor="uploadStoreImage"
                        className="lableForImage"
                      >
                        select file
                      </label>
                      {showCompetitionData?.image ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid`}
                      name="image"
                      id="uploadStoreImage"
                      hidden
                      onChange={(e) => handleOnChnageAddImgCompetition(e)}
                    />
                  </div>
                  <div>
                    {showCompetitionData?.image?.length != 0 ? (
                      <>
                        {showCompetitionData?.image
                          ?.toString()
                          ?.includes(".com") === true ? (
                          <img
                            src={showCompetitionData?.image}
                            alt="img"
                            style={{
                              height: "150px",
                              width: "270px",
                              margin: "15px 0",
                            }}
                          />
                        ) : showCompetitionData?.image ? (
                          <img
                            src={URL.createObjectURL(
                              showCompetitionData?.image
                            )}
                            alt="img"
                            style={{
                              height: "150px",
                              width: "270px",
                              margin: "15px 0",
                            }}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["image"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Start Date
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="startingDate"
                      value={showCompetitionData?.startingDate}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["startingDate"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  End Date
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="endingDate"
                      value={showCompetitionData?.endingDate}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["endingDate"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Entry Fee
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="entryFee"
                      name="entryFee"
                      value={showCompetitionData?.entryFee}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["entryFee"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  prize Pool Type
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      className={`form-control rounded-lg custom-style`}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                      defaultValue={showCompetitionData?.prizePool}
                      name="prizePool"
                    >
                      <option value="select for prizePool" selected disabled>
                        Select prizePool
                      </option>
                      <option value="fixed">Fixed</option>
                      <option value="incrementing">Incrementing</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["prizePool"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Prize Pool Amount
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      placeholder="0"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="prizeAmount"
                      value={
                        showCompetitionData?.prizePool === "incrementing"
                          ? "0"
                          : showCompetitionData?.prizeAmount
                      }
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["prizeAmount"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Tiered / even Payment
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      className={`form-control rounded-lg custom-style`}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                      defaultValue={showCompetitionData?.winningType}
                      name="winningType"
                    >
                      <option value="select for winningType" selected disabled>
                        Select winningType
                      </option>
                      <option value="tiered">Tiered</option>
                      <option value="even">Even</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["winningType"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  No. of winners
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="winnerCount"
                      value={showCompetitionData?.winnerCount}
                      // value={(showCompetitionData?.prizePool === "increment" ? "0" :compititionData?.winnerCount)}
                      onChange={(e) => {
                        onhandleChangeofCompetition(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["winnerCount"]}
                  </span>
                </div>
              </div>

              {showCompetitionData?.winningType === "tiered" ? (
                <>
                  {showCompetitionData?.winnerCount !== "" &&
                  showCompetitionData?.prizeAmount !== ""
                    ? handlePrizePool(
                        +showCompetitionData?.winnerCount,
                        +showCompetitionData?.prizeAmount
                      )
                    : ""}
                </>
              ) : (
                <>
                  {showCompetitionData?.winnerCount !== "" &&
                  showCompetitionData?.prizeAmount !== ""
                    ? handlePrizePoolEven(
                        +showCompetitionData?.winnerCount,
                        +showCompetitionData?.prizeAmount
                      )
                    : ""}
                </>
              )}

              <div className="form-group row">
                <label
                  htmlFor="coinIcon"
                  className="col-xl-5 col-lg-5 col-form-label label-alignment"
                >
                  Enable Geo-Series
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    {isEditApi === false ? (
                      <input
                        type="checkbox"
                        className={`form-control form-control-lg `}
                        id="GeoSeries"
                        name="GeoSeries"
                        value={showCompetitionData?.GeoSeries}
                        defaultChecked={showCompetitionData?.GeoSeries}
                        onChange={(e) => onhandleChangeofCompetition(e)}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        className={`form-control form-control-lg `}
                        id="GeoSeries"
                        name="GeoSeries"
                        value={showCompetitionData?.GeoSeries}
                        defaultChecked={showCompetitionData?.GeoSeries}
                        // onChange={(e) => onhandleChangeofCompetition(e)}
                        disabled
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["GeoSeries"]}
                  </span>
                </div>
              </div>

              {showCompetitionData?.GeoSeries === true && (
                <>
                  {isEditApi === false && (
                    <>
                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment blue">
                            DIAMOND
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="diamond"
                              name="diamond"
                              value="diamond"
                              // defaultChecked={showCompetitionData?.diamond}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["diamond"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment browb">
                            PLATINUM
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="platinum"
                              name="diamond"
                              // value={showCompetitionData?.platinum}
                              value="platinum"
                              // defaultChecked={showCompetitionData?.platinum}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["platinum"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment digital">
                            GOLD
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="gold"
                              name="diamond"
                              value="gold"
                              // defaultChecked={showCompetitionData?.gold}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["gold"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment black">
                            SILVER
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="silver"
                              name="diamond"
                              value="silver"
                              // defaultChecked={showCompetitionData?.silver}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["silver"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment browb">
                            BRONZE
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="bronze"
                              name="diamond"
                              value="bronze"
                              // defaultChecked={showCompetitionData?.bronze}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["bronze"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment black">
                            IRON
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="iron"
                              name="diamond"
                              value="iron"
                              // defaultChecked={showCompetitionData?.iron}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["iron"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment black">
                            STONE
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="stone"
                              name="diamond"
                              value="stone"
                              // defaultChecked={showCompetitionData?.stone}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["stone"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment red">
                            WOOD
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="wood"
                              name="diamond"
                              value="wood"
                              // defaultChecked={showCompetitionData?.wood}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["wood"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment red">
                            PAPER
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="paper"
                              name="diamond"
                              value="paper"
                              // defaultChecked={showCompetitionData?.paper}
                              onChange={(e) => onhandleChangeofCompetition(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["paper"]}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button className="btn" type="button">
                      <img src={AddCompetitionIcon} alt={AddCompetitionIcon} />
                      <img src={CompetitionIcon2} alt={CompetitionIcon2} />
                      Edit competition
                    </button>
                  </div>
                </>
              ) : (
                <div className="button-details-alignment">
                  <button
                    className="btn center"
                    onClick={(e) => {
                      editPackageData(e);
                    }}
                  >
                    <img src={AddCompetitionIcon} alt={AddCompetitionIcon} />
                    <img src={CompetitionIcon2} alt={CompetitionIcon2} />
                    Edit competition
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
