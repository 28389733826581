import React, { useEffect, useState } from "react";
import firebase from "../../../firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { requestForToken } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { ApiPost } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../_metronic/_assets/sass/layout/_basic.scss";
import { getUserInfo } from "../../../../utils/user.util";
import axios from "axios";
import { set } from "lodash";
import { CallMerge } from "@material-ui/icons";
import { ApiDelete, ApiGet, ApiPut } from "../../../../helpers/API/ApiData";

export default function Login() {
  const firebaseseToken = localStorage.getItem("firebaseToken");
  // const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [loginData, setLoginData] = useState({});
  const [errors, setErrors] = useState({});
  const [getAllStore, setGetAllStore] = useState([]);

  const [loader, setLoader] = useState(false);
  const userInfo = getUserInfo();

  const regexemail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };
  useEffect(() => {
    handleTokenFunction();
    Notification.requestPermission().then(function (permission) {
      // ...
    });
  }, []);

  const getAllStoreData = async (id) => {
    await ApiGet(`store/find?userId=${id}`).then((res) => {
      if (res?.data?.payload?.[0]?._id) {

        return setTimeout(() => {
          localStorage.setItem(
            "localStoreId",
            JSON.stringify(res?.data?.payload?.[0]?._id)
          );
          toast.success(res?.data?.message);
          history.push("/");
          window.location.reload();
        }, 2000);
      } else {
        return history.push("/assign-store-message");
      }
    });
  };

  const handleTokenFunction = () => {
    // const messaging = firebase
    // messaging
    //   .requestPermission()
    //   .then(() => {
    //     const tkn = messaging.getToken();
    //     console.log("tokkkkkeeennn", tkn);
    //   })
    //   .then((token) => {
    //     console.log("tok", token);
    //   });
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!loginData.email && !loginData.password) {
      setErrors({
        email: "Email  is required*",
        password: "Password is required*",
      });
    } else if (loginData.email === "" && loginData.password === "") {
      setErrors({ ...errors, email: "Email  is required*" });
    } else if (!loginData.email || loginData.email === "") {
      setErrors({ ...errors, email: "Email  is required*" });
    } else if (!loginData.email || regexemail.test(loginData.email) === false) {
      setErrors({ ...errors, email: "Email  is not valid*" });
    } else if (!loginData.password || loginData.password === "") {
      setErrors({ ...errors, password: "Password is required*" });
    } else {
      loginData.email = loginData.email.toLowerCase();
      const data = {
        email: loginData?.email,
        password: loginData?.password,
        fcmToken: firebaseseToken ? firebaseseToken : "",
      };
      await ApiPost("user/login", data)
        .then((res) => {
          if (res.data.error === "email Does Not Exist") {
            setErrors({ user: "User does not exist !!" });
          } else if (res.data.error === "Password Incorrect") {
            setErrors({
              user: "Login credentials are incorrect !!",
            });
          } else {
            authUtil.setToken(res?.data?.accessToken);
            userUtil.setUserInfo(res?.data?.payload);
            if (res?.data?.payload?.role === "owner") {
              return getAllStoreData(res?.data?.payload?._id);
            } 
            else {
              return setTimeout(() => {
                toast.success(res?.data?.message);
                history.push("/");
                window.location.reload();
              }, 2000);
            }
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    setLoader(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      history.push("/dashboard");
    }
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Admin Dashboard</h3>
        <p className="text-muted font-weight-bold">
          Enter your login credentials.
        </p>

        <span className="text-danger h6">{errors.user}</span>
      </div>
      {/* 
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      /> */}
      <form onSubmit={(e) => handleKeyPress(e)}>
        {/* <div className="form-group fv-plugins-icon-container">
          <input type="radio" id="Admin" name="fav_language" value="Admin" />
          <label for="Admin">Admin</label>
          <input type="radio" id="Store-owner" name="fav_language" value="Store-owner" />
          <label for="Store-owner">Store-owner</label>

          <span className="text-danger">{errors.email}</span>
        </div> */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email Id"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6  `}
            name="email"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <span className="text-danger">{errors.email}</span>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 `}
            name="password"
            onChange={(e) => {
              handleChange(e);
            }}
          />
          <span className="text-danger">{errors.password}</span>
        </div>
        <div>
          <a href="/auth/forgot-password" style={{ marginLeft: "280px" }}>
            Forgot password?
          </a>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            className={`align-items-center d-flex btn btn-color font-weight-bold px-9 py-4 my-3`}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            <span className="pr-2">Log In</span>
            {loader && (
              <div class="spinner-grow text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </form>

      {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div> */}
      {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Read our <Link>Onboarding Policy</Link> here.
          </span>
        </div> */}
      {/* </form> */}

      {/*end::Form*/}
    </div>
  );
}
