import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { ApiPost } from "../../../helpers/API/ApiData";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import StoreClientImg from "../../_assets/plugins/keenthemes-icons/svg/Store_Clients.png";
import UserPlusIcon from "../../_assets/plugins/keenthemes-icons/svg/Plus-icon.png";
import AddUserIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import AddUserAccountIcon from "../../_assets/plugins/keenthemes-icons/svg/account-icon.png";
import EditUser from "../../_assets/plugins/keenthemes-icons/svg/editIcon.png";

import { red } from "@material-ui/core/colors";

function User() {
  const [users, setUsers] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [, setloderrr] = useState(false);
  const [search, setSearch] = useState();
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userIdForEdit, setUserIdForEdit] = useState();
  const [userDataForGetRole, setUserDataForGetRole] = useState();
  const [uploadFiles, setUploadFiles] = useState();
  const [editImg, setEditImg] = useState();
  const [openMoreDetail, setOpenMoreDetail] = useState(false);
  const [showMoreUserData, setShowMoreUserData] = useState({});
  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getUserData();
  }, [page, countPerPage]);
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    if (name === "privacy") {
      setUserData({ ...userData, [name]: e.target.checked });
      return;
    }
    setErrors({ ...errors, [name]: "" });
  };

  const regexEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if(isEditApi === false){

      if (!userData?.username?.trim() || userData?.username === "") {
        formvalied = false;
        errors["username"] = "*Please enter name.";
      }
      if (
        !userData?.email?.trim() ||
        userData?.email === "" ||
        regexEmail?.test(userData?.email) === false
      ) {
        formvalied = false;
        errors["email"] = "*Please enter valid email id";
      }
      if (!userData?.password?.trim() || userData?.password === "" || !userData.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
      )) {
        formvalied = false;
        errors["password"] = "*Password must contain atleast one special charater,number,capital latter and small latter .";
      }
    }
    if (!userData?.dob || userData?.dob === "") {
      formvalied = false;
      errors["dob"] = "*Please enter Date Of birth.";
    }
    if (!userData?.privacy || userData?.privacy === false) {
      formvalied = false;
      errors["privacy"] = "*Please Check Privacy";
    }
    // if (!userData?.phone || userData?.phone === "") {
    //   formvalied = false;
    //   errors["phone"] = "*Please enter phone number.";
    // }
    // if (userData && !userData?.profile_picture) {
    //   formvalied = false;
    //   errors["profile_picture"] = "*Please select image.";
    // }

    setErrors(errors);
    return formvalied;
  };

  useEffect(() => {
    getRole();
  }, []);

  const addGameModelClose = (e) => {
    setUploadFiles("");
    setAddUser(false);
    setIsEditApi(false);
    setErrors(false);
    setUserData({});
  };

  const getRole = async () => {
    await ApiGet(`role/find`)
      .then((res) => {
        setUserDataForGetRole(res?.data?.data);
      })
      .catch((err) => {});
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setUserData({ ...userData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const getUserData = async () => {
    setLoadingData(true);

    let url = search ? `&letter=${search}` : "";
    await ApiGet(`user/find?page=${page}&limit=${countPerPage}&days=30${url}`)
      .then((res) => {
        setLoadingData(false);
        setUsers(res?.data?.payload);
        setCount(res?.data?.count);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };
  const addgameData = async (e) => {
    setIsEditApi(false);
    const UserID = userDataForGetRole?.find((item) => {
      return <>{item?.roleName === "user" ? item : ""}</>;
    });
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("username", userData?.username);
      formData.append("email", userData?.email);
      formData.append("password", userData?.password);
      formData.append("role", UserID?._id);
      formData.append("dob", userData?.dob);
      formData.append("privacy", userData?.privacy);
      if(userData?.profile_picture){

        formData.append(
          "profile_picture",
          userData?.profile_picture ? userData?.profile_picture : ""
        );
      }
    if(userData?.phone){

      formData.append("phone", userData?.phone);
    }
      if (userData?.address) {
        formData.append("address", userData?.address);
      }
      if (userData?.city) {
        formData.append("city", userData?.city);
      }
      if (userData?.state) {
        formData.append("state", userData?.state);
      }
      if (userData?.zipCode) {
        formData.append("zipCode", userData?.zipCode);
      }
      if (userData?.country) {
        formData.append("country", userData?.country);
      }
      await ApiPost(`user/register`, formData)
        .then((res) => {
          toast.success("User add successfully");
          setAddUser(false);
          setIsEditApi(false);
          getUserData();
          setUserData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error("Something went wrong please try again");
          setLoader(false);
        });
    }
  };
  const imageupload = (e) => {
    setUploadFiles(e.target.files[0]);
  };
  const editgameData = async (e) => {
     if (formValidation()) {
    let formData = new FormData();
    formData.append("userId", userIdForEdit);
    formData.append("dob", userData?.dob);
    formData.append("profile_picture", userData?.profile_picture);
    formData.append("phone", userData?.phone);
    formData.append("privacy", userData?.privacy);

    if (userData?.address) {
      formData.append("address", userData?.address);
    }
    if (userData?.city) {
      formData.append("city", userData?.city);
    }
    if (userData?.state) {
      formData.append("state", userData?.state);
    }
    if (userData?.zipCode) {
      formData.append("zipCode", userData?.zipCode);
    }
    if (userData?.country) {
      formData.append("country", userData?.country);
    }
    formData.append("isActive", userData?.isActive);

    setLoader(true);

    await ApiPut(`user/update`, formData)
      .then((res) => {
        toast.success("user update successfully");
        getUserData();
        setAddUser(false);
        setIsEditApi(false);
        setUserData({});
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
        setLoader(false);
      });
    }
  };

  const updatestatus = (e, row) => {
    let body = {
      userId: row?._id,
      isActive: row?.isActive === true ? false : true,
    };

    ApiPut(`user/updatebyAdmin`, body)
      .then((res) => {
        getUserData();
        toast.success("Status Update Successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.statusText);
        setloderrr(false);
      });
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        backgroundColor: "lightgrey",
        color: "#040761",
        // userSelect: "none"
      },
    },
  ];

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Joined on",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "8%",
    },
    {
      name: "User name",
      selector: (row) => (row?.username ? row?.username : "-"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (row?.email ? row?.email : "-"),
      width: "15%",
    },
    {
      name: "Phone No",
      selector: (row) => (row?.phone ? row?.phone : "-"),
      sortable: true,
    },
    {
      name: "Dob",
      selector: (row) =>
        row?.dob ? moment(row?.dob).format("Do MMM YYYY") : "-",
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row?.address ? row?.address : "-"),
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => (row?.city ? row?.city : "-"),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => (row?.state ? row?.state : "-"),
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => (row?.country ? row?.country : "-"),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.isActive === true && (
              <div
                style={{
                  background: "#22cc00",
                  color: "white",
                  fontWeight: "bold",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Active
                </p>
              </div>
            )}
            {row?.isActive === false && (
              <div
                style={{
                  background: "red",
                  height: "50px",
                  fontWeight: "bold",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Deactived
                </p>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div className="cursor-pointer pl-2">
                <Tooltip title="Manage Account" arrow>
                  <button
                    style={{
                      background: "#002a5a",
                      color: "white",
                      borderRadius: "20px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) => {
                      setIsEditApi(true);
                      setAddUser(true);
                      setUserIdForEdit(row?._id);
                      setUserData({
                        dob: moment(row?.dob).format("YYYY-MM-DD"),
                        phone: row?.phone,
                        profile_picture:
                          row?.profile_picture?.length > 0
                            ? row?.profile_picture
                            : null,
                            privacy: row?.privacy,
                        address: row?.address,
                        city: row?.city,
                        state: row?.state,
                        zipCode: row?.zipCode,
                        country: row?.country,
                        isActive: row?.isActive,
                      });
                    }}
                  >
                    Manage
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
    // {
    //   name: "More Information",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="d-flex justify-content-center m-auto">
    //           <div
    //             className="cursor-pointer pl-2"
    //             onClick={(e) => {
    //               setOpenMoreDetail(true);
    //               setShowMoreUserData(row);
    //             }}
    //           >
    //             <Tooltip title="Order Details" arrow>
    //               <InfoOutlinedIcon
    //                 data-toggle="modal"
    //                 data-target="#exampleModalCenter"
    //               />
    //             </Tooltip>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   },
    //   sortable: true,
    // },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={StoreClientImg} alt="StoreClientImg" className="client" />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">Total Account Holders</h6>
            </div>
          </div>
        </div>
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Users</h2>   */}
            </div>
            <div className="cus-medium-button-style button-details-alignment">
              <button
                className="btn"
                onClick={() => {
                  setAddUser(true);
                }}
              >
                <img src={AddUserIcon} alt={AddUserIcon} />
                <img
                  src={AddUserAccountIcon}
                  alt="AddUserAccountIcon"
                  className="child-img"
                />
                Add Account Holder
              </button>
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>

          {loadingData ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={users}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            />
          )}
        </div>

        {addUser === true ? (
          <Dialog fullScreen open={addUser}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={(e) => addGameModelClose(e)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <List>
              <div className="heading-banner d-flex justify-content-center">
                <div className="align-items-center d-flex">
                  {isEditApi === true ? (
                    <img
                      src={EditUser}
                      alt="EditUser"
                      className="coupon mr-4"
                    />
                  ) : (
                    <img
                      src={UserPlusIcon}
                      alt="UserPlusIcon"
                      className="coupon mr-4"
                    />
                  )}

                  <img
                    src={StoreClientImg}
                    alt="StoreClientImg"
                    className="client"
                  />
                </div>
              </div>
              <div className="m-auto w-1000px pt-10">
                {isEditApi === false && (
                  <>
                    <div className="form-group row">
                      <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                        User Name
                      </label>
                      <div className="col-lg-7 col-xl-7">
                        <div>
                          <input
                            type="text"
                            className={`form-control rounded-lg custom-style`}
                            id="title"
                            name="username"
                            value={userData?.username}
                            onChange={(e) => {
                              onhandleChange(e);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["username"]}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                        E-mail
                      </label>
                      <div className="col-lg-7 col-xl-7">
                        <div>
                          <input
                            type="text"
                            className={`form-control rounded-lg custom-style`}
                            id="email"
                            name="email"
                            value={userData?.email}
                            onChange={(e) => {
                              onhandleChange(e);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["email"]}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                        Password
                      </label>
                      <div className="col-lg-7 col-xl-7">
                        <div>
                          <input
                            type="password"
                            className={`form-control rounded-lg custom-style`}
                            id="password"
                            name="password"
                            // value={userData?.password}
                            onChange={(e) => {
                              onhandleChange(e);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["password"]}
                        </span>
                      </div>
                    </div>

                    {/* <div className="form-group row">
                      <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                        Select Role
                      </label>
                      <div className="col-lg-7 col-xl-7">
                        <div>
                          <select
                            name="role"
                            id="role"
                            defaultValue={userData?.role}
                            className="form-control"
                            aria-label="Default select example"
                            onChange={(e) => onhandleChange(e)}
                          >
                            <option selected disabled>
                              select Role
                            </option>
                            {userDataForGetRole &&
                              userDataForGetRole?.map((data) => (
                                <option value={data._id}>
                                  {data?.roleName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}

                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Date Of Birth
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="date"
                        className={`form-control rounded-lg custom-style`}
                        id="dob"
                        name="dob"
                        value={userData?.dob}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["dob"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Phone No.
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="number"
                        className={`form-control rounded-lg custom-style`}
                        id="phone"
                        name="phone"
                        value={userData?.phone}
                        onChange={(e) => {
                          e?.target?.value?.length <= 10 && onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["phone"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Profile Image
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <div className="labelMainDiv">
                        <label
                          htmlFor="profile_picture"
                          className="lableForImage"
                        >
                          select file
                        </label>
                        {userData?.profile_picture ? (
                          ""
                        ) : (
                          <label className="labelselectfile">
                            no file selected
                          </label>
                        )}
                      </div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid`}
                        name="profile_picture"
                        id="profile_picture"
                        hidden
                        onChange={(e) => handleOnChnageAddImg(e)}
                      />
                    </div>
                    <div>
                   
                      {userData && userData?.profile_picture && (
                        <img
                          style={{
                            height: "150px",
                            width: "270px",
                            margin: "15px 0",
                          }}
                          src={
                            userData?.profile_picture
                              ?.toString()
                              ?.includes(".com") === true
                              ? userData?.profile_picture
                              : URL.createObjectURL(userData?.profile_picture)
                          }
                        />
                      )}
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["profile_picture"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                <label
                  htmlFor="coinIcon"
                  className="col-xl-5 col-lg-5 col-form-label label-alignment"
                >
                 Privacy
                </label>

                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="checkbox"
                      className={`form-control form-control-lg `}
                      id="privacy"
                      name="privacy"
                      value={userData?.privacy}
                      defaultChecked={userData?.privacy}
                      onChange={(e) => onhandleChange(e)}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["privacy"]}
                  </span>
                </div>
              </div>

                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Address
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="text"
                        className={`form-control rounded-lg custom-style`}
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={userData?.address}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["address"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    {/* City */}
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="text"
                        className={`form-control rounded-lg custom-style`}
                        id="city"
                        name="city"
                        placeholder="city"
                        value={userData?.city}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["city"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    {/* State */}
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="text"
                        className={`form-control rounded-lg custom-style`}
                        id="state"
                        name="state"
                        placeholder="state"
                        value={userData?.state}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["state"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    {/* Zip Code */}
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="number"
                        className={`form-control rounded-lg custom-style`}
                        id="zipCode"
                        name="zipCode"
                        placeholder="Zip Code"
                        value={userData?.zipCode}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["zipCode"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    {/* Country */}
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="text"
                        className={`form-control rounded-lg custom-style`}
                        id="country"
                        name="country"
                        placeholder="country"
                        value={userData?.country}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["country"]}
                    </span>
                  </div>
                </div>
                {isEditApi === true && (
                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      Status
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                          name="isActive"
                          defaultValue={
                            userData?.isActive === true ? "Active" : "De-Active"
                          }
                        >
                          <option value="select for Erreicht" selected disabled>
                            select Status
                          </option>
                          <option value="true">Active</option>
                          <option value="false">De-Active</option>
                        </select>
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["isActive"]}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-center">
                {loader ? (
                  <>
                    <div className="text-center button-details-alignment">
                      <button className="btn " type="button">
                        <img src={AddUserIcon} alt={AddUserIcon} />
                        <img
                          src={AddUserAccountIcon}
                          alt="AddUserAccountIcon"
                        />
                        {isEditApi ? "Edit Acc Holder" : "Add Acc Holder"}
                        {/* <span
                          className="mx-3 spinner spinner-black"
                          role="status"
                        ></span> */}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="button-details-alignment">
                    <button
                      className="btn "
                      onClick={(e) => {
                        isEditApi ? editgameData(e) : addgameData(e);
                      }}
                    >
                      <img src={AddUserIcon} alt={AddUserIcon} />
                      <img src={AddUserAccountIcon} alt="AddUserAccountIcon" />
                      {isEditApi ? "Edit Acc Holder" : "Add Acc Holder"}
                    </button>
                  </div>
                )}
              </div>
            </List>
          </Dialog>
        ) : null}
      </div>
      {openMoreDetail === true ? (
        <>
          <div
            class="modal fade"
            id="exampleModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            style={{ backdropFilter: "brightness(0.5)" }}
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h2 class="modal-title" id="exampleModalLongTitle">
                    More Information
                  </h2>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h4>Phone no.</h4>
                  <p>{showMoreUserData?.phone}</p>

                  <h4>Date of birth</h4>
                  <p>{moment(showMoreUserData?.dob).format("Do MMM YYYY")}</p>

                  <h4>Address</h4>
                  <p>{showMoreUserData?.address}</p>

                  <h4>City</h4>
                  <p>{showMoreUserData?.city}</p>

                  <h4>State</h4>
                  <p>{showMoreUserData?.state}</p>

                  <h5>Country</h5>
                  <p>{showMoreUserData?.country}</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
export default User;
