import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import ReactHtmlParser from "html-react-parser";
import { getUserInfo } from "../../../utils/user.util";
import moment from "moment";
import Competition from "../Compitition/Compitition";
import AddGameIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import CompetitionForOwner from "../CompetitionForOwner/CompetitionForOwner";
import MyPagination from "../Pagination/MyPagination";

export default function GameForOwner() {
  let userInfo = getUserInfo();
  const [gameData, setGameData] = useState({});
  const [gameImage, setGameImage] = useState([]);
  const [addGame, setAddGame] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllGame, setgetAllGame] = useState([]);
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(10);
  const [count, setCount] = useState(0);
  const [gameIdForEdit, setGameIdForEdit] = useState();
  const [gameIdForDelete, setgameIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  // const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [show, setShow] = useState(false);
  const [showCompitition, setShowCompition] = useState(false);
  const [gameIdForCompitition, setGameIdForCompitition] = useState();
  const [openAction, setOpenAction] = useState(false);
  const [defineIndeKey, setDefineIndexKey] = useState();

  useEffect(() => {
    getAllgameData();
  }, [page, countPerPage]);

  const addGameModelClose = (e) => {
    setAddGame(false);
    setIsEditApi(false);
    setErrors(false);
    setGameData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setGameData({ ...gameData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // const handleOnChnageAddImg = (e) => {
  //   const { name } = e.target;
  //   // setGameData({ ...gameData, [name]: e.target.files[0] });
  //   setGameData(Array.from(e.target.files));
  //   setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  // };

  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setGameImage(Array.from(e.target.files));
    setErrors({ ...errors, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!gameData?.name?.trim() || gameData?.name === "") {
      formvalied = false;
      errors["name"] = "*Please enter Name.";
    }
    if (gameImage?.length <= 0) {
      formvalied = false;
      errors["file"] = "*Please select Image.";
    }
    if (!gameData?.description?.trim() || gameData?.description === "") {
      formvalied = false;
      errors["description"] = "*Please enter Description.";
    }
    if (!gameData?.iosStoreLink?.trim() || gameData?.iosStoreLink === "") {
      formvalied = false;
      errors["iosStoreLink"] = "*Please enter IosStoreLink";
    }

    if (
      !gameData?.androidStoreLink?.trim() ||
      gameData?.androidStoreLink === ""
    ) {
      formvalied = false;
      errors["androidStoreLink"] = "*Please enter AndroidStoreLink";
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };
  const compititionModelClose = () => {
    setShowCompition(false);
  };

  const getAllgameData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(`game/find?page=${page}&limit=${countPerPage}&days=30`)
        .then((res) => {
          setgetAllGame(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
          setTotal(Math.ceil((res?.data?.count || 1) / limit));
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `game/find?letter=${search}&page=${page}&limit=${countPerPage}&days=30`
      )
        .then((res) => {
          setgetAllGame(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
          setTotal(Math.ceil((res?.data?.count || 1) / limit));
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };
  const deletegameData = async () => {
    await ApiDelete(`game/delete?id=${gameIdForDelete}`)
      .then((res) => {
        if (res?.status === true) {
          toast.success("Delete Game successfully");
          setShow(false);
          getAllgameData();
        } else {
          toast.error("something went wrong please try again");
          setShow(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  const updatestatus = (e, row) => {
    let formData = new FormData();
    formData.append("isRevoked", row?.isRevoked === true ? false : true);

    ApiPut(`game/update?id=${row?._id}`, formData)
      .then((res) => {
        getAllgameData();
        toast.success("Status Update Successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.statusText);
        setLoader(false);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllgameData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllgameData();
    }
  }, [debouncedSearchTerm]);

  const handlePage = (data) => {
    setPage(data);
  };
  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3 align-items-center">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Game</h2>
            </div>
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style `}
                name="search"
                value={search}
                onChange={(e) => handleSearch(e)}
                placeholder="Search"
              />
            </div>
            {/* <div className="col-lg-3 col-xl-3">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn position-relative center"
                onClick={() => {
                  setAddGame(true);
                }}
              >
                <img
                  src={AddGameIcon}
                  alt={AddGameIcon}
                  className="button-img game-img "
                />
                <span className="position-absolute button-alignment category-alignment ">
                  Add Game
                </span>
              </button>
            </div> */}
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <div>
            <div className="gallery-box">
              <div className="gallery-grid">
                {getAllGame?.map((data, index) => {
                  return (
                    <>
                      <div>
                        <div className="gallery-grid-items">
                          <div className="card-image">
                            <img src={data?.pictures[0]} alt="img" />
                          </div>
                        </div>
                        <div className="card-details  m-10 p-5">
                          <div>
                            <div className="d-flex ">
                              <p className="badge badge-warning">
                                {" "}
                                <a
                                  style={{ color: "black" }}
                                  href={data?.iosStoreLink}
                                  target="_blank"
                                >
                                  iOS
                                </a>
                              </p>
                              <p className="badge badge-warning ml-4">
                                <a
                                  style={{ color: "black" }}
                                  href={data?.androidStoreLink}
                                  target="_blank"
                                >
                                  android
                                </a>
                              </p>
                            </div>
                            <h1>{data?.name}</h1>

                            <p>
                              {" "}
                              {data?.description?.length > 50
                                ? data?.description?.substring(0, 50) + "..."
                                : data?.description}
                            </p>
                            <div
                              className="badge badge-warning ml-40"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "rgb(41, 182, 246)",
                              }}
                              onClick={(e) => {
                                setShowCompition(true);
                                setGameIdForCompitition(data?._id);
                              }}
                            >
                              <p className="m-2" style={{ color: "black" }}>
                                Create competition
                              </p>
                            </div>
                          </div>
                          <div
                            className="dropdown"
                            onClick={(e) => {
                              setOpenAction(!openAction);
                              setDefineIndexKey(index);
                            }}
                          >
                            <div>
                              {/* <svg
                                width="4"
                                height="16"
                                viewBox="0 0 4 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.96721 3.93443C3.05367 3.93443 3.93443 3.05367 3.93443 1.96721C3.93443 0.880751 3.05367 0 1.96721 0C0.880751 0 0 0.880751 0 1.96721C0 3.05367 0.880751 3.93443 1.96721 3.93443Z"
                                  fill="#606060"
                                />
                                <path
                                  d="M1.96721 9.96763C3.05367 9.96763 3.93443 9.08688 3.93443 8.00042C3.93443 6.91395 3.05367 6.0332 1.96721 6.0332C0.880751 6.0332 0 6.91395 0 8.00042C0 9.08688 0.880751 9.96763 1.96721 9.96763Z"
                                  fill="#606060"
                                />
                                <path
                                  d="M1.96721 16.0008C3.05367 16.0008 3.93443 15.1201 3.93443 14.0336C3.93443 12.9472 3.05367 12.0664 1.96721 12.0664C0.880751 12.0664 0 12.9472 0 14.0336C0 15.1201 0.880751 16.0008 1.96721 16.0008Z"
                                  fill="#606060"
                                />
                              </svg> */}
                            </div>
                            {defineIndeKey === index && openAction === true && (
                              <ul className="dropdown-main">
                                <li className="nav-items">
                                  <span
                                    className="active"
                                    onClick={(e) => {
                                      setIsEditApi(true);
                                      setAddGame(true);
                                      setGameIdForEdit(data?._id);
                                      setGameData({
                                        name: data?.name,
                                        file: data?.pictures,
                                        description: data?.description,
                                        iosStoreLink: data?.iosStoreLink,
                                        androidStoreLink:
                                          data?.androidStoreLink,
                                      });
                                    }}
                                  >
                                    Edit
                                  </span>
                                  <span
                                    className="text-danger"
                                    onClick={(e) => {
                                      setgameIdForDelete(data?._id);
                                      setShow(true);
                                    }}
                                  >
                                    Delete
                                  </span>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <MyPagination pages={total} current={page} onClick={handlePage} />
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Game?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deletegameData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addGame === true ? (
        <Dialog fullScreen open={addGame}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addGameModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="m-auto w-1000px pt-10">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="name"
                      value={gameData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Pictures
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    {/* <div className="labelMainDiv">
                      <label htmlFor="imageUpload" className="lableForImage">
                        select file
                      </label>
                      {gameImage?.companyLogo ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div> */}
                    <input
                      type="file"
                      multiple
                      className={`form-control rounded-lg custom-style`}
                      name="file"
                      id="imageUpload"
                      onChange={(e) => {
                        handleOnChnageAddImg(e);
                      }}
                    />
                    {gameImage &&
                      gameImage?.map((img, i) => {
                        return (
                          <img
                            src={URL.createObjectURL(img)}
                            height="100px"
                            width="100px"
                          />
                        );
                      })}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["file"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Description
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      className={`form-control rounded-lg custom-style`}
                      id="description"
                      name="description"
                      value={gameData?.description}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  iOS Store Link
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="iosStoreLink"
                      name="iosStoreLink"
                      value={gameData?.iosStoreLink}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["iosStoreLink"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Android Store Link
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="androidStoreLink"
                      name="androidStoreLink"
                      value={gameData?.androidStoreLink}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["androidStoreLink"]}
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center">
                    <button
                      className="btn position-relative center"
                      type="button"
                    >
                      <img
                        src={AddGameIcon}
                        alt={AddGameIcon}
                        className="button-img game-img "
                      />
                      <span className="position-absolute button-alignment category-alignment ">
                        {isEditApi ? "Edit  Game" : "Add Game"}
                      </span>
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn position-relative center"
                  onClick={(e) => {
                    isEditApi ? editgameData(e) : addgameData(e);
                  }}
                >
                  <img
                    src={AddGameIcon}
                    alt={AddGameIcon}
                    className="button-img game-img "
                  />
                  <span className="position-absolute button-alignment category-alignment ">
                    {isEditApi ? "Edit  Game" : "Add Game"}
                  </span>
                </button>
              )}
            </div> */}
          </List>
        </Dialog>
      ) : null}

      {showCompitition === true ? (
        <Dialog fullScreen open={showCompitition}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => compititionModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <>
              <CompetitionForOwner
                getAllGame={getAllGame}
                gameIdForCompitition={gameIdForCompitition}
              />
            </>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
