import React, { useState } from "react";
import "./AssignStoreMsg.scss";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../../layout/components/Logos/gstreak.png";

export default function AssignStoreMsg() {
  const [scrollY, setScrollY] = useState(0);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const scrolling = window.scrollY;
      setScrollY(scrolling);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollY]);

  return (
    <>
   
      <div
        className="lsq-details-section_Toast animate__animated animate__fadeInUp wow"
        data-wow-offset="400"
      >
        <div className="container">
          <div className="lsq-details-form-alignment">
            <div className="lsq-form-alignment">
              <div className="lsq-input-alignment">
                <div className="fa-paper-plane-style">
                 <img src={Logo} height={73} />
                </div>

                <div className="laq-main-cantainer">
                <h2>You don`t have assign any store</h2>
                  <div className="align-itme-button">
                    <button onClick={() => {history.push("/auth/login")}}>Back to login</button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
     
      {/* <div
        className="lsq-details-section_Toast animate__animated animate__fadeInUp wow"
        data-wow-offset="400"
      >
        <div className="container">
          <div className="lsq-details-form-alignment">
            <div className="lsq-form-alignment">
              <div className="lsq-input-alignment">
              <p>Oops!!</p>

                <div className="fa-paper-plane-style_error">
                  <i class='fas fa-plane-slash'></i>

                </div>

                <div className="laq-main-cantainer">
                <h2>Error Message</h2>
                 
                  <div className="link-data-error">
                    <p onClick={() => history.push("/account/lsq_and_quote")}>Show lsq & quote</p>
                  </div>
                  <p>
                  Sorry, we couldn't process your request at this time. Please check your internet connection and try again.
                    
                  </p>
                  <div className="align-itme-button-error">
                    <button onClick={() => setSuccessMessage({model:false,data:[]})}>Back</button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    */}
    </>
  );
}
