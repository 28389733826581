import React from "react";
import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import {ApiGet,ApiPut,} from "../../../helpers/API/ApiData";
import PhoneInput from "react-phone-number-input";
import { toast, ToastContainer } from "react-toastify";
import { getUserInfo } from "../../../utils/user.util";

import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import AddPrizeImg from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import StoreHomeImg from "../../_assets/plugins/keenthemes-icons/svg/home-icon.png";
import StoreIconDetails from "../../_assets/plugins/keenthemes-icons/svg/store-icon-details.png";

const apiKey =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBwyQ2fI1UgcPSJJ8HuYLhVQRFHYpB0iRE&v=3.exp&libraries=geometry,drawing,places";

const MyMapComponent = compose(
  withProps({
    googleMapURL: apiKey,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={4}
    defaultCenter={{ lat: props?.latitude, lng: props?.longtitude }}
    onClick={(e) => props?.handleClickedMap(e)}
  >
    <Marker position={{ lat: props?.latitude, lng: props?.longtitude }} />
  </GoogleMap>
));

export default function EditStore() {
  
  
  const userInfo = getUserInfo();
  const storeId = JSON.parse(localStorage.getItem("localStoreId"))

  const [errors, setErrors] = useState({});
  const [getAllStore, setGetAllStore] = useState({});
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [latitude, Setlatitude] = useState(-34.78702602419161);
  const [longtitude, Setlongtitude] = useState(-34.78702602419161);
  const [phoneInput, setphoneInput] = useState("");

  const [monthDay, setMonthDay] = useState([
    { day: "Monday", closed: false, open: "", close: "" },
    { day: "tuesday", closed: false, open: "", close: "" },
    { day: "wednesday", closed: false, open: "", close: "" },
    { day: "thursday", closed: false, open: "", close: "" },
    { day: "friday", closed: false, open: "", close: "" },
    { day: "saturday", closed: false, open: "", close: "" },
    { day: "sunday", closed: false, open: "", close: "" },
  ]);


useEffect(()=>{
  getAllStoreData();
},[])

const getAllStoreData = async () => {
  setLoaderForGetAll(true);
  await ApiGet(`store/find?userId=${userInfo?._id}`)
    .then((res) => {
      setGetAllStore(res?.data?.payload?.find((e)=>e?._id === storeId));
    })
    .catch((err) => {
      setLoaderForGetAll(false);
    });
};

  const handleClickedMap = (e) => {
    Setlatitude(e.latLng.lat());
    Setlongtitude(e.latLng.lng());
  };

  const onhandleMonthChange = (e, index) => {
    const { name, value } = e.target;
    monthDay[index][name] = value;

    setMonthDay([...monthDay]);
  };
  const onhandlecustometabChange = (e, index) => {
    const { checked } = e.target;
    monthDay[index].closed = checked;
    setMonthDay([...monthDay]);
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;

    setErrors({ ...errors, [name]: "" });
    if (
      name === "coinIcon" ||
      name === "delivery" ||
      name === "digital" ||
      name === "brickandmortar" ||
      name === "isFeatured"
    ) {
      setGetAllStore({ ...getAllStore, [name]: e.target.checked });
      return;
    }
    setGetAllStore({ ...getAllStore, [name]: value });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setGetAllStore({ ...getAllStore, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };


  const editgameData = async (e) => {
    // if (formValidation()) {
    let formData = new FormData();

    // formData.append("userId", storeData?.userId);
    formData.append("name", getAllStore?.name);
    formData.append("details", getAllStore?.details);
    formData.append("coinIcon", getAllStore?.coinIcon === true ? true : false);
    formData.append("delivery", getAllStore?.delivery === true ? true : false);
    formData.append("digital", getAllStore?.digital === true ? true : false);
    formData.append(
      "brickandmortar",
      getAllStore?.brickandmortar === true ? true : false
    );
    formData.append(
      "isFeatured",
      getAllStore?.isFeatured === true ? true : false
    );
    formData.append("email", getAllStore?.email);
    formData.append("url", getAllStore?.url);
    formData.append("phone", phoneInput);
    formData.append("description", getAllStore?.description);
    formData.append("address", getAllStore?.address ? getAllStore?.address : "");
    formData.append("state", getAllStore?.state ? getAllStore?.state : "");
    formData.append("zipCode", getAllStore?.zipCode ? getAllStore?.zipCode : "");
    formData.append("city", getAllStore?.city ? getAllStore?.city : "");
    formData.append("country", getAllStore?.country ? getAllStore?.country : "");
    formData.append("storeImage", getAllStore?.storeImage);
    formData.append("lat", latitude);
    formData.append("long", longtitude);
    formData.append(
      "customHours",
      getAllStore?.customHours === "24/7" ? false : true
    );
    formData.append("customTime", JSON.stringify(monthDay));
    formData.append("status", getAllStore?.status);

    setLoader(true);
    await ApiPut(`store/update?id=${storeId}`, formData)
      .then((res) => {
        toast.success("Store update successfully");
        getAllStoreData();
        window.location.reload();
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
        setLoader(false);
      });
    // }
  };


  return (
    <>
      <h1>Edit Store</h1>
      <ToastContainer />
      <div className="m-auto w-1000px pt-10">
        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Name
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="title"
                name="name"
                value={getAllStore?.name}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {/* {errors["name"]} */}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Image
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <div className="labelMainDiv">
                <label htmlFor="uploadStoreImage" className="lableForImage">
                  select file
                </label>
                {getAllStore?.storeImage ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
              </div>
              <input
                type="file"
                className={`form-control form-control-lg form-control-solid`}
                name="storeImage"
                id="uploadStoreImage"
                hidden
                onChange={(e) => handleOnChnageAddImg(e)}
              />
            </div>
            <div>
              {getAllStore?.storeImage && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          getAllStore?.storeImage
                            ?.toString()
                            ?.includes(".com") === true
                            ? getAllStore?.storeImage
                            : URL.createObjectURL(getAllStore?.storeImage)
                        }
                      />
                    )}
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {/* {errors["storeImage"]} */}
            </span>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <label
            htmlFor="coinIcon"
            className="col-xl-3 col-lg-3 col-form-label"
          >
            <img
              src={StoreIconDetails}
              alt="StoreIconDetails"
              className="storeImg1"
            />
          </label>
          <div className="col-lg-9 col-xl-6">
            <div>
              <input
                type="checkbox"
                className={`form-control form-control-lg form-control-solid `}
                id="coinIcon"
                name="coinIcon"
                value={getAllStore?.coinIcon}
                defaultChecked={getAllStore?.coinIcon}
                onChange={(e) => onhandleChange(e)}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {/* {errors["coinIcon"]} */}
            </span>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <div htmlFor="delivery" className="col-xl-3 col-lg-3 col-form-label">
            <label className="deleviryBoxAlignment">DELIVERY</label>
          </div>
          <div className="col-lg-9 col-xl-6">
            <div>
              <input
                type="checkbox"
                className={`form-control form-control-lg form-control-solid `}
                id="delivery"
                name="delivery"
                value={getAllStore?.delivery}
                defaultChecked={getAllStore?.delivery}
                onChange={(e) => onhandleChange(e)}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["delivery"]}
            </span>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <div htmlFor="digital" className="col-xl-3 col-lg-3 col-form-label">
            <label className="deleviryBoxAlignment digital">digital</label>
          </div>
          <div className="col-lg-9 col-xl-6 align-items-center">
            <div>
              <input
                type="checkbox"
                className={`form-control form-control-lg form-control-solid `}
                id="digital"
                name="digital"
                value={getAllStore?.digital}
                defaultChecked={getAllStore?.digital}
                onChange={(e) => onhandleChange(e)}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["digital"]}
            </span>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <div
            htmlFor="brickandmortar"
            className="col-xl-3 col-lg-3 col-form-label"
          >
            <label className="deleviryBoxAlignment mortar">
              brickandmortar
            </label>
          </div>
          <div className="col-lg-9 col-xl-6">
            <div>
              <input
                type="checkbox"
                className={`form-control form-control-lg form-control-solid `}
                id="brickandmortar"
                name="brickandmortar"
                value={getAllStore?.brickandmortar}
                defaultChecked={getAllStore?.brickandmortar}
                onChange={(e) => onhandleChange(e)}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["brickandmortar"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label
            htmlFor="coinIcon"
            className="col-xl-5 col-lg-5 col-form-label label-alignment"
          >
            Is-Featured
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="checkbox"
                className={`form-control form-control-lg `}
                id="isFeatured"
                name="isFeatured"
                value={getAllStore?.isFeatured}
                defaultChecked={getAllStore?.isFeatured}
                onChange={(e) => onhandleChange(e)}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["isFeatured"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            E-mail
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="email"
                className={`form-control rounded-lg custom-style`}
                id="email"
                name="email"
                value={getAllStore?.email}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["email"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Url
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="url"
                name="url"
                value={getAllStore?.url}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["url"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Phone
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <PhoneInput
                      placeholder="Enter Phone Number"
                      name="phone"
                      maxLength="18"
                      value={phoneInput}
                      onChange={(e) => {
                        setphoneInput(e);
                      }}
                    />
            </div>

            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["phone"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Detail
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="details"
                name="details"
                value={getAllStore?.details}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["details"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Description
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="description"
                name="description"
                value={getAllStore?.description}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["description"]}
            </span>
          </div>
        </div>
        {getAllStore?.brickandmortar === true && (
          <>
          
        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Address
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="address"
                name="address"
                value={getAllStore?.address}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["address"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            State
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="state"
                name="state"
                value={getAllStore?.state}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["state"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            Zip Code
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="number"
                className={`form-control rounded-lg custom-style`}
                id="zipCode"
                name="zipCode"
                value={getAllStore?.zipCode}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["zipCode"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            city
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="city"
                name="city"
                value={getAllStore?.city}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["city"]}
            </span>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
            country
          </label>
          <div className="col-lg-7 col-xl-7">
            <div>
              <input
                type="text"
                className={`form-control rounded-lg custom-style`}
                id="country"
                name="country"
                value={getAllStore?.country}
                onChange={(e) => {
                  onhandleChange(e);
                }}
              />
            </div>
            <span
              style={{
                color: "red",
                top: "5px",
                fontSize: "12px",
              }}
            >
              {errors["country"]}
            </span>
          </div>
        </div>
          </>
 )}
        <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Opening hour
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="radio"
                      className={`form-control rounded-lg custom-style`}
                      id="customHours24"
                      name="customHours"
                      value="24/7"
                      checked={
                        getAllStore?.customHours == "24/7" ||
                        getAllStore?.customHours === false
                      }
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <label for="htmcustomHoursl">24/7</label>
                  <div>
                    <input
                      type="radio"
                      className={`form-control rounded-lg custom-style`}
                      id="customHours24"
                      name="customHours"
                      checked={
                        getAllStore?.customHours == "Customed" ||
                        getAllStore?.customHours === true
                      }
                      // value={getAllStore?.customHours}
                      value="Customed"
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <label for="customHours24">Customed</label>
                </div>
              </div>
              <span
                style={{
                  color: "red",
                  top: "5px",
                  fontSize: "12px",
                }}
              >
                {errors["customHours"]}
              </span>

              {getAllStore?.customHours === true ||
              getAllStore?.customHours == "Customed" ? (
                <>
                  {monthDay?.map((item, i) => {
                    return (
                      <div className="d-flex justify-content-between">
                        <div>
                          <h3>{item?.day}</h3>
                        </div>
                        <div className="d-flex">
                          {item?.open === "" && item?.close === "" && (
                            <>
                              <input
                                type="checkbox"
                                className={`form-control rounded-lg custom-style`}
                                id={item?.day}
                                name={item?.day}
                                value={item?.day}
                                checked={item?.closed}
                                onChange={(e) => {
                                  onhandlecustometabChange(e, i);
                                }}
                              />
                              <h3>closed</h3>
                            </>
                          )}

                          {!item?.closed && (
                            <>
                              <input
                                type="time"
                                className={`form-control rounded-lg custom-style`}
                                name="open"
                                value={item?.open}
                                onChange={(e) => {
                                  onhandleMonthChange(e, i);
                                }}
                              />
                              <input
                                type="time"
                                className={`form-control rounded-lg custom-style`}
                                name="close"
                                value={item?.close}
                                onChange={(e) => {
                                  onhandleMonthChange(e, i);
                                }}
                              />
                            </>
                          )}

                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors[`closed${i}`]}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}


<div className="form-group row">
                <div className="col-xs-12 col-lg-12">
                  <div className="my-12">
                    <MyMapComponent
                      key="map"
                      longtitude={longtitude || 0}
                      latitude={latitude || 0}
                      handleClickedMap={(e) => handleClickedMap(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  latitude
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="lat"
                      name="lat"
                      value={latitude}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  {/* <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["lat"]}
                  </span> */}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  longitude
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="long"
                      name="long"
                      value={longtitude}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                 <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["long"]}
                  </span>
                </div>
              </div>



              <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Status
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    {userInfo?.role === "admin" ? (
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                          name="status"
                          defaultValue={getAllStore?.status}
                        >
                          <option value="select for status" selected disabled>
                            select Status
                          </option>
                          <option value="active">Active</option>
                          <option value="suspended">Suspended</option>
                        </select>
                      </div>
                    ) : (
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                          name="status"
                          defaultValue={getAllStore?.status}
                        >
                          <option value="select for status" selected disabled>
                            select Status
                          </option>
                          <option value="active">Active</option>
                          <option value="deactivated">De-Active</option>
                        </select>
                      </div>
                    )}
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["status"]}
                    </span>
                  </div>
                </div>






      </div>
      <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button
                      className="btn position-relative center"
                      type="button"
                    >
                      <img src={AddPrizeImg} alt={AddPrizeImg} />
                      <img
                        src={StoreHomeImg}
                        alt="StoreHomeImg"
                        className="add-prizes-child-img"
                      />
                     Edit  Store
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className="button-details-alignment">
                  <button
                    className="btn position-relative center"
                    onClick={(e) => {
                       editgameData(e)
                    }}
                  >
                    <img src={AddPrizeImg} alt={AddPrizeImg} />
                    <img
                      src={StoreHomeImg}
                      alt="StoreHomeImg"
                      className="add-prizes-child-img"
                    />
                    Edit  Store
                  </button>
                </div>
              )}
            </div>
    </>
  );
}
