import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import PrizeImg from "../../_assets/plugins/keenthemes-icons/svg/Add_Prize.png";
import AddPrizeImg from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import AddPrizeStarImg from "../../_assets/plugins/keenthemes-icons/svg/Prizes.png";
import TicketsCollectionIcon from "../../_assets/plugins/keenthemes-icons/svg/Tickets_Collected.png";
import PrizesIcon from "../../_assets/plugins/keenthemes-icons/svg/Store_Prizes_Icon.png";
import EditUser from "../../_assets/plugins/keenthemes-icons/svg/editIcon.png";
import UserPlusIcon from "../../_assets/plugins/keenthemes-icons/svg/Plus-icon.png";
import DefaultImg from "../../../_metronic/layout/components/Logos/static.png";
import { getUserInfo } from "../../../utils/user.util";

export default function GetPrizeForOwner(props) {
  const { prizeData } = props;

  const [itamData, setItamData] = useState({});
  const [addItam, setAddItam] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllStoreData, setGetAllStoreData] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [itemIdForEdit, setItemIdForEdit] = useState();
  const [itamIdForDelete, setItamIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [show, setShow] = useState(false);
  const [getCategoryData, setGetCategoryData] = useState({});
  const [forQuantity, setForQuantity] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  let item = localStorage.getItem("localStoreId");
  item = item && JSON.parse(item);

  useEffect(() => {
    getAllStoreItamData();
  }, [page, countPerPage]);

  useEffect(() => {
    getAllCategory();
    getCount();
  }, []);

  const getCount = async () => {
    await ApiGet(`store/get-ordered-store-items?storeId=${item}`)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.totalAmount) {
            setTotalCount(res?.data?.totalAmount);
          } else {
            setTotalCount(0);
          }
        }
      })
      .catch((err) => {});
  };
  const addItemModelClose = (e) => {
    setAddItam(false);
    setIsEditApi(false);
    setErrors(false);
    setItamData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setItamData({ ...itamData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setItamData({ ...itamData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!itamData?.itemName?.trim() || itamData?.itemName === "") {
      formvalied = false;
      errors["itemName"] = "*Please enter itemName.";
    }
    if (
      !itamData?.itemDescription?.trim() ||
      itamData?.itemDescription === ""
    ) {
      formvalied = false;
      errors["itemDescription"] = "*Please enter item Description.";
    }
    if (!itamData?.itemPrice || itamData?.itemPrice === "") {
      formvalied = false;
      errors["itemPrice"] = "*Please enter item Price.";
    }
    if (forQuantity === false) {
      if (!itamData?.stock) {
        formvalied = false;
        errors["stock"] = "*Please enter Stock";
      }
    }
    if (!itamData?.delivery) {
      formvalied = false;
      errors["delivery"] = "*Please enter delivery";
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  const getAllCategory = async () => {
    await ApiGet(`category/find`)
      .then((res) => {
        setGetCategoryData(res?.data?.payload);
      })
      .catch((err) => {});
  };

  const getAllStoreItamData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        `storeItem/find?storeId=${item.trim()}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStoreData(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `storeItem/find?storeId=${item.trim()}?latter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStoreData(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const addStoreItemData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("storeId", item.trim());
      formData.append("itemName", itamData?.itemName);
      formData.append("itemDescription", itamData?.itemDescription);
      formData.append("storeItemImage", itamData?.storeItemImage ?? DefaultImg);
      formData.append("itemPrice", itamData?.itemPrice);
      formData.append("stock", itamData?.stock);
      formData.append("unlimited", false);
      formData.append("isForAdult", itamData?.isForAdult);
      formData.append("categoryId", itamData?.categoryId);

      let formData1 = new FormData();
      formData1.append("storeId", item.trim());
      formData1.append("itemName", itamData?.itemName);
      formData1.append("itemDescription", itamData?.itemDescription);
      formData1.append(
        "storeItemImage",
        itamData?.storeItemImage ?? DefaultImg
      );
      formData1.append("itemPrice", itamData?.itemPrice);
      formData1.append("unlimited", true);
      formData1.append("isForAdult", itamData?.isForAdult);
      formData1.append("categoryId", itamData?.categoryId);
      formData1.append("delivery", itamData?.requires === "yes" ? true : false);


      await ApiPost(
        `storeItem/create`,
        forQuantity === false ? formData : formData1
      )
        .then((res) => {
          toast.success("item add successfully");
          setAddItam(false);
          setIsEditApi(false);
          getAllStoreItamData();
          setItamData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editStoreItemData = async (e) => {
    if (formValidation()) {
      let formData = new FormData();
      formData.append("storeId", item.trim());
      formData.append("itemName", itamData?.itemName);
      formData.append("itemDescription", itamData?.itemDescription);
      formData.append("storeItemImage", itamData?.storeItemImage);
      formData.append("itemPrice", itamData?.itemPrice);
      formData.append("stock", itamData?.stock);
      formData.append("unlimited", false);
      formData.append("isForAdult", itamData?.isForAdult);
      formData.append("categoryId", itamData?.categoryId);

      let formData1 = new FormData();
      formData1.append("storeId", item.trim());
      formData1.append("itemName", itamData?.itemName);
      formData1.append("itemDescription", itamData?.itemDescription);
      formData1.append("storeItemImage", itamData?.storeItemImage);
      formData1.append("itemPrice", itamData?.itemPrice);
      formData1.append("unlimited", true);
      formData1.append("isForAdult", itamData?.isForAdult);
      formData1.append("categoryId", itamData?.categoryId);
      formData1.append("delivery", itamData?.requires === "yes" ? true : false);


      setLoader(true);

      await ApiPut(
        `storeItem/update?id=${itemIdForEdit}`,
        forQuantity === false ? formData : formData1
      )
        .then((res) => {
          toast.success("item update successfully");
          getAllStoreItamData();
          setAddItam(false);
          setIsEditApi(false);
          setItamData([]);
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  // For Delete game
  const deleteStoreItemData = async () => {
    await ApiDelete(`storeItem/delete?id=${itamIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Delete item successfully");
          setShow(false);
          getAllStoreItamData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  //For search

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreItamData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreItamData();
    }
  }, [debouncedSearchTerm]);

  // -------------------------DATA TABLE--------------------
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row?.itemName,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row?.itemPrice,
      sortable: true,
    },
    {
      name: "Description",
      cell: (row) => {
        return (
          <div className="showmore-class  p-2 ">
            {row?.itemDescription?.length > 200
              ? row?.itemDescription?.substring(0, 200) + "..."
              : row?.itemDescription}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row?.categoryId?.name,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img src={row?.storeItemImage[0]} width={100} height={50} />
            </div>
          </>
        );
      },
      width: "15%",
    },
    {
      name: "Quantity",
      selector: (row) => (row?.stock ? row?.stock : "Unlimited"),
      sortable: true,
    },
    {
      name: "Is For Adult",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 checkAdult  rounded">
              {row?.isForAdult === true ? (
                <span className="checkYes">YES</span>
              ) : (
                <span className="checkNo">NO</span>
              )}
            </div>
          </>
        );
      },
      sortable: true,
    },

    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            {/* edit category */}
            <div className="d-flex justify-content-between mr-3">
              <div className="cursor-pointer pl-2">
                <Tooltip title="edit item" arrow>
                  <button
                    style={{
                      background: "#002a5a",
                      color: "white",
                      borderRadius: "20px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) => {
                      setIsEditApi(true);
                      setAddItam(true);
                      setItemIdForEdit(row?._id);
                      // setForQuantity(row?.unlimited);
                      setItamData({
                        categoryId: row?.categoryId?._id,
                        itemName: row?.itemName,
                        itemDescription: row?.itemDescription,
                        storeItemImage: row?.storeItemImage,
                        itemPrice: row?.itemPrice,
                        isForAdult: row?.isForAdult,
                        delivery: row?.delivery
                      });
                    }}
                  >
                    Manage
                  </button>
                </Tooltip>
              </div>

              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setItamIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete item" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={PrizesIcon} alt="PrizesIcon" className="coupon" />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">Prizes Available</h6>
            </div>
          </div>

          <div className="align-items-center d-flex ml-40">
            <img
              src={TicketsCollectionIcon}
              alt="TicketsCollectionIcon"
              className="coupon"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">{totalCount}</h1>
              <h6 className="text-white">Tickets Collected</h6>
            </div>
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Prizes</h2> */}
            </div>
            <div className="cus-medium-button-style button-height button-details-alignment">
              <button
                className="btn"
                onClick={() => {
                  setAddItam(true);
                }}
              >
                <img src={AddPrizeImg} alt={AddPrizeImg} />
                <img
                  src={AddPrizeStarImg}
                  alt={AddPrizeStarImg}
                  className="start-img"
                />
                Add Prizes
              </button>
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          //Data Table

          <DataTable
            columns={columns}
            data={getAllStoreData}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Prize?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteStoreItemData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addItam === true ? (
        <Dialog fullScreen open={addItam}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addItemModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="heading-banner d-flex justify-content-center">
              {isEditApi === true ? (
                <img src={EditUser} alt="EditUser" className="coupon mr-4" />
              ) : (
                <img
                  src={UserPlusIcon}
                  alt="UserPlusIcon"
                  className="coupon mr-4"
                />
              )}
              <img src={PrizeImg} alt="PrizeImg" className="coupon mr-4" />
            </div>

            <div className="m-auto w-1000px pt-10">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Category
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      name="categoryId"
                      id="categoryId"
                      defaultValue={itamData?.categoryId}
                      className={`form-control rounded-lg custom-style `}
                      aria-label="Default select example"
                      onChange={(e) => onhandleChange(e)}
                    >
                      <option selected disabled>
                        select Category
                      </option>
                      {getCategoryData &&
                        getCategoryData?.map((data) => (
                          <option value={data._id}>{data?.name}</option>
                        ))}
                    </select>

                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["userId"]}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Item Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style `}
                      id="title"
                      name="itemName"
                      value={itamData?.itemName}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemName"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Item Description
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      className={`form-control rounded-lg custom-style `}
                      id="itemDescription"
                      name="itemDescription"
                      value={itamData?.itemDescription}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemDescription"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Item Image
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <div className="labelMainDiv">
                      <label htmlFor="itamImage" className="lableForImage ">
                        select file
                      </label>
                      {itamData?.companyLogo ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg  rounded-lg custom-style`}
                      name="storeItemImage"
                      id="itamImage"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {itamData?.storeItemImage && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          itamData?.storeItemImage
                            ?.toString()
                            ?.includes(".com") === true
                            ? itamData?.storeItemImage
                            : URL.createObjectURL(itamData?.storeItemImage)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["storeItemImage"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Item Price
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style `}
                      id="itemPrice"
                      name="itemPrice"
                      value={itamData?.itemPrice}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemPrice"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Quantity
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div className="row">
                    <div className="check-box-alignment col-lg-5 col-xl-5">
                      {/* <input
                      type="text"
                      className={`form-control form-control-lg rounded-lg custom-style`}
                      id="stock"
                      name="stock"
                      value={itamData?.stock}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    /> */}
                      <input
                        type="checkbox"
                        id="unlimited"
                        name="unlimited"
                        checked={forQuantity}
                        onClick={() => setForQuantity(!forQuantity)}
                      />
                      <label for="unlimited">unlimited</label>
                    </div>
                  </div>
                  {forQuantity === false && (
                    <div className="form-group row pt-10">
                      <label className="col-xl-5 col-lg-5 col-form-label label-alignment mt-0">
                        Limit
                      </label>
                      <div className="col-lg-7 col-xl-7">
                        <div>
                          <input
                            type="number"
                            className={`form-control rounded-lg custom-style`}
                            id="stock"
                            name="stock"
                            value={itamData?.stock}
                            onChange={(e) => {
                              onhandleChange(e);
                            }}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["stock"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Is For Adult
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      className={`form-control rounded-lg custom-style `}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                      defaultValue={itamData?.isForAdult}
                      name="isForAdult"
                    >
                      <option value="select for adult" selected disabled>
                        select for adult
                      </option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemPrice"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Requires Delivery
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div className="row">
                    <div className="d-flex justify-content-center">
                    <input
                      type="radio"
                      id="yes"
                      name="delivery"
                      value="yes"
                      checked={itamData?.delivery === "yes" ? true : false}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                    <label for="yes" style={{alignSelf:"center",margin:"0 14px",fontSize:"15px"}}>Yes</label>
                    </div>

                    <div className="d-flex justify-content-center">
                    <input
                      type="radio"
                      id="no"
                      name="delivery"
                      value="no"
                      checked={itamData?.delivery === "no" ? true : false}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />

                    <label for="no"  style={{alignSelf:"center" ,margin:"0 14px",fontSize:"15px"}}>No</label>
                    </div>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["delivery"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button className="btn" type="button">
                      <img src={AddPrizeImg} alt={AddPrizeImg} />
                      <img
                        src={AddPrizeStarImg}
                        alt={AddPrizeStarImg}
                        className="start-img"
                      />
                      {isEditApi ? "Edit item" : "Add item"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className="button-details-alignment">
                  <button
                    className="btn"
                    onClick={(e) => {
                      isEditApi ? editStoreItemData(e) : addStoreItemData(e);
                    }}
                  >
                    <img src={AddPrizeImg} alt={AddPrizeImg} />
                    <img
                      src={AddPrizeStarImg}
                      alt={AddPrizeStarImg}
                      className="start-img"
                    />
                    {isEditApi ? "Edit  item" : "Add item"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
