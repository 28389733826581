import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { getUserInfo } from "../../../utils/user.util";
let item = localStorage.getItem("localStoreId");
item = item && JSON.parse(item);

export default function PrizesReport(props) {
  const { getAllStore } = props;
  const [itamData, setItamData] = useState({});
  const [addItam, setAddItam] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllStoreData, setGetAllStoreData] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [itemIdForEdit, setItemIdForEdit] = useState();
  const [itamIdForDelete, setItamIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [show, setShow] = useState(false);
  const [getCategoryData, setGetCategoryData] = useState({});
  const [downloadLink, setDownloadLink] = useState();

  const userInfo = getUserInfo();

  useEffect(() => {
    getAllStoreItamData();
  }, [page, countPerPage]);
  useEffect(() => {
    getAllCategory();
    prizeReportDownload();
  }, []);

  const addItemModelClose = (e) => {
    setAddItam(false);
    setIsEditApi(false);
    setErrors(false);
    setItamData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setItamData({ ...itamData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setItamData({ ...itamData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!itamData?.itemName?.trim() || itamData?.itemName === "") {
      formvalied = false;
      errors["itemName"] = "*Please enter itemName.";
    }
    if (
      !itamData?.itemDescription?.trim() ||
      itamData?.itemDescription === ""
    ) {
      formvalied = false;
      errors["itemDescription"] = "*Please enter item Description.";
    }
    if (itamData && !itamData?.storeItemImage) {
      formvalied = false;
      errors["storeItemImage"] = "*Please select Image.";
    }
    if (!itamData?.itemPrice || itamData?.itemPrice === "") {
      formvalied = false;
      errors["itemPrice"] = "*Please enter item Price.";
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  const getAllCategory = async () => {
    await ApiGet(`category/find`)
      .then((res) => {
        setGetCategoryData(res?.data?.payload);
      })
      .catch((err) => {});
  };
  const getAllStoreItamData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        userInfo?.role === "owner"
          ? `storeItem/find?storeId=${item.trim()}&page=${page}&limit=${countPerPage}`
          : `storeItem/find?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStoreData(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        userInfo?.role === "owner"
          ? `storeItem/find?storeId=${item.trim()}&page=${page}&limit=${countPerPage}`
          : `storeItem/find?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStoreData(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  // const addStoreItemData = async (e) => {
  //   setIsEditApi(false);
  //   if (formValidation()) {
  //     setLoader(true);
  //     let formData = new FormData();
  //     formData.append("storeId", (item).trim());
  //     formData.append("itemName", itamData?.itemName);
  //     formData.append("itemDescription", itamData?.itemDescription);
  //     formData.append("storeItemImage", itamData?.storeItemImage);
  //     formData.append("itemPrice", itamData?.itemPrice);
  //     formData.append("isForAdult", itamData?.isForAdult);
  //     formData.append("categoryId", itamData?.categoryId);

  //     await ApiPost(`storeItem/create`, formData)
  //       .then((res) => {
  //         toast.success("item add successfully");
  //         setAddItam(false);
  //         setIsEditApi(false);
  //         getAllStoreItamData();
  //         setItamData({});
  //         setLoader(false);
  //       })
  //       .catch((err) => {
  //         toast.error(err?.response?.statusText);
  //         setLoader(false);
  //       });
  //   }
  // };

  // const editStoreItemData = async (e) => {
  //   if (formValidation()) {
  //     let formData = new FormData();
  //     formData.append("storeId", (item).trim());
  //     formData.append("itemName", itamData?.itemName);
  //     formData.append("itemDescription", itamData?.itemDescription);
  //     formData.append("storeItemImage", itamData?.storeItemImage);
  //     formData.append("itemPrice", itamData?.itemPrice);
  //     formData.append("isForAdult", itamData?.isForAdult);
  //     formData.append("categoryId", itamData?.categoryId);
  //     setLoader(true);

  //     await ApiPut(`storeItem/update?id=${itemIdForEdit}`, formData)
  //       .then((res) => {
  //         toast.success("item update successfully");
  //         getAllStoreItamData();
  //         setAddItam(false);
  //         setIsEditApi(false);
  //         setItamData([]);
  //         setLoader(false);
  //       })
  //       .catch((err) => {
  //         toast.error(err?.response?.statusText);
  //         setLoader(false);
  //       });
  //   }
  // };

  // For Delete game
  // const deleteStoreItemData = async () => {
  //   await ApiDelete(`storeItem/delete?id=${itamIdForDelete}`)
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         toast.success("Delete item successfully");
  //         setShow(false);
  //         getAllStoreItamData();
  //       } else {
  //         toast.error("something went wrong please try again");
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.statusText);
  //     });
  // };

  //For search

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreItamData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreItamData();
    }
  }, [debouncedSearchTerm]);

  // -------------------------DATA TABLE--------------------
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row?.itemName,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row?.itemPrice,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => {
        return (
          <div className="showmore-class  p-2 ">
            {row?.itemDescription?.length > 200
              ? row?.itemDescription?.substring(0, 200) + "..."
              : row?.itemDescription}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row?.categoryId?.name,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img src={row?.storeItemImage[0]} width={100} height={50} />
            </div>
          </>
        );
      },
      width: "15%",
    },
    {
      name: "Is For Adult",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 checkAdult  rounded">
              {row?.isForAdult === true ? (
                <span className="checkYes">YES</span>
              ) : (
                <span className="checkNo">NO</span>
              )}
            </div>
          </>
        );
      },
      sortable: true,
    },

    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    // {
    //   name: "Actions",
    //   cell: (row) => {
    //     return (
    //       <>
    //         {/* edit category */}
    //         <div className="d-flex justify-content-between mr-3">
    //           <div
    //             className="cursor-pointer pl-2"
    //             onClick={(e) => {
    //               setIsEditApi(true);
    //               setAddItam(true);
    //               setItemIdForEdit(row?._id);
    //               setItamData({
    //                 categoryId: row?.categoryId?._id,
    //                 itemName: row?.itemName,
    //                 itemDescription: row?.itemDescription,
    //                 storeItemImage: row?.storeItemImage,
    //                 itemPrice: row?.itemPrice,
    //                 isForAdult: row?.isForAdult,
    //               });
    //             }}
    //           >
    //             <Tooltip title="edit item" arrow>
    //               <CreateIcon />
    //             </Tooltip>
    //           </div>

    //           <div
    //             data-toggle="modal"
    //             data-target="#exampleModal"
    //             className="cursor-pointer"
    //             onClick={(e) => {
    //               setItamIdForDelete(row?._id);
    //               setShow(true);
    //             }}
    //           >
    //             <Tooltip title="delete item" arrow>
    //               <DeleteIcon />
    //             </Tooltip>
    //           </div>
    //         </div>
    //       </>
    //     );
    //   },
    //   width: "5%",
    // },
  ];

  const prizeReportDownload = async () => {
    await ApiGet(
      userInfo?.role === "owner"
        ? `storeItem/download-report?storeId=${item.trim()}&page=${page}&limit=${countPerPage}`
        : `storeItem/download-report?page=${page}&limit=${countPerPage}`
    )
      .then((res) => {
        // window.open(res?.data?.data)
        setDownloadLink(res?.data?.payload);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };
  // useEffect(() => {
  //   prizeReportDownload();
  // }, []);

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Prizes</h2>
            </div>
            <div className="col">
              {/* <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div> */}
            </div>
            {/* <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-color mr-2"
                onClick={() => {
                  setAddItam(true);
                }}
              >
                Add item
              </button>
            </div> */}
            <a href={downloadLink}>
              <button
                className="btn btn-color mr-2"
                onClick={() => prizeReportDownload()}
              >
                Download
              </button>
            </a>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          //Data Table

          <DataTable
            columns={columns}
            data={getAllStoreData}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
    </>
  );
}
