/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Changepassword from "../pages/changepassowrd";
import Logo from "../../../../_metronic/layout/components/Logos/gstreak.png";
import AssignStoreMsg from "../../../../_metronic/components/AssignStoreMsg/AssignStoreMsg";
export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <div
            className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
            style={{ background: "#002a5a" }}
          >
            {/* <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/changepassword"
                  component={Changepassword}
                />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div> */}
            <div className="sideImg-alignment">
              <div className="logo-img">
                <img alt="" src={Logo} width="300px"></img>
              </div>

              <div className="pt-19">
                <p className="fa-3 text-white text-center">
                  Trusted by the 400,000+ marketing teams, agencies and
                  freelancers
                </p>
              </div>

              <div className="pt-12 text-center">
                <span className="text-white text-center"  style={{fontSize:"18px"}}>
                  By creating an account, you are agreeing to our Terms of
                  Service and Privacy Policy. You also agree to receive
                  product-related marketing emails from gstreak, which you
                  can unsubscribe from at any time.
                </span>
              </div>
              
            </div>

            <div className="d-flex justify-content-center">
                <h5 style={{color:"#9f9292"}}>developed by <a href="https://rejoicehub.com/" style={{color:"#ffa500d4"}} target="_blank">Rejoicehub Solutions</a></h5>
              </div>

          </div>

          <div className="login-aside d-flex flex-row-auto   justify-content-center bgi-size- bgi-no-repeat p-10 p-lg-10">
            <div className="d-flex align-items-center justify-content-center" style={{width:"inherit"}}>
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <ContentRoute
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  <ContentRoute
                    path="/changepassword"
                    component={Changepassword}
                  />

                  <Redirect from="/auth" exact={true} to="/auth/login" />
                 
                  <ContentRoute
            exact
            path="/assign-store-message"
            component={AssignStoreMsg}
          />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
