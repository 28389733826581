import React from "react";
import { useState, useEffect } from "react";
import { ApiGet, ApiPut } from "../../../helpers/API/ApiData";
import { ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import ReservationsIcon from "../../_assets/plugins/keenthemes-icons/svg/Reservations_Icon.png";
import ReservationsIconComplete from "../../_assets/plugins/keenthemes-icons/svg/Reservations_Completed.png";
import { Tooltip } from "@material-ui/core";
import OrderDetails from "../OrderDetails/OrderDetails";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import DatePicker from "react-datepicker";


export default function Order() {
  const [getAllOrder, setGetAllOrder] = useState({});
  const [, setGetAllCompleteOrder] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [, setCount1] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [getOrderDetails, setGetOrderDetails] = useState(false);
  const [storeOrderData, setStoreOrderData] = useState({});
  const [indexNumber, setIndexNumber] = useState();
  var date = new Date();
  var firstDay = new Date(date?.getFullYear(), date?.getMonth(), 1);
  var lastDay = new Date(date?.getFullYear(), date?.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    getAllOrderData();
    getAllOrderCompleteData();
  }, [page, countPerPage, startDate, endDate]);

  const closeOrderDetailModal = () => {
    setGetOrderDetails(false);
  };

  const getAllOrderData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : ""
    await ApiGet(`order/find?page=${page}&limit=${countPerPage}&days=30${url}&startDate=${(moment(startDate).format("YYYY-MM-DD"))}&endDate=${(moment(endDate).format("YYYY-MM-DD"))}`)
      .then((res) => {
        setGetAllOrder(res?.data?.data);
        setCount(res?.data?.totalAmount?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });

  };
  const completeresrvation = (e) => {
    ApiPut(`order/update?orderId=${storeOrderData?._id}&status=complete`)
      .then((res) => {
        setGetOrderDetails(false);
        window.location.reload();
      })
      .catch((err) => { })
  };

  const canceledresrvation = (e) => {
    ApiPut(`order/update?orderId=${storeOrderData?._id}&status=canceled`)
      .then((res) => {
        setGetOrderDetails(false);
        window.location.reload();
      })
      .catch((err) => { })
  };

  const getAllOrderCompleteData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : ""
    await ApiGet(`order/find?status=complete${url}`)
      .then((res) => {
        setGetAllCompleteOrder(res?.data?.data);
        setCount1(res?.data?.totalAmount?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "Order ID.",
      // selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      selector: (row)=>row?.order_Id,
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Item name",
      selector: (row) =>
        row?.Item[0]?.itemId?.itemName ? row?.Item[0]?.itemId?.itemName : "-",
      sortable: true,
    },

    {
      name: "Item description",
      selector: (row) => {
        return (<>
          {row?.Item[0]?.itemId?.itemDescription
            ? row?.Item[0]?.itemId?.itemDescription?.length > 200
              ? row?.Item[0]?.itemId?.itemDescription?.substring(0, 200) +
              "..."
              : row?.Item[0]?.itemId?.itemDescription
            : "-"}
        </>);
      },
      sortable: true,
    },
    {
      name: "Item price",
      selector: (row) =>
        row?.Item[0]?.itemId?.itemPrice ? row?.Item[0]?.itemId?.itemPrice : "-",
      sortable: true,
    },
    {
      name: "Total price",
      selector: (row) => (row?.totalAmount ? row?.totalAmount : "-"),
      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "pending" && (
              <div
                style={{
                  background: "yellow",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  {row?.status}
                </p>
              </div>
            )}
            {row?.status === "canceled" && (
              <div
                style={{
                  background: "red",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  {row?.status}
                </p>
              </div>
            )}
            {row?.status === "complete" && (
              <div
                style={{
                  background: "#22cc00",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  {row?.status}
                </p>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row?.address ? row?.address : "-"),
      sortable: true,
    },
    {
      name: "city",
      selector: (row) => (row?.city ? row?.city : "-"),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => (row?.state ? row?.state : "-"),
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row , index) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div className="cursor-pointer pl-2">
                <Tooltip title="Order" arrow>
                  {["complete", "canceled"].includes(row?.status) ?
                    <button
                      style={{
                        background: "hwb(211deg 56% 15%)",
                        color: "white",
                        borderRadius: "20px",
                        whiteSpace: "nowrap"
                      }}
                      disabled>
                      Manage
                    </button>

                    :
                    <button
                      style={{
                        background: "#002a5a",
                        color: "white",
                        borderRadius: "20px",
                        whiteSpace: "nowrap"
                      }}
                      onClick={() => {
                        setGetOrderDetails(true);
                        setStoreOrderData(row);
                        setIndexNumber((page - 1) * countPerPage + (index + 1))
                      }}
                    >
                      Manage
                    </button>
                    } 
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img
              src={ReservationsIcon}
              alt="ReservationsIcon"
              className="coupon"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">All Reservation</h6>
            </div>
          </div>

          <div className="align-items-center d-flex ml-40">
            <img
              src={ReservationsIconComplete}
              alt="ReservationsIconComplete"
              className="coupon"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">123</h1>
              <h6 className="text-white">Complete Reservation</h6>
            </div>
          </div>
        </div>
        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 justify-content-between align-items-center">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Reservations</h2> */}
            </div>
            <div className="new-margin-bottom-alignment d-flex gap-3">

              <>
                <div className="d-flex align-items-center gap-1">
                  <label style={{ margin: "0", fontSize: "14px", fontWeight: "600", color: "rgb(0, 42, 90)", width: "100%" }}>Reservation From :</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
                <div className="d-flex align-items-center gap-1">
                  <label style={{ margin: "0", fontSize: "14px", fontWeight: "600", color: "rgb(0, 42, 90)", width: "100%" }}>Reservation To :</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                </div>
              </>

            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="complete/pending/cancle"
                />
              </div>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllOrder}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      {getOrderDetails === true && (
        <>
          <Dialog fullScreen open={getOrderDetails}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => closeOrderDetailModal()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <List>
              <>
                <OrderDetails storeOrderData={storeOrderData}
                  completeresrvation={completeresrvation}
                  canceledresrvation={canceledresrvation}
                  getAllOrder={getAllOrder}
                  // index={index}
                  // page={page}
                  // countPerPage={countPerPage}
                  indexNumber={indexNumber}

                />
              </>
            </List>
          </Dialog>
        </>
      )}
    </>
  );
}
