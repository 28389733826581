import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { ApiGet, ApiPost, ApiPut, ApiDelete, } from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import CoupontActiveImg from "../../_assets/plugins/keenthemes-icons/svg/Active_Coupons_Icon.png";
import AddCouponIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import CTGYIcon from "../../_assets/plugins/keenthemes-icons/svg/couponctgy.png";

export default function CouponCategories() {
  const [packageTypeData, setPackageTypeData] = useState({});
  const [addPackageType, setAddPackageType] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllPackaheType, setGetAllPackaheType] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [packageIdForEdit, setPackageIdForEdit] = useState();
  const [packageIdForDelete, setPackageIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAllPackageTypeData();
  }, [page, countPerPage]);

  const addPackageTypeModelClose = (e) => {
    setAddPackageType(false);
    setIsEditApi(false);
    setErrors(false);
    setPackageTypeData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setPackageTypeData({ ...packageTypeData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!packageTypeData?.type || packageTypeData?.type === "" || !packageTypeData?.type?.trim()) {
      formvalied = false;
      errors["type"] = "*Please enter type";
    }
    // if (!packageTypeData?.description || packageTypeData?.description === "" || !packageTypeData?.description?.trim()) {
    //   formvalied = false;
    //   errors["description"] = "*Please enter Message";
    // }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  const getAllPackageTypeData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(`packageType/find?page=${page}&limit=${countPerPage}`)
        .then((res) => {
          setGetAllPackaheType(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `packageType/find?letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllPackaheType(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const addPackageTypeData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let body = {
        type: packageTypeData?.type,
        // description: packageTypeData?.description,
      };

      await ApiPost(`packageType/create`, body)
        .then((res) => {
          toast.success("coupon Type add successfully");
          setAddPackageType(false);
          setIsEditApi(false);
          getAllPackageTypeData();
          setPackageTypeData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editPackageTypeData = async (e) => {
    if (formValidation()) {
      setLoader(true);
      let body = {
        packageTypeId: packageIdForEdit,
        type: packageTypeData?.type,
        // description: packageTypeData?.description,
      };

      await ApiPut(`packageType/update`, body)
        .then((res) => {
          toast.success("Coupon Type update successfully");
          getAllPackageTypeData();
          setAddPackageType(false);
          setIsEditApi(false);
          setPackageTypeData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const deletePackageTypeData = async () => {
    await ApiDelete(`packageType/delete?id=${packageIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Coupon type delete successfully");
          setShow(false);
          getAllPackageTypeData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllPackageTypeData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllPackageTypeData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
    },
    // {
    //   name: "Description",
    //   cell: (row) => {
    //     return (
    //       <div className="showmore-class  p-2 ">
    //         {row?.description?.length > 200
    //           ? row?.description?.substring(0, 200) + "..."
    //           : row?.description}
    //       </div>
    //     );
    //   },
    //   sortable: true,
    // },
    {
      name: "Edit",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setAddPackageType(true);
                  setPackageIdForEdit(row?._id);
                  setPackageTypeData({
                    type: row?.type?.toString(),
                    // description: row?.description?.toString(),
                  });
                }}
              >
                <Tooltip title="edit Coupon type" arrow>
                  <button
                    style={{
                      background: "#002a5a",
                      color: "white",
                      borderRadius: "20px",
                      border: "2px solid #939393",
                      padding: "5px 10px",
                      whiteSpace: "nowrap"
                    }}>
                    Manage
                  </button>
                </Tooltip>
              </div>

              {/* <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setPackageIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete Coupon type" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div> */}
            </div>
          </>
        );
      },
      width: "10%",
    },

    {
      name: "Delete",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              {/* <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setAddPackageType(true);
                  setPackageIdForEdit(row?._id);
                  setPackageTypeData({
                    type: row?.type?.toString(),
                    // description: row?.description?.toString(),
                  });
                }}
              >
                <Tooltip title="edit Coupon type" arrow>
                  <CreateIcon />
                </Tooltip>
              </div> */}

              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setPackageIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete Coupon type" arrow>
                  <button
                    style={{
                      background: "#ff0000",
                      color: "white",
                      borderRadius: "20px",
                      border: "2px solid #939393",
                      padding: "5px 10px"
                    }}>
                    Delete
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={CoupontActiveImg} alt="CoupontActiveImg" className="exchangeImg" />
            {/* <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">All Categorys</h6>
            </div> */}
          </div>
        </div>
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Packages</h2> */}
            </div>
            <div className="cus-medium-button-style button-height  button-details-alignment">
              <button
                className="btn center"
                onClick={() => {
                  setAddPackageType(true);
                }}
              >
                <img
                  src={AddCouponIcon}
                  alt={AddCouponIcon}
                />
                <img src={CTGYIcon} alt="CTGYIcon" />
                Add CTGY
              </button>
            </div>

            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllPackaheType}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Coupon type?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deletePackageTypeData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addPackageType === true ? (
        <Dialog fullScreen open={addPackageType}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addPackageTypeModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="m-auto w-1000px pt-6">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">Type</label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="type"
                      name="type"
                      value={packageTypeData?.type}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["type"]}
                  </span>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">Description</label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      className={`form-control rounded-lg custom-style`}
                      id="description"
                      name="description"
                      value={packageTypeData?.description}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div> */}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center  button-details-alignment">
                    <button className="btn" type="button">
                      <img
                        src={AddCouponIcon}
                        alt={AddCouponIcon}
                      />
                      <img src={CTGYIcon} alt="CTGYIcon" className="prizeCategory-child-img" />

                      {isEditApi ? "Edit CTGY" : "Add CTGY"}

                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className=" button-details-alignment">
                  <button
                    className="btncenter"
                    onClick={(e) => {
                      isEditApi ? editPackageTypeData(e) : addPackageTypeData(e);
                    }}
                  >
                    <img
                      src={AddCouponIcon}
                      alt={AddCouponIcon}
                    />
                    <img src={CTGYIcon} alt="CTGYIcon" className="prizeCategory-child-img" />
                    {isEditApi ? "Edit CTGY" : "Add CTGY"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
