import React from "react";
import { useState, useEffect } from "react";
import AnnouncementsImg from "../../_assets/plugins/keenthemes-icons/svg/Announcement_Icon.png";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import moment from "moment";
export default function Announcements() {
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [getData, setGetData] = useState([]);
  const [count, setCount] = useState();
  useEffect(() => {
    handlegetAnnousment();
  }, []);

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!inputData?.title?.trim() || inputData?.title === "") {
      formvalied = false;
      errors["title"] = "*Please enter title.";
    }
    if (!inputData?.description?.trim() || inputData?.description === "") {
      formvalied = false;
      errors["description"] = "*Please enter description.";
    }

    setErrors(errors);
    return formvalied;
  };

  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleAddAnnounc = async () => {
    setInputData({});
    if (formValidation()) {
      const data = {
        title: inputData?.title,
        description: inputData?.description,
      };
      await ApiPost(`anouncement/create`, data)
        .then((res) => {
          toast.success("Announcements Add successfully");
          setInputData({
            title: "",
            description: "",
          });
        })
        .catch(() => {});
    }
  };

  const handlegetAnnousment = async () => {
    await ApiGet(`anouncement/get`).then((res) => {
      setGetData(res?.data?.data?.data);
      setCount(res?.data?.data?.count);
    });
  };
  return (
    <>
      <div className="heading-banner d-flex justify-content-center">
        <div className="align-items-center d-flex">
          <img src={AnnouncementsImg} alt="StoreClientImg" className="client" />
          <div className="client-details pl-2">
            <h1 className="text-white" style={{display:"flex",justifyContent:"center"}}>{count}</h1>
            <h6 className="text-white">Total Announcements</h6>
          </div>
        </div>
      </div>

      <div className="announcementBox-alignment m-4">
        <div className="m-auto pt-10">
          <div className="announcementBox-second-box-alignment m-auto">
            <div className="form-group row">
              <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                Title
              </label>
              <div className="col-lg-7 col-xl-7">
                <div>
                  <input
                    type="text"
                    className={`form-control rounded-lg custom-style`}
                    id="title"
                    name="title"
                    value={inputData?.title}
                    placeholder="Enter title"
                    onChange={(e) => {
                      onhandleChange(e);
                    }}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["title"]}
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                Description
              </label>
              <div className="col-lg-7 col-xl-7">
                <div>
                  <textarea
                    className={`form-control rounded-lg custom-style`}
                    id="description"
                    name="description"
                    placeholder="Enter description"
                    value={inputData?.description}
                    onChange={(e) => {
                      onhandleChange(e);
                    }}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["description"]}
                </span>
              </div>
            </div>
          </div>
          <div className=" button-details-alignment d-flex justify-content-center">
            <button
              className="btn position-relative mt-10 w-150px d-flex justify-content-center align-items-center"
              type="button"
              onClick={() => handleAddAnnounc()}
            >
              send
            </button>
          </div>
        </div>
      </div>

      <div className="announcement-history-details-alignment mt-6">
        <h6 className="text-center">Announcements History</h6>

        <div className="announcement-box-alignment mt-8">
          {getData?.map((item, i) => {
            return (
              <>
                <div className="announcement-text-alignment">
                  <div>
                    <p>{item?.description}</p>
                  </div>
                  <div className="announce-date-alignment">
                    <span>{moment(item?.createdAt).format("YY MMM DD")}</span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      {/* <div className="mt-12">
        <div className="announcement-confirm-box-alignment w-1000px m-auto">
          <p className="text-center ">
            Are your Sure jruv vjfivjvr fjvosjf rcjdvjojfd jfdjvjf okexkx
            kifj0def0re0 refcjc kcc90e cjijr8 cjij
          </p>

          <div className="confirm-cancle-button-alignment mt-8">
            <div className="cancle-button-alignment">
              <button>Cancle</button>
            </div>
            <div className="submit-button-alignment">
              <button>Submit</button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
