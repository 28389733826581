import React, { useEffect, useState } from "react";
import { ApiGet } from "../../../../helpers/API/ApiData";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";
import { getUserInfo } from "../../../../utils/user.util";
import Dashboard1 from "../../../_assets/plugins/keenthemes-icons/svg/dashboard1.png";
import DashboardIcon1 from "../../../_assets/plugins/keenthemes-icons/svg/dashboard1.png";
import Dashboard2 from "../../../_assets/plugins/keenthemes-icons/svg/dashboard2.png";
import Dashboard3 from "../../../_assets/plugins/keenthemes-icons/svg/Package_Icon.png";
import Dashboard4 from "../../../_assets/plugins/keenthemes-icons/svg/Transaction_Icon.png";
import StoreIcon from "../../../_assets/plugins/keenthemes-icons/svg/storeIcon.png";
import CompetitionIcon from "../../../_assets/plugins/keenthemes-icons/svg/Competition.png";
import UserIcon from "../../../_assets/plugins/keenthemes-icons/svg/User.png";
import CouponIcon from "../../../_assets/plugins/keenthemes-icons/svg/Coupon.png";
import CategoryIcon from "../../../_assets/plugins/keenthemes-icons/svg/Category.png";
import TransactionIcon from "../../../_assets/plugins/keenthemes-icons/svg/Transaction.png";
import ReservationIcon from "../../../_assets/plugins/keenthemes-icons/svg/reservation-icon.png";
import PrizeIcon from "../../../_assets/plugins/keenthemes-icons/svg/Prize.png";
import ClientIcon from "../../../_assets/plugins/keenthemes-icons/svg/Clients.png";
import DiscountIcon from "../../../_assets/plugins/keenthemes-icons/svg/Discounts.png";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import DatePicker from "react-datepicker";

export function MixedWidget1({ className }) {
  const userInfo = getUserInfo();

  const [title, setTitle] = useState();
  const [totalGame, setTotalgame] = useState();
  const [totalUser, setTotaluser] = useState();
  const [totalClient, setTotalclient] = useState();
  const [totalStore, setTotalstore] = useState();
  const [totalOrder, setTotalorder] = useState();
  const [totalCoupon, setTotalcoupon] = useState();
  const [totalPrize, setTotalprize] = useState();
  const [totalPackage, setTotalpackage] = useState();
  const [totalTransaction, setTotaltransaction] = useState();
  const [totalCategory, setTotalcategory] = useState();
  const [totalCurrency, setTotalcurrency] = useState();
  const [totalCompitition, setTotalcompitition] = useState();
  const [getNumberOfToken, setGetNumberOfToken] = useState([]);
  const [totalOwnerStore, setTotalOwnerstore] = useState();
  const [totalOwnerOrder, setTotalOwnerOrder] = useState();
  const [totalOwnerClient, setTotalOwnerClient] = useState();
  const [totalOwnerPrize, setTotalOwnerPrize] = useState();
  const [count, setCount] = useState(0);
  const [getallOrder, setGetAllOrder] = useState([]);

  let item = localStorage.getItem("localStoreId");
  item = item && JSON.parse(item);
  item = item?.trim();
  var date = new Date();
  var firstDay = new Date(date?.getFullYear(), date?.getMonth(), 1);
  var lastDay = new Date(date?.getFullYear(), date?.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const history = useHistory();
  useEffect(() => {
    getNumberOfBoughtToken();
    getAllOrderData()
  }, [startDate, endDate]);

  const getNumberOfBoughtToken = async () => {
    await ApiGet(
      `transaction/find?type=inApp&startDate=${moment(startDate)
        .utc()
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}&endDate=${moment(endDate)
        .utc()
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}`
    ).then((res) => {
      setGetNumberOfToken(res?.data?.payload?.[0]?.data);
    });
  };

  const getAllOrderData = async () => {
    
      await ApiGet(`store/get-ordered-store-items?storeId=${item}`)
        .then((res) => {
          setGetAllOrder(res?.data?.payload);
          setCount(res?.data?.orderCount);
        })
        .catch((err) => {
        });
    
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
        // text: 'Custom Chart Title'
      },
    },
    stacked: false,
    // plugins: {
    //   title: {
    //     display: true,
    //     text: "Chart.js Line Chart - Multi Axis",
    //   },
    // },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const options1 = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
        // text: 'Custom Chart Title'
      },
    },
    stacked: false,
    // plugins: {
    //   title: {
    //     display: true,
    //     text: "Chart.js Line Chart - Multi Axis",
    //   },
    // },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
    },
  };

  const data = {
    labels: getNumberOfToken?.map((data) =>
      moment(getNumberOfToken?.createdAt).format("Do MMM YYYY")
    ),
    datasets: [
      {
        label: "coins",
        data: getNumberOfToken?.map((res) =>
          faker?.datatype.number({ min: res?.coins, max: res?.coins })
        ),
        borderColor: "#9c9489",
        backgroundColor: "#9c9489",
        yAxisID: "y",
      },
      {
        label: "amount",
        data: getNumberOfToken?.map((res) =>
          faker?.datatype.number({ min: res?.amount, max: res?.amount })
        ),
        borderColor: "#e08910",
        backgroundColor: "#e08910",
        yAxisID: "y1",
      },
    ],
  };

  const data1 = {
    labels: getallOrder?.map((data) =>
      moment(data?.createdAt).format("Do MMM YYYY")
    ),
    datasets: [
      {
        label: "amount",
        data: getallOrder?.map((data) =>
          faker?.datatype.number({ min: data?.totalAmount, max: data?.totalAmount })
        ),
        borderColor: "#9c9489",
        backgroundColor: "#9c9489",
        yAxisID: "y",
      },
      // {
      //   label: "amount",
      //   data: getNumberOfToken?.map((res) =>
      //     faker?.datatype.number({ min: res?.amount, max: res?.amount })
      //   ),
      //   borderColor: "#e08910",
      //   backgroundColor: "#e08910",
      //   yAxisID: "y1",
      // },
    ],
  };

  useEffect(() => {
    document.title = "Gstreak | Dashboard";
    setTitle("Gstreak | Dashboard");
  }, []);

  useEffect(() => {
    getAllGame();
    getAllClient();
    getAllUser();
    getAllStore();
    getAllOrder();
    getAllCoupon();
    getAllPrize();
    getAllPackage();
    getAllTransaction();
    getAllCategory();
    getAllCurrency();
    getAllCompitition();
    if (userInfo?.role === "owner") {
      getAllStoreForOwner();
      getAllOrderForOwner();
      getAllOrderForClient();
      getAllOrderForPrize();
    }
  }, []);

  const getAllStoreForOwner = async () => {
    await ApiGet(`store/find?userId=${userInfo?._id}`).then((res) => {
      setTotalOwnerstore(res?.data?.count);
    });
  };

  const getAllGame = async () => {
    await ApiGet(`game/find`).then((res) => {
      setTotalgame(res?.data?.count);
    });
  };

  const getAllUser = async () => {
    await ApiGet(`/user/find?days=7`).then((res) => {
      setTotaluser(res?.data?.count);
    });
  };

  const getAllStore = async () => {
    await ApiGet(`store/find`).then((res) => {
      setTotalstore(res?.data?.count);
    });
  };

  const getAllOrder = async () => {
    await ApiGet(`order/find?status=pending`).then((res) => {
      setTotalorder(res?.data?.totalAmount?.count);
    });
  };

  const getAllCoupon = async () => {
    await ApiGet(`coupon/get-coupon?status=active`).then((res) => {
      setTotalcoupon(res?.data?.count);
    });
  };

  const getAllPrize = async () => {
    await ApiGet(`storeItem/find`).then((res) => {
      setTotalprize(res?.data?.count);
    });
  };

  const getAllPackage = async () => {
    await ApiGet(`package/find?type=discount`).then((res) => {
      setTotalpackage(res?.data?.count);
    });
  };

  const getAllTransaction = async () => {
    await ApiGet(`transaction/find`).then((res) => {
      setTotaltransaction(res?.data?.count);
    });
  };

  const getAllCategory = async () => {
    await ApiGet(`category/find`).then((res) => {
      setTotalcategory(res?.data?.count);
    });
  };

  const getAllClient = async () => {
    await ApiGet(`order/findUser`).then((res) => {
      setTotalclient(res?.data?.count);
    });
  };

  const getAllCurrency = async () => {
    await ApiGet(`exchange/find`).then((res) => {
      setTotalcurrency(res?.data?.count);
    });
  };

  const getAllCompitition = async () => {
    await ApiGet(`competation/find`).then((res) => {
      setTotalcompitition(res?.data?.count);
    });
  };

  const getAllOrderForOwner = async () => {
    await ApiGet(`store/get-ordered-store-items?storeId=${item.trim()}`).then(
      (res) => {
        setTotalOwnerOrder(res?.data?.orderCount);
      }
    );
  };

  const getAllOrderForClient = async () => {
    await ApiGet(`order/findUser?storeId=${item.trim()}`).then((res) => {
      setTotalOwnerClient(res?.data?.data?.[0]?.count[0]?.count);
    });
  };

  const getAllOrderForPrize = async () => {
    await ApiGet(`storeItem/find?storeId=${item.trim()}`).then((res) => {
      setTotalOwnerPrize(res?.data?.count);
    });
  };

  const jumpOnGame = (e) => {
    history.push("/game");
  };
  const jumpOnUser = (e) => {
    history.push("/user");
  };
  const jumpOnStore = (e) => {
    history.push("/store");
  };
  const jompOnStoreOwner = (e) => {
    history.push("/store");
  };

  const jumpOnOrder = (e) => {
    history.push("/order");
  };
  const jumpOnOrderOwner = (e) => {
    history.push("/owner-order");
  };
  const jumpOnCoupon = (e) => {
    history.push("/coupon");
  };

  const jumpOnPrize = (e) => {
    history.push("/prizes");
  };

  const jumpOnPackage = (e) => {
    history.push("/discount-Package");
  };

  const jumpOnClient = (e) => {
    history.push("/client");
  };

  const jumpOnTransaction = (e) => {
    history.push("/transaction");
  };

  const jumpOnCategory = (e) => {
    history.push("/category");
  };

  const jumpOnCurrency = (e) => {
    history.push("/currency");
  };

  const jumpOnCompitition = (e) => {
    history.push("/compitition");
  };

  const jumpOnClientOwner = (e) => {
    history.push("/owner-client");
  };

  const jumpOnPrizeOwner = (e) => {
    history.push("/owner-prize");
  };

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {userInfo?.role === "admin" && (
        <div className="heading-banner">
          <div className="last-day-alignment pl-10">
            <h6>Last 30 Days</h6>
          </div>
          <div className="d-flex justify-content-center">
            <div className="align-items-center d-flex mt-4">
              <img src={Dashboard1} alt="Dashboard1" className="coupon" />
              <div className="client-details pl-2">
                <h1 className="text-white dashboard-text">{totalUser}</h1>
                <h6 className="text-white">Active Players</h6>
              </div>
            </div>

            <div className="align-items-center d-flex ml-40">
              <img src={Dashboard2} alt="Dashboard2" className="coupon" />
              <div className="client-details pl-2">
                <h1 className="text-white dashboard-text">
                  {totalCompitition}
                </h1>
                <h6 className="text-white">Tokens Acquired</h6>
              </div>
            </div>
            <div className="align-items-center d-flex ml-40">
              <img src={Dashboard3} alt="Dashboard3" className="coupon" />
              <div className="client-details pl-2">
                <h1 className="text-white dashboard-text">
                  {totalCompitition}
                </h1>
                <h6 className="text-white">Competition Entries</h6>
              </div>
            </div>
            <div className="align-items-center d-flex ml-40">
              <img src={Dashboard4} alt="Dashboard4" className="coupon" />
              <div className="client-details pl-2">
                <h1 className="text-white dashboard-text">{totalStore}</h1>
                <h6 className="text-white">Stores Online</h6>
              </div>
            </div>
          </div>
        </div>
      )}
      <>
        {userInfo?.role === "admin" ? (
          <>
            <div className="new-margin-bottom-alignment d-flex gap-1 my-10 ">
              <div className="col-3">
                <p
                  style={{
                    margin: "0",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "rgb(0, 42, 90)",
                  }}
                >
                  Tokens bought
                </p>
              </div>
              <>
                <div className=" d-flex gap-3">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                </div>
              </>
            </div>
            <div
              style={{
                width: "800px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Line options={options} data={data} />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                width: "800px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Line options={options1} data={data1} />
            </div>
          </>
        )}
        <div
          className={`card card-custom mt-10`}
          
        >
          <div className="card-header border-0  py-1 px-1   m-5">
            <div className="card-body p-0 position-relative overflow-hidden">

              <>
                <div className="card-spacer">
                  <div
                    className="carder-box"
                    style={{
                      display: "flex",
                      gap: "12px",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      padding: "20px",
                    }}
                  >
                    {userInfo?.role === "admin" ? (
                      <>
                        {/* user */}
                        <div className="cardBgAlignment">
                          <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalUser && (
                                        <CountUp
                                          end={totalUser}
                                          start={0}
                                          delay={1}
                                        />
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    {/* <i
                                    className="fa-solid fa-user"
                                    style={{ fontSize: "25px" }}
                                  ></i> */}
                                    <img
                                      src={UserIcon}
                                      alt="UserIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Account Holders
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jumpOnUser(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>

                          {/* store */}
                          <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalStore && (
                                        <CountUp
                                          end={totalStore}
                                          start={0}
                                          delay={1}
                                        />
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    <img
                                      src={StoreIcon}
                                      alt="StoreIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Stores Online
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jumpOnStore(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                          {/* Competition */}
                          <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalCompitition && (
                                        <CountUp
                                          end={totalCompitition}
                                          start={0}
                                          delay={1}
                                        />
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    {/* <i
                                    className="fa-solid fa-ticket"
                                    style={{ fontSize: "25px" }}
                                  ></i> */}
                                    <img
                                      src={CompetitionIcon}
                                      alt="CompetitionIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Competitions Active
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jumpOnCompitition(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>

                          {/* Reservation */}
                          <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalOrder && (
                                        <CountUp
                                          end={totalOrder}
                                          start={0}
                                          delay={1}
                                        />
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    {/* <i
                                    className="fa-solid fa-cart-shopping"
                                    style={{ fontSize: "25px" }}
                                  ></i> */}
                                    <img
                                      src={ReservationIcon}
                                      alt="ReservationIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Reservation Pending
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jumpOnOrder(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`card card-custom mt-10`}
                          
                        >
                          <div className="card-header border-0  py-1 px-1   m-5">
                            <div className="card-body p-0 position-relative overflow-hidden">
                              
                              <>
                                <div className="card-spacer">
                                  <div className="carder-box cardBgAlignment">
                                    {/* coupon */}
                                    <div className="bg-light-warning p-6 rounded box-alignment">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <h1
                                                className="text-warning font-weight-bold"
                                                style={{ fontSize: "40px" }}
                                              >
                                                {totalCoupon ? (
                                                  <CountUp
                                                    end={totalCoupon}
                                                    start={0}
                                                    delay={1}
                                                  />
                                                ) : (
                                                  "0"
                                                )}
                                              </h1>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: "6px",
                                              }}
                                            >
                                              {/* <i
                                    className="fa-solid fa-ticket"
                                    style={{ fontSize: "25px" }}
                                  ></i> */}
                                              <img
                                                src={CouponIcon}
                                                alt="CouponIcon"
                                                className="dashboard-icon"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <span
                                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Coupons Active
                                        </span>
                                      </div>
                                      <div
                                        className="showMore"
                                        onClick={(e) => jumpOnCoupon(e)}
                                      >
                                        <span style={{ cursor: "pointer" }}>
                                          {" "}
                                          Details{" "}
                                          <span
                                            className="fa-solid fa-arrow-right-long ml-2"
                                            style={{ cursor: "pointer" }}
                                          ></span>
                                        </span>
                                      </div>
                                    </div>
                                    {/* prizes */}

                                    <div className="bg-light-warning p-6 rounded box-alignment">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <h1
                                                className="text-warning font-weight-bold"
                                                style={{ fontSize: "40px" }}
                                              >
                                                {totalPrize ? (
                                                  <CountUp
                                                    end={totalPrize}
                                                    start={0}
                                                    delay={1}
                                                  />
                                                ) : (
                                                  "0"
                                                )}
                                              </h1>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: "6px",
                                              }}
                                            >
                                              {/* <i
                                    className="fa-solid fa-ticket"
                                    style={{ fontSize: "25px" }}
                                  ></i> */}
                                              <img
                                                src={PrizeIcon}
                                                alt="PrizeIcon"
                                                className="dashboard-icon"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <span
                                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Prizes Available
                                        </span>
                                      </div>
                                      <div
                                        className="showMore"
                                        onClick={(e) => jumpOnPrize(e)}
                                      >
                                        <span style={{ cursor: "pointer" }}>
                                          {" "}
                                          Details{" "}
                                          <span
                                            className="fa-solid fa-arrow-right-long ml-2"
                                            style={{ cursor: "pointer" }}
                                          ></span>
                                        </span>
                                      </div>
                                    </div>
                                    {/* discount */}
                                    <div className="bg-light-warning p-6 rounded box-alignment">
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <h1
                                                className="text-warning font-weight-bold"
                                                style={{ fontSize: "40px" }}
                                              >
                                                {totalPackage && (
                                                  <CountUp
                                                    end={totalPackage}
                                                    start={0}
                                                    delay={1}
                                                  />
                                                )}
                                              </h1>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: "6px",
                                              }}
                                            >
                                              {/* <i
                                    className="fa-solid fa-ticket"
                                    style={{ fontSize: "25px" }}
                                  ></i> */}
                                              <img
                                                src={DiscountIcon}
                                                alt="DiscountIcon"
                                                className="dashboard-icon"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <span
                                          className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Discounts Available
                                        </span>
                                      </div>
                                      <div
                                        className="showMore"
                                        onClick={(e) => jumpOnPackage(e)}
                                      >
                                        <span style={{ cursor: "pointer" }}>
                                          {" "}
                                          Details{" "}
                                          <span
                                            className="fa-solid fa-arrow-right-long ml-2"
                                            style={{ cursor: "pointer" }}
                                          ></span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="cardBgAlignment">
                           {/* owner prizes */}
                           <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalOwnerPrize && (
                                        <CountUp
                                          end={totalOwnerPrize}
                                          start={0}
                                          delay={1}
                                        />
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    <img
                                      src={PrizeIcon}
                                      alt="PrizeIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Prizes Reserved
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jumpOnPrizeOwner(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>

                          {/* owner client */}

                          <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalOwnerClient ? (
                                        <CountUp
                                          end={totalOwnerClient}
                                          start={0}
                                          delay={1}
                                        />
                                      ) : (
                                        "0"
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    <img
                                      src={ClientIcon}
                                      alt="ClientIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Total Clients
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jumpOnClientOwner(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                          {/* store */}
                          <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalOwnerStore ? (
                                        <CountUp
                                          end={totalOwnerStore}
                                          start={0}
                                          delay={1}
                                        />
                                      ) : (
                                        "0"
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    <img
                                      src={StoreIcon}
                                      alt="StoreIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Store
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jompOnStoreOwner(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                          {/* Reservation */}

                          <div className="bg-light-warning p-6 rounded box-alignment">
                            <div className="row">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h1
                                      className="text-warning font-weight-bold"
                                      style={{ fontSize: "40px" }}
                                    >
                                      {totalOwnerOrder ? (
                                        <CountUp
                                          end={totalOwnerOrder}
                                          start={0}
                                          delay={1}
                                        />
                                      ) : (
                                        "0"
                                      )}
                                    </h1>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "6px",
                                    }}
                                  >
                                    {/* <i
                                    className="fa-solid fa-cart-shopping"
                                    style={{ fontSize: "25px" }}
                                  ></i> */}
                                    <img
                                      src={ReservationIcon}
                                      alt="ReservationIcon"
                                      className="dashboard-icon"
                                    />
                                  </div>
                                </div>
                              </div>

                              <span
                                className="text-warning font-weight-bold font-size-h3 d-block my-2 ml-3"
                                style={{ cursor: "pointer" }}
                              >
                                Reservation
                              </span>
                            </div>
                            <div
                              className="showMore"
                              onClick={(e) => jumpOnOrderOwner(e)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {" "}
                                Details{" "}
                                <span
                                  className="fa-solid fa-arrow-right-long ml-2"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                          
                         
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
