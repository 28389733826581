import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import { getUserInfo } from "../../../utils/user.util";
import { useHistory, useLocation } from "react-router-dom";
import GetClientForOwner from "../GetClientForOwner/GetClientForOwner";

export default function PrizeForOwner() {
  const userInfo = getUserInfo();
  const router = useLocation();
  const [getAllClient, setGetAllClient] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [openPrize, setOpenPrize] = useState(false);
  const [clientData, setClientData] = useState({});
  useEffect(() => {
    getAllClientData();
  }, [page, countPerPage]);

  const getAllClientData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet((userInfo?.role === "owner" ? `store/find?userId=${userInfo?._id}` : `/store/find?page=${page}&limit=${countPerPage}`))
        .then((res) => {
          setGetAllClient(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet((userInfo?.role === "owner" ? `store/find?userId=${userInfo?._id}` : `store/find?letter=${search}&page=${page}&limit=${countPerPage}`))
        .then((res) => {
          setGetAllClient(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllClientData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllClientData();
    }
  }, [debouncedSearchTerm]);

  
  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
        </div>

        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <GetClientForOwner clientData={clientData} />
        )}
      </div>
      

    </>
  );
}
