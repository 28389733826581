import React from "react";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";
import StoreIcon from "../../../../../_metronic/_assets/plugins/keenthemes-icons/svg/storeIcon.png";
import DashboardIcon from "../../../../_assets/plugins/keenthemes-icons/svg/dashboardIcon.png";
import AccountHolderIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Accountholder.png";
import GameIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Games.png";
import CompetitionIcon2 from "../../../../_assets/plugins/keenthemes-icons/svg/D_Competitions.png";
import StoresIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Stores.png";
import ReservationsIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Reservations.png";
import WalletIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Wallet.png";
import ExchangeIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Exchange.png";
import DiscountIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Discount.png";
import CouponIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Coupons.png";
import TokenIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Report.png";
import SettingIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Settings.png";
import PrizeIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Prizes.png";
import TransactionIcon from "../../../../_assets/plugins/keenthemes-icons/svg/Transactions.png";
import AnnouncementsImg from "../../../../_assets/plugins/keenthemes-icons/svg/Announcement_Icon_white.png";


export function AsideMenuList({ layoutProps }) {
  const userInfo = getUserInfo();
  const location = useLocation();
  const [getStoreData, setGetStoreData] = useState(false);
  const [getAllStore, setGetAllStore] = useState([]);
  const [storeData, setStoreData] = useState({});
  const history = useHistory();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const jumpOnPackage = () => {
    history.push("/package");
  };
  const jumpOnPackage1 = () => {
    history.push("/owner-prize");
  };
  const jumpOnPackage3 = () => {
    history.push("/game");
  };

  return (
    <>
      {userInfo?.role === "admin" ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <img
                  src={DashboardIcon}
                  alt="DashboardIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          {/* user */}
          <li
            className={`menu-item ${getMenuItemActive("/user", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/user">
              <span className="svg-icon menu-icon">
                <img
                  src={AccountHolderIcon}
                  alt="AccountHolderIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Account Holders</span>
            </NavLink>
          </li>

          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/game ",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {/* <NavLink className="menu-link menu-toggle" to="/package"> */}
            <div
              className="menu-link menu-toggle"
              onClick={() => jumpOnPackage3()}
            >
              <span className="svg-icon menu-icon">
                <img
                  src={GameIcon}
                  alt="GameIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Games</span>
              <i className="menu-arrow" />
              {/* </NavLink> */}
            </div>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
              <li
            className={`menu-item ${getMenuItemActive("/game-competition", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/game-competition">
              <span className="svg-icon menu-icon">
                <img
                  src={CompetitionIcon2}
                  alt="CompetitionIcon2"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Competition</span>
            </NavLink>
          </li>
             
              </ul>
            </div>
          </li>
          {/* Announcements */}
          <li
            className={`menu-item ${getMenuItemActive("/Announcements", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/Announcements">
              <span className="svg-icon menu-icon">
                <img
                  src={AnnouncementsImg}
                  alt="AnnouncementsImg"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Announcements</span>
            </NavLink>
          </li>

          {/* store */}
          <li
            className={`menu-item ${getMenuItemActive("/store", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/store">
              <span className="svg-icon menu-icon">
                <img
                  src={StoresIcon}
                  alt="StoresIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Stores</span>
            </NavLink>
          </li>
          {/* Reservation */}
          <li
            className={`menu-item ${getMenuItemActive("/order", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/order">
              <span className="svg-icon menu-icon">
                <img
                  src={ReservationsIcon}
                  alt="ReservationsIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Reservations</span>
            </NavLink>
          </li>

          {/* prizes */}
          <li
            className={`menu-item ${getMenuItemActive("/prizes", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/prizes">
              <span className="svg-icon menu-icon">
                <img
                  src={PrizeIcon}
                  alt="PrizeIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Prizes</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/package ",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {/* <NavLink className="menu-link menu-toggle" to="/package"> */}
            <div
              className="menu-link menu-toggle"
              onClick={() => jumpOnPackage()}
            >
              <span className="svg-icon menu-icon">
                <img
                  src={WalletIcon}
                  alt="WalletIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Wallet</span>
              <i className="menu-arrow" />
              {/* </NavLink> */}
            </div>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/currency",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/currency">
                    <span className="svg-icon menu-icon">
                      <img
                        src={ExchangeIcon}
                        alt="ExchangeIcon"
                        style={{
                          width: "16px",
                          height: "16px",
                          display: "block",
                        }}
                      />
                    </span>
                    <span className="menu-text">Exchanges</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/discount-Package",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/discount-Package">
                    <span className="svg-icon menu-icon">
                      <img
                        src={CouponIcon}
                        alt="CouponIcon"
                        style={{
                          width: "16px",
                          height: "16px",
                          display: "block",
                        }}
                      />
                    </span>
                    <span className="menu-text">Discount Package</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/transaction",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/transaction">
                    <span className="svg-icon menu-icon">
                      <img
                        src={TransactionIcon}
                        alt="TransactionIcon"
                        style={{
                          width: "16px",
                          height: "16px",
                          display: "block",
                        }}
                      />
                    </span>
                    <span className="menu-text">Transaction</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/number-of-tokens-bought",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/number-of-tokens-bought">
                    <span className="svg-icon menu-icon">
                      <img
                        src={TokenIcon}
                        alt="TokenIcon"
                        style={{
                          width: "16px",
                          height: "16px",
                          display: "block",
                        }}
                      />
                    </span>
                    <span className="menu-text">Number of token bought</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          {/* Coupon */}
          <li
            className={`menu-item ${getMenuItemActive("/coupon", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/coupon">
              <span className="svg-icon menu-icon">
                <img
                  src={DiscountIcon}
                  alt="DiscountIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Coupons</span>
            </NavLink>
          </li>

          {/* Report */}
          <li
            className={`menu-item ${getMenuItemActive("/report", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/report">
              <span className="svg-icon menu-icon">
                <img
                  src={TokenIcon}
                  alt="TokenIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Reports</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/setting", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/setting">
              <span className="svg-icon menu-icon">
                <img
                  src={SettingIcon}
                  alt="SettingIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Settings</span>
            </NavLink>
          </li>
        </ul>
      ) : (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/* dashboard */}

          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <img
                  src={DashboardIcon}
                  alt="DashboardIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          {/* client */}
          <li
            className={`menu-item ${getMenuItemActive("/owner-client", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/owner-client">
              <span className="svg-icon menu-icon">
                <img
                  src={AccountHolderIcon}
                  alt="AccountHolderIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Clients</span>
            </NavLink>
          </li>


          {/* game */}
          <li
            className={`menu-item ${getMenuItemActive("/owner-game", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/owner-game">
              <span className="svg-icon menu-icon">
                <img
                  src={GameIcon}
                  alt="GameIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Store Competition</span>
            </NavLink>
          </li>

          {/* prizes */}
          <li
            className={`menu-item ${getMenuItemActive("/owner-prize", false)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {/* <NavLink className="menu-link" to="/owner-prize"> */}
            <div
              className="menu-link menu-toggle"
              onClick={() => jumpOnPackage1()}
            >
              <span className="svg-icon menu-icon">
                <img
                  src={PrizeIcon}
                  alt="PrizeIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Prizes</span>
              <i className="menu-arrow" />
            </div>
            {/* </NavLink> */}
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                {/* Coupon */}
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/owner-coupon",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/owner-coupon">
                    <span className="svg-icon menu-icon">
                      <img
                        src={DiscountIcon}
                        alt="DiscountIcon"
                        style={{
                          width: "16px",
                          height: "16px",
                          display: "block",
                        }}
                      />
                    </span>
                    <span className="menu-text">Coupons</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          {/* Reservation */}
          <li
            className={`menu-item ${getMenuItemActive("/owner-order", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/owner-order">
              <span className="svg-icon menu-icon">
                <img
                  src={ReservationsIcon}
                  alt="ReservationsIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Reservations</span>
            </NavLink>
          </li>
          {/* Report */}
          <li
            className={`menu-item ${getMenuItemActive("/report", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/report">
              <span className="svg-icon menu-icon">
                <img
                  src={TokenIcon}
                  alt="TokenIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Reports </span>
            </NavLink>
          </li>
          {/* Setting */}
          <li
            className={`menu-item ${getMenuItemActive("/edit-store", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/edit-store">
              <span className="svg-icon menu-icon">
                <img
                  src={TokenIcon}
                  alt="TokenIcon"
                  style={{ width: "16px", height: "16px", display: "block" }}
                />
              </span>
              <span className="menu-text">Setting </span>
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );
}
