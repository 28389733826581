import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import StoreClientImg from "../../_assets/plugins/keenthemes-icons/svg/Store_Clients.png";


export default function Order() {
  const [getAllOrder, setGetAllOrder] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);

  useEffect(() => {
    getAllOrderData();
  }, [page, countPerPage]);

  const getAllOrderData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : ""
    await ApiGet(`order/findUser?page=${page}&limit=${countPerPage}&days=30${url}`)
      .then((res) => {
        setGetAllOrder(res?.data?.data[0]?.data);
        setCount(res?.data?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        backgroundColor: "#bdc7c9",
        color: "#040761"
        // userSelect: "none"
      },
    },
  ];

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },

    {
      name: "User Name",
      selector: (row) => (row?.userId?.username ? row?.userId?.username : "-"),
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => (row?.userId?.email ? row?.userId?.email : "-"),
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => (row?.status ? row?.status : "-"),
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row?.userId?.address ? row?.userId?.address : "-"),
      sortable: true,
    },
    {
      name: "city",
      selector: (row) => (row?.userId?.city ? row?.userId?.city : "-"),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => (row?.userId?.state ? row?.userId?.state : "-"),
      sortable: true,
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={StoreClientImg} alt="StoreClientImg" className="client" />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">Total Clients</h6>
            </div>
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 align-items-center">
            <div className="col d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Clients</h2> */}
            </div>
            <div className="">
              {/* <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div> */}
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllOrder}
            customStyles={customStyles}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
    </>
  );
}
