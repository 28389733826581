import React, { useMemo } from "react";
import objectPath from "object-path";
import { useState, useLayoutEffect, useEffect } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { getStoreData, getUserInfo } from "../../../../utils/user.util";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "../subheader/components/BreadCrumbs";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../../helpers/API/ApiData";
import DownArrowIcon from "../../../_assets/plugins/keenthemes-icons/svg/035-bold-arrow-down.svg";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
export function Topbar() {
  const [DropdownBox, setDropdownBox] = useState(false);

  const [getAllStore, setGetAllStore] = useState([]);
  let selectedStoreData = getStoreData();
  let userInfo = getUserInfo();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();


  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const onhandleChange = (e) => {
    const { value } = e.target;
    localStorage.setItem("localStoreId", JSON.stringify(value));
    window.location.reload();
  };
  useEffect(() => {
    getAllStoreData();
  }, []);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle(
      "kt_aside_menu",
      location.pathname
    );
    const header = getBreadcrumbsAndTitle(
      "kt_header_menu",
      location.pathname
    );
    const breadcrumbs =
      aside && aside.breadcrumbs?.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title?.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);



  const getAllStoreData = async () => {
    await ApiGet(
      userInfo?.role === "owner" && `store/find?userId=${userInfo?._id}`
    ).then((res) => {
      setGetAllStore(res?.data?.payload);
    });
  };
  return (
    <div className="topbar d-flex justify-content-between w-100 align-items-center">
      <div className="d-flex flex-column">
        <>
          <div className="d-flex gap-1">
            <NavLink className="menu-link" to="/dashboard">
              <span
                className="menu-text"
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: " rgb(0, 42, 90)",
                }}
              >
                Gstreak | {userInfo?.role}
                {/* {userInfo?.admin?.role?.roleName === "superadmin" && "Admin"} */}
              </span>
            </NavLink>
            {userInfo?.role === "owner" && (
              <select
                name="store"
                onChange={(e) => onhandleChange(e)}
                value={selectedStoreData}
                style={{
                  fontSize: "16px",
                  border: "none",
                  color: "rgb(0, 42, 90)",
                  fontWeight: "500",
                }}
              >
                <option disabled>select store</option>
                {getAllStore?.map((item) => {
                  return <option value={item?._id}>{item?.name}</option>;
                })}
              </select>
            )}
          </div>
          <BreadCrumbs items={subheader.breadcrumbs} />

          {/* <div className="dropdown-box-alignment">
            <span>Select</span>
            <div onClick={() => setDropdownBox(!DropdownBox)}>
              <img src={DownArrowIcon} alt="DownArrowIcon" />
            </div>
            {DropdownBox && (
              <div className="dropdownbox-alignment">
                 {getAllStore?.map((item) => {
                return <p value={item?._id}>{item?.name}</p>;
              })}
              </div>
            )}
          </div> */}
        </>
      </div>
      {layoutProps.viewUserDisplay && <QuickUserToggler />}
      {/* <div className="signout-button">
                <button>Sign Out</button>
            </div> */}
    </div>
  );
}
