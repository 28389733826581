import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { ApiGet, ApiPost, ApiPut, } from "../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import ExchangesIcon from "../../_assets/plugins/keenthemes-icons/svg/exchanges.png";
import AddCurrencyIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import PackageType from "../PackageType/PackageType";
import Packages from "../packages/Packages";
export default function Currency() {
  const [currencyData, setCurrencyData] = useState({});
  const [addCurrency, setAddCurrency] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllCurrency, setGetAllCurrency] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currencyIdForEdit, setCurrencyIdForEdit] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAllCurrencyData();
  }, [page, countPerPage]);

  const addCurrencyModelClose = (e) => {
    setAddCurrency(false);
    setIsEditApi(false);
    setErrors(false);
    setCurrencyData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setCurrencyData({ ...currencyData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!currencyData?.amount || currencyData?.ticketAmount === "") {
      formvalied = false;
      errors["amount"] = "*Please enter amount";
    }

    if (!currencyData?.gsc_tokens || currencyData?.gsc_tokens === "") {
      formvalied = false;
      errors["gsc_tokens"] = "*Please enter gsc_tokens";
    }

    setErrors(errors);
    return formvalied;
  };
  const getAllCurrencyData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : ""
    await ApiGet(`exchange/find?page=${page}&limit=${countPerPage}${url}`)
      .then((res) => {
        setGetAllCurrency(res?.data?.payload);
        setCount(res?.data?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const addCurrencydata = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let data = {
        ticketAmount: currencyData?.ticketAmount,
        gsc_tokens: currencyData?.gsc_tokens,
        // currency: currencyData?.currency,
      };

      await ApiPost(`exchange/create`, data)
        .then((res) => {
          toast.success("currency add successfully");
          setAddCurrency(false);
          setIsEditApi(false);
          getAllCurrencyData();
          setCurrencyData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editCurrencydata = async (e) => {
    let data = {
      exchangeId: currencyIdForEdit,
      ticketAmount: currencyData?.amount,
      gsc_tokens: currencyData?.gsc_tokens,
      // currency: currencyData?.currency,
    };

    setLoader(true);

    await ApiPut(`exchange/update`, data)
      .then((res) => {
        toast.success("currency update successfully");
        getAllCurrencyData();
        setAddCurrency(false);
        setIsEditApi(false);
        setCurrencyData([]);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
        setLoader(false);
      });
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCurrencyData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCurrencyData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },

    {
      name: "Ticket amount",
      selector: (row) => row?.ticketAmount,
      sortable: true,
    },

    {
      name: "Gsc Tokens",
      selector: (row) => row?.gsc_tokens,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div>
                <Tooltip title="edit Exchanges" arrow>
                  <button style={{ background: "#002a5a", color: "white", borderRadius: "20px",  whiteSpace: "nowrap" }} onClick={(e) => {
                    setIsEditApi(true);
                    setAddCurrency(true);
                    setCurrencyIdForEdit(row?._id);
                    setCurrencyData({
                      amount: row?.ticketAmount,
                      gsc_tokens: row?.gsc_tokens,
                    });
                  }}>Manage</button>
                </Tooltip>
              </div>

              {/* <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCurrencyIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete Exchanges" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div> */}
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={ExchangesIcon} alt="ExchangesIcon" className="exchangeImg" />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">All Exchanges</h6>
            </div>
          </div>
        </div>
        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Exchanges</h2>
            </div>
            <div className="col-lg-3 col-xl-3">
              {/* <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div> */}
            </div>
            {/* <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-color mr-2"
                onClick={() => {
                  setAddCurrency(true);
                }}
              >
                Add currency
              </button>
            </div> */}
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          //Data Table

          <DataTable
            columns={columns}
            data={getAllCurrency}
            customStyles={customStyles}
            highlightOnHover
          // pagination
          // paginationServer
          // paginationTotalRows={count}
          // paginationPerPage={countPerPage}
          // paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
          // paginationDefaultPage={page}
          // onChangePage={(page) => {
          //   setPage(page);
          // }}
          // onChangeRowsPerPage={(rowPerPage) => {
          //   setCountPerPage(rowPerPage);
          // }}
          />
        )}
      </div>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this currency?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteCurrencydata()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal> */}

      {addCurrency === true ? (
        <Dialog fullScreen open={addCurrency}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addCurrencyModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="heading-banner d-flex justify-content-center">
              <div className="align-items-center d-flex">
                <img src={ExchangesIcon} alt="ExchangesIcon" className="exchangeImg" />
                <div className="client-details pl-2">
                  {/* <h1 className="text-white">29</h1> */}
                  <h6 className="text-white child-heading">Exchanges</h6>
                </div>
              </div>
            </div>
            <div className="m-auto w-1000px pt-10">
            <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Gsc tokens
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="gsc_tokens"
                      name="gsc_tokens"
                      value={currencyData?.gsc_tokens}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                      onKeyPress={bindInput}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["gsc_tokens"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Ticket Amount
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style `}
                      id="amount"
                      name="amount"
                      value={currencyData?.amount}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                      onKeyPress={bindInput}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["amount"]}
                  </span>
                </div>
              </div>

             

              {/* <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Currency
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="currency"
                      name="currency"
                      value={currencyData?.currency}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["currency"]}
                  </span>
                </div>
              </div> */}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center  button-details-alignment">
                    <button className="btn" type="button">
                      <img src={AddCurrencyIcon} alt={AddCurrencyIcon} />
                      {isEditApi ? "Edit  currency" : "Add currency"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className=" button-details-alignment">
                  <button
                    className="btn"
                    onClick={(e) => {
                      isEditApi ? editCurrencydata(e) : addCurrencydata(e);
                    }}
                  >
                    <img src={AddCurrencyIcon} alt={AddCurrencyIcon} className="" />
                    {isEditApi ? "Edit  currency" : "Add currency"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null
      }
    </>
  );
}
