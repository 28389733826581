import React from "react";
import { useState, useEffect, useCallback } from "react";
import Dropzone from "react-dropzone";
import { Dialog } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "react-phone-number-input/style.css";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import PhoneInput from "react-phone-number-input";
import TransactionIcon from "../../_assets/plugins/keenthemes-icons/svg/Transaction_Icon.png";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import moment from "moment";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import Storeitem from "../StoreItam/StoreItam";
import StoreWiseOrderData from "../StorwiseOrderData/StoreWiseOrderData";
import AddPrizeImg from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import StoreHomeImg from "../../_assets/plugins/keenthemes-icons/svg/home-icon.png";
import StorePlusIcon from "../../_assets/plugins/keenthemes-icons/svg/Plus-icon.png";
import StoreIconDetails from "../../_assets/plugins/keenthemes-icons/svg/store-icon-details.png";
import EditUser from "../../_assets/plugins/keenthemes-icons/svg/editIcon.png";
import clothsImg from "../../_assets/plugins/keenthemes-icons/svg/cloths.png";
import otherImg from "../../_assets/plugins/keenthemes-icons/svg/other.png";
import shoppingIcon from "../../_assets/plugins/keenthemes-icons/svg/shopping.png";
import trophyIcon from "../../_assets/plugins/keenthemes-icons/svg/trophy.png";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import CommonRadioButton from "./CommonRadioButton";

const apiKey =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBwyQ2fI1UgcPSJJ8HuYLhVQRFHYpB0iRE&v=3.exp&libraries=geometry,drawing,places";

const MyMapComponent = compose(
  withProps({
    googleMapURL: apiKey,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={4}
    defaultCenter={{ lat: props?.latitude, lng: props?.longtitude }}
    onClick={(e) => props?.handleClickedMap(e)}
  >
    <Marker position={{ lat: props?.latitude, lng: props?.longtitude }} />
  </GoogleMap>
));

export default function Store() {
  const userInfo = getUserInfo();
  const [uploadedImage, setUploadedImage] = useState(null);

  const [storeData, setStoreData] = useState({});
  const [addStore, setAddStore] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllStore, setGetAllStore] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [storeIdForEdit, setStoreIdForEdit] = useState();
  const [setStoreIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [latitude, Setlatitude] = useState(-34.78702602419161);
  const [longtitude, Setlongtitude] = useState(-34.78702602419161);
  const [show, setShow] = useState(false);
  const [showItamData, setShowItamData] = useState(false);
  const [userData, setUserData] = useState({});
  const [showMoreStoreData, setShowMoreStoreData] = useState({});
  const [openMoreStoreData, setOpenMoreStoreData] = useState(false);
  const [openOrderData, setOpenOrderData] = useState(false);
  const [storeOrderData, setStorOrderData] = useState({});
  const [phoneInput, setphoneInput] = useState("");
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 200,
    height: 200,
    maxHeight: 200,
    maxWidth: 200,
  });


  const [monthDay, setMonthDay] = useState([
    { day: "Monday", closed: false, open: "", close: "" },
    { day: "tuesday", closed: false, open: "", close: "" },
    { day: "wednesday", closed: false, open: "", close: "" },
    { day: "thursday", closed: false, open: "", close: "" },
    { day: "friday", closed: false, open: "", close: "" },
    { day: "saturday", closed: false, open: "", close: "" },
    { day: "sunday", closed: false, open: "", close: "" },
  ]);

  const handleClickedMap = (e) => {
    Setlatitude(e.latLng.lat());
    Setlongtitude(e.latLng.lng());
  };

  useEffect(() => {
    getAllStoreData();
    getAllUser();
  }, [page, countPerPage]);

  function isValidURL(string) {
    var res = string.match(
      /(https?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  const addStoreModelClose = (e) => {
    setAddStore(false);
    setIsEditApi(false);
    setErrors(false);
    setStoreData({});
  };

  const ShoworderModelClose = () => {
    setOpenOrderData(false);
  };

  const getAllUser = async () => {
    await ApiGet(`user/find`)
      .then((res) => {
        ApiGet(`user/find?limit=${res?.data?.count}`).then((res) => {
          setUserData(res?.data?.payload);
        });
      })

      .catch((err) => { });
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;

    setErrors({ ...errors, [name]: "" });
    if (name === "category") {
      setStoreData({ ...storeData, [name]: value });
      return
    }
    
    if (
      name === "coinIcon" ||
      name === "delivery" ||
      name === "digital" ||
      name === "brickandmortar" ||
      name === "isFeatured"
    ) {
      setStoreData({ ...storeData, [name]: e.target.checked });
      return;
    }
    setStoreData({ ...storeData, [name]: value });
  };
  const onhandlecustometabChange = (e, index) => {
    const { checked } = e.target;
    monthDay[index].closed = checked;
    setMonthDay([...monthDay]);
  };

  const onhandleMonthChange = (e, index) => {
    const { name, value } = e.target;
    monthDay[index][name] = value;

    setMonthDay([...monthDay]);
  };

  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;

    const file = e.target.files[0];
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      if (name === "storeImage") {
        if (image.naturalHeight > 201 || image.naturalWidth > 201) {
          return toast.error(`Please enter 200*200 : Your image is ${image.naturalWidth} * ${image.naturalHeight} `);
        } else {
          return setStoreData({ ...storeData, [name]: file });
          // setErrorsForPhoto({  [name]: "" });
        }
      } else {
        if ((image.naturalHeight > 401 || image.naturalHeight < 301) || (image.naturalWidth > 801 || image.naturalWidth < 701)) {
          return toast.error(`Please enter 800*400 : Your image is ${image.naturalWidth} * ${image.naturalHeight}`);
        } else {
          return setStoreData({ ...storeData, [name]: file });
          // setErrorsForPhoto({  [name]: "" });
        }
      }
    };
  };
  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!storeData?.userId || storeData?.userId === "") {
      formvalied = false;
      errors["userId"] = "*Please select user.";
    }
    if (!phoneInput) {
      formvalied = false;
      errors["phone"] = "*Please enter phone number";
    }
    if (!storeData?.name?.trim() || storeData?.name === "") {
      formvalied = false;
      errors["name"] = "*Please enter name.";
    }

    if (storeData && !storeData?.storeImage) {
      formvalied = false;
      errors["storeImage"] = "*Please select image.";
    }
    if (!storeData?.email?.trim() || storeData?.email === "") {
      formvalied = false;
      errors["email"] = "*Please enter email.";
    }

    let isValidiosStoreLink = isValidURL(storeData?.url || "");
    if (!isValidiosStoreLink) {
      formvalied = false;
      errors["url"] = "*Please enter Valid url";
    }

    if (!storeData?.details?.trim() || storeData?.details === "") {
      formvalied = false;
      errors["details"] = "*Please enter details.";
    }

    if (!storeData?.description?.trim() || storeData?.description === "") {
      formvalied = false;
      errors["description"] = "*Please enter description.";
    }

    if (storeData?.brickandmortar === true) {
      if (!storeData?.address?.trim() || storeData?.address === "") {
        formvalied = false;
        errors["address"] = "*Please enter address.";
      }
      if (!storeData?.state?.trim() || storeData?.state === "") {
        formvalied = false;
        errors["state"] = "*Please enter state.";
      }
      if (!storeData?.zipCode || storeData?.zipCode === "") {
        formvalied = false;
        errors["zipCode"] = "*Please enter zip-code.";
      }
      if (!storeData?.city?.trim() || storeData?.city === "") {
        formvalied = false;
        errors["city"] = "*Please enter city.";
      }
      if (!storeData?.country || storeData?.country === "") {
        formvalied = false;
        errors["country"] = "*Please enter country.";
      }
      if (!monthDay?.closed === false || storeData?.country === "") {
        formvalied = false;
        errors["customHours"] = "*Please enter country.";
      }
    }

    // if (
    //   // monthDay[i].closed === false &&
    //   monthDay[0].open === "" ||
    //   monthDay[0].close === ""
    // ) {
    //   formvalied = false;
    //   errors["closed"] = "*Please enter customHours.";
    // }

    for (var i = 0; i <= 6; i++) {
      if (monthDay[i].closed === false) {
        if (monthDay[i].open === "" || monthDay[i].close === "") {
          formvalied = false;
          errors[`closed${i}`] = "*Please enter customHours.";
        }
      }
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };
  const ShowItamDataModelClose = () => {
    setShowItamData(false);
    getAllStoreData();
  };

  const handleShowStoreItamData = (data) => {
    setGetAllStore(data);
  };

  const getAllStoreData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : "";
    await ApiGet(
      userInfo?.role === "owner"
        ? `store/find?userId=${userInfo?._id}${url}`
        : `store/find?page=${page}&limit=${countPerPage}${url}`
    )
      .then((res) => {
        setGetAllStore(res?.data?.payload);
        setCount(res?.data?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const addStoreData = async (e) => {
    setIsEditApi(false);
    // if (formValidation()) {
    setLoader(true);

    let formData = new FormData();

    // let fileArr = Array.from(monthDay);
    //   fileArr.forEach((file) => {
    //     formData.append("customTime",JSON.stringify(file));
    //   });

    formData.append("userId", storeData?.userId);
    formData.append("name", storeData?.name);
    formData.append("details", storeData?.details);
    formData.append("coinIcon", storeData?.coinIcon === true ? true : false);
    formData.append("delivery", storeData?.delivery === true ? true : false);
    formData.append("digital", storeData?.digital === true ? true : false);
    formData.append(
      "brickandmortar",
      storeData?.brickandmortar === true ? true : false
    );
    formData.append("category", storeData?.category);
    formData.append(
      "isFeatured",
      storeData?.isFeatured === true ? true : false
    );
    formData.append("email", storeData?.email);
    formData.append("url", storeData?.url);
    formData.append("phone", phoneInput);
    formData.append("description", storeData?.description);
    formData.append("address", storeData?.address);
    formData.append("state", storeData?.state);
    formData.append("zipCode", storeData?.zipCode);
    formData.append("city", storeData?.city);
    formData.append("country", storeData?.country);
    // formData.append("storeImage", uploadedImage);
    formData.append("storeImage", storeData?.storeImage);
    formData.append("storeImageThumbnail", storeData?.storeImageThumbnail);
    formData.append("lat", latitude);
    formData.append("long", longtitude);
    formData.append(
      "customHours",
      storeData?.customHours === "24/7" ? false : true
    );
    formData.append("customTime", JSON.stringify(monthDay));
    // formData.append("status", storeData?.status);

    await ApiPost(`store/create`, formData)
      .then((res) => {
        toast.success("Store add successfully");
        setAddStore(false);
        setMonthDay([]);
        setIsEditApi(false);
        getAllStoreData();
        setStoreData({});
        setLoader(false);
        Setlongtitude("");
        Setlatitude("");
        setphoneInput("");
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
        setLoader(false);
      });
    // }
  };


  const editStoreData = async (e) => {
    // if (formValidation()) {
    let formData = new FormData();

    // formData.append("userId", storeData?.userId);
    formData.append("name", storeData?.name);
    formData.append("details", storeData?.details);
    formData.append("coinIcon", storeData?.coinIcon === true ? true : false);
    formData.append("delivery", storeData?.delivery === true ? true : false);
    formData.append("digital", storeData?.digital === true ? true : false);
    formData.append(
      "brickandmortar",
      storeData?.brickandmortar === true ? true : false
    );
    formData.append("category", storeData?.category);

    formData.append(
      "isFeatured",
      storeData?.isFeatured === true ? true : false
    );
    formData.append("email", storeData?.email);
    formData.append("url", storeData?.url);
    formData.append("phone", phoneInput);
    formData.append("description", storeData?.description);
    formData.append("address", storeData?.address ? storeData?.address : "");
    formData.append("state", storeData?.state ? storeData?.state : "");
    formData.append("zipCode", storeData?.zipCode ? storeData?.zipCode : "");
    formData.append("city", storeData?.city ? storeData?.city : "");
    formData.append("country", storeData?.country ? storeData?.country : "");
    formData.append("storeImage", storeData?.storeImage);
    formData.append("storeImageThumbnail", storeData?.storeImageThumbnail);
    formData.append("lat", latitude);
    formData.append("long", longtitude);
    formData.append(
      "customHours",
      storeData?.customHours === "24/7" ? false : true
    );
    formData.append("customTime", JSON.stringify(monthDay));
    formData.append("status", storeData?.status);

    setLoader(true);
    await ApiPut(`store/update?id=${storeIdForEdit}`, formData)
      .then((res) => {
        toast.success("Store update successfully");
        getAllStoreData();
        setAddStore(false);
        setIsEditApi(false);
        setStoreData([]);
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
        setLoader(false);
      });
    // }
  };

  const deleteStoreData = async () => {
    await ApiDelete(`store/delete?id=${setStoreIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Delete Store successfully");
          setShow(false);
          getAllStoreData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  const updatestatus = (e, row) => {
    let body = {
      storeId: row?._id,
      isSuspend: row?.isSuspend === true ? false : true,
    };

    ApiPut(`store/updateStatus`, body)
      .then((res) => {
        toast.success("Status Update Successfully");
        getAllStoreData();
      })
      .catch((error) => {
        toast.error(error?.response?.statusText);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        backgroundColor: "lightgrey",
        color: "#040761",
        // userSelect: "none"
      },
    },
  ];

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Joined on",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      width: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div className="cursor-pointer pl-2">
                <button
                  style={{
                    background: "#002a5a",
                    color: "white",
                    borderRadius: "20px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={(e) => {
                    setIsEditApi(true);
                    setAddStore(true);
                    setStoreIdForEdit(row?._id);
                    setphoneInput(row?.phone);
                    setStoreData({
                      name: row?.name,
                      details: row?.details,
                      email: row?.email,
                      url: row?.url,
                      coinIcon: row?.coinIcon,
                      delivery: row?.delivery,
                      digital: row?.digital,
                      brickandmortar: row?.brickandmortar,
                      category:row?.category,
                      isFeatured: row?.isFeatured,
                      description: row?.description,
                      address: row?.address,
                      state: row?.state,
                      zipCode: row?.zipCode,
                      city: row?.city,
                      country: row?.country,
                      storeImage: row?.storeImage,
                      customHours: row?.customHours,
                      customTime: row?.customTime,
                      lat: row?.location?.coordinates[0],
                      long: row?.location?.coordinates[1],
                      status: row?.status,
                      storeImageThumbnail: row?.storeImageThumbnail,
                    });
                  }}
                >
                  {/* <Tooltip title="edit Store" arrow> */}
                  Manage
                  {/* </Tooltip> */}
                </button>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },

    {
      name: "Prize Case",
      cell: (row) => {
        return (
          <>
            <button
              style={{
                background: "yellow",
                color: "black",
                borderRadius: "20px",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                setShowItamData(true);
                handleShowStoreItamData(row);
              }}
            >
              Prizes
            </button>
          </>
        );
      },
      width: "10%",
    },

    {
      name: "Reservation Details",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-center m-auto">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setOpenOrderData(true);
                  setStorOrderData(row);
                }}
              >
                <Tooltip title="Reservation Details" arrow>
                  <button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    View
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
      width: "10%",
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "active" && (
              <div
                style={{
                  background: "#22cc00",
                  color: "white",
                  fontWeight: "bold",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Active
                </p>
              </div>
            )}
            {row?.status === "suspended" && (
              <div
                style={{
                  background: "red",
                  height: "50px",
                  fontWeight: "bold",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Suspend
                </p>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "More Information",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-center m-auto">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setShowMoreStoreData(row);
                  setOpenMoreStoreData(true);
                }}
              >
                <Tooltip title="Store Details" arrow>
                  <InfoOutlinedIcon
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
      width: "10%",
    },
  ];
  const columns1 = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Joined on",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      width: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Reservation Details",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-center m-auto">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setOpenOrderData(true);
                  setStorOrderData(row);
                }}
              >
                <Tooltip title="Reservation Details" arrow>
                  <button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    View
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
      width: "10%",
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "active" && (
              <div
                style={{
                  background: "#22cc00",
                  color: "white",
                  fontWeight: "bold",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  Active
                </p>
              </div>
            )}
            {row?.status === "deactivated" && (
              <div
                style={{
                  background: "red",
                  height: "50px",
                  fontWeight: "bold",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  De Actived
                </p>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "More Information",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-center m-auto">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setShowMoreStoreData(row);
                  setOpenMoreStoreData(true);
                }}
              >
                <Tooltip title="Order Details" arrow>
                  <InfoOutlinedIcon
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
      width: "10%",
    },
  ];

  useEffect(() => {
    if (storeData?.customTime?.length) setMonthDay([...storeData?.customTime]);
  }, [storeData]);

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img
              src={TransactionIcon}
              alt="TransactionIcon"
              className="exchangeImg"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">Stores Online</h6>
            </div>
          </div>
        </div>
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Stores</h2> */}
            </div>
            <div className="cus-medium-button-style button-height button-details-alignment">
              {userInfo?.role === "admin" && (
                <button
                  className="btn"
                  onClick={() => {
                    setAddStore(true);
                  }}
                >
                  <img src={AddPrizeImg} alt={AddPrizeImg} />
                  <img src={StoreHomeImg} alt="StoreHomeImg" />
                  Add Store
                </button>
              )}
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>

        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={userInfo?.role === "admin" ? columns : columns1}
            data={getAllStore}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Store?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteStoreData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addStore === true ? (
        <Dialog fullScreen open={addStore}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addStoreModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="heading-banner d-flex justify-content-center">
              <div className="align-items-center d-flex">
                {isEditApi === true ? (
                  <img src={EditUser} alt="EditUser" className="coupon mr-4" />
                ) : (
                  <img
                    src={StorePlusIcon}
                    alt="StorePlusIcon"
                    className="plusImg mr-4"
                  />
                )}

                <img
                  src={TransactionIcon}
                  alt="TransactionIcon"
                  className="exchangeImg"
                />
              </div>
            </div>
            <div className="m-auto w-1000px pt-10">
              {userInfo?.role === "admin" && isEditApi === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    User
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <select
                        name="userId"
                        id="userId"
                        defaultValue={storeData?.userId}
                        className="form-control rounded-lg custom-style"
                        aria-label="Default select example"
                        onChange={(e) => onhandleChange(e)}
                      >
                        <option selected disabled>
                          Select user
                        </option>
                        {userData &&
                          userData?.map((data) => (
                            <option value={data._id}>{data?.username}</option>
                          ))}
                      </select>

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userId"]}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="name"
                      value={storeData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Thumb nail Image
                <p style={{fontSize:"11px",color:"black",fontWeight:"bold"}}>1:1 aspect ratio</p>

                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <div className="labelMainDiv">
                      <label
                        htmlFor="uploadStoreImage"
                        className="lableForImage"
                      >
                        select file
                      </label>
                      {storeData?.storeImage ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid`}
                      name="storeImage"
                      id="uploadStoreImage"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>

                  <div>
                    {storeData?.storeImage && (
                      <img
                        // style={{
                        // height: "150px",
                        // width: "270px",
                        // margin: "15px 0",
                        // }}
                        src={
                          storeData?.storeImage
                            ?.toString()
                            ?.includes(".com") === true
                            ? storeData?.storeImage
                            : URL.createObjectURL(storeData?.storeImage)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["storeImage"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Banner Image

                <p style={{fontSize:"11px",color:"black",fontWeight:"bold"}}>2:1 aspect ratio</p>

                </label>

                <div className="col-lg-7 col-xl-7">
                  <div>
                    <div className="labelMainDiv">
                      <label
                        htmlFor="storeImageThumbnail"
                        className="lableForImage"
                      >
                        select file
                      </label>
                      {storeData?.storeImage ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid`}
                      name="storeImageThumbnail"
                      id="storeImageThumbnail"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {storeData?.storeImageThumbnail && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          storeData.storeImageThumbnail instanceof Blob || storeData.storeImageThumbnail instanceof File
                            ? URL.createObjectURL(storeData.storeImageThumbnail)
                            : typeof storeData.storeImageThumbnail === "string" && storeData.storeImageThumbnail.includes(".com")
                            ? storeData.storeImageThumbnail
                            : null
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["storeImage"]}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div>

                    <div className="form-group row align-items-center">
                      <label
                        htmlFor="coinIcon"
                        className="col-xl-8 col-lg-8 col-form-label"
                      >
                        <img
                          src={StoreIconDetails}
                          alt="StoreIconDetails"
                          className="storeImg1"
                        />
                      </label>
                      <div className="col-lg-4 col-xl-4">
                        <div>
                          <input
                            type="checkbox"
                            className={`form-control form-control-lg form-control-solid `}
                            id="coinIcon"
                            name="coinIcon"
                            value={storeData?.coinIcon}
                            defaultChecked={storeData?.coinIcon}
                            onChange={(e) => onhandleChange(e)}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["coinIcon"]}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row align-items-center">
                      <div
                        htmlFor="delivery"
                        className="col-xl-8 col-lg-8 col-form-label"
                      >
                        <label className="deleviryBoxAlignment">DELIVERY</label>
                      </div>
                      <div className="col-lg-4 col-xl-4">
                        <div>
                          <input
                            type="checkbox"
                            className={`form-control form-control-lg form-control-solid `}
                            id="delivery"
                            name="delivery"
                            value={storeData?.delivery}
                            defaultChecked={storeData?.delivery}
                            onChange={(e) => onhandleChange(e)}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["delivery"]}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row align-items-center">
                      <div
                        htmlFor="digital"
                        className="col-xl-8 col-lg-8 col-form-label"
                      >
                        <label className="deleviryBoxAlignment digital">
                          digital
                        </label>
                      </div>
                      <div className="col-lg-4 col-xl-4 align-items-center">
                        <div>
                          <input
                            type="checkbox"
                            className={`form-control form-control-lg form-control-solid `}
                            id="digital"
                            name="digital"
                            value={storeData?.digital}
                            defaultChecked={storeData?.digital}
                            onChange={(e) => onhandleChange(e)}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["digital"]}
                        </span>
                      </div>
                    </div>

                    <div className="form-group row align-items-center">
                      <div
                        htmlFor="brickandmortar"
                        className="col-xl-8 col-lg-8 col-form-label"
                      >
                        <label className="deleviryBoxAlignment mortar">
                          brickandmortar
                        </label>
                      </div>
                      <div className="col-lg-4 col-xl-4">
                        <div>
                          <input
                            type="checkbox"
                            className={`form-control form-control-lg form-control-solid `}
                            id="brickandmortar"
                            name="brickandmortar"
                            value={storeData?.brickandmortar}
                            defaultChecked={storeData?.brickandmortar}
                            onChange={(e) => onhandleChange(e)}
                          />
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["brickandmortar"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>

                  <CommonRadioButton
                      imgSrc={clothsImg}
                      imgAlt="Cloths"
                      id="T-Shirt"
                      name="category"
                      value="T-Shirt"
                      label="T-Shirt"
                      storeData={storeData}
                      errors={errors}
                      onhandleChange={onhandleChange}
                    />

          <CommonRadioButton
            imgSrc={otherImg}
            imgAlt="Other"
            id="Martini"
            name="category"
            value="Martini"
            label="Martini"
            storeData={storeData}
            errors={errors}
            onhandleChange={onhandleChange}
          />

          <CommonRadioButton
            imgSrc={shoppingIcon}
            imgAlt="Shopping Bag"
            id="ShoppingBag"
            name="category"
            value="ShoppingBag"
            label="Shopping Bag"
            storeData={storeData}
            errors={errors}
            onhandleChange={onhandleChange}
          />

          <CommonRadioButton
            imgSrc={trophyIcon}
            imgAlt="Trophy"
            id="Trophy"
            name="category"
            value="Trophy"
            label="Trophy"
            storeData={storeData}
            errors={errors}
            onhandleChange={onhandleChange}
          />
                    
                  </div>
                </div>
              </div>

              <div className="form-group row" >
                <label
                  htmlFor="coinIcon"
                  className="col-xl-5 col-lg-5 col-form-label label-alignment"
                >
                  Is-Featured
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="checkbox"
                      className={`form-control form-control-lg `}
                      id="isFeatured"
                      name="isFeatured"
                      value={storeData?.isFeatured}
                      defaultChecked={storeData?.isFeatured}
                      onChange={(e) => onhandleChange(e)}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["isFeatured"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  E-mail
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="email"
                      className={`form-control rounded-lg custom-style`}
                      id="email"
                      name="email"
                      value={storeData?.email}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["email"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Url
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="url"
                      name="url"
                      value={storeData?.url}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["url"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Phone
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <PhoneInput
                      placeholder="Enter Phone Number"
                      name="phone"
                      maxLength="18"
                      value={phoneInput}
                      onChange={(e) => {
                        setphoneInput(e);
                      }}
                    />
                  </div>

                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["phone"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Detail
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="details"
                      name="details"
                      value={storeData?.details}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["details"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Description
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="description"
                      name="description"
                      value={storeData?.description}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>
              {storeData?.brickandmortar === true && (
                <>
                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      Address
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="text"
                          className={`form-control rounded-lg custom-style`}
                          id="address"
                          name="address"
                          value={storeData?.address}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["address"]}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      State
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="text"
                          className={`form-control rounded-lg custom-style`}
                          id="state"
                          name="state"
                          value={storeData?.state}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["state"]}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      Zip Code
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="number"
                          className={`form-control rounded-lg custom-style`}
                          id="zipCode"
                          name="zipCode"
                          value={storeData?.zipCode}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["zipCode"]}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      city
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="text"
                          className={`form-control rounded-lg custom-style`}
                          id="city"
                          name="city"
                          value={storeData?.city}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["city"]}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      country
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="text"
                          className={`form-control rounded-lg custom-style`}
                          id="country"
                          name="country"
                          value={storeData?.country}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["country"]}
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Opening hour
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="radio"
                      className={`form-control rounded-lg custom-style`}
                      id="customHours24"
                      name="customHours"
                      value="24/7"
                      checked={
                        storeData?.customHours == "24/7" ||
                        storeData?.customHours === false
                      }
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <label for="htmcustomHoursl">24/7</label>
                  <div>
                    <input
                      type="radio"
                      className={`form-control rounded-lg custom-style`}
                      id="customHours24"
                      name="customHours"
                      checked={
                        storeData?.customHours == "Customed" ||
                        storeData?.customHours === true
                      }
                      // value={storeData?.customHours}
                      value="Customed"
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <label for="customHours24">Customed</label>
                </div>
              </div>

              <span
                style={{
                  color: "red",
                  top: "5px",
                  fontSize: "12px",
                }}
              >
                {errors["customHours"]}
              </span>

              {storeData?.customHours === true ||
              storeData?.customHours == "Customed" ? (
                <>
                  {monthDay?.map((item, i) => {
                    return (
                      <div className="d-flex justify-content-between">
                        <div>
                          <h3>{item?.day}</h3>
                        </div>
                        <div className="d-flex">
                          {item?.open === "" && item?.close === "" && (
                            <>
                              <input
                                type="checkbox"
                                className={`form-control rounded-lg custom-style`}
                                id={item?.day}
                                name={item?.day}
                                value={item?.day}
                                checked={item?.closed}
                                onChange={(e) => {
                                  onhandlecustometabChange(e, i);
                                }}
                              />
                              <h3>closed</h3>
                            </>
                          )}

                          {!item?.closed && (
                            <>
                              <input
                                type="time"
                                className={`form-control rounded-lg custom-style`}
                                name="open"
                                value={item?.open}
                                onChange={(e) => {
                                  onhandleMonthChange(e, i);
                                }}
                              />
                              <input
                                type="time"
                                className={`form-control rounded-lg custom-style`}
                                name="close"
                                value={item?.close}
                                onChange={(e) => {
                                  onhandleMonthChange(e, i);
                                }}
                              />
                            </>
                          )}

                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors[`closed${i}`]}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}

              <div className="form-group row">
                <div className="col-xs-12 col-lg-12">
                  <div className="my-12">
                    <MyMapComponent
                      key="map"
                      longtitude={longtitude || 0}
                      latitude={latitude || 0}
                      handleClickedMap={(e) => handleClickedMap(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  latitude
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="lat"
                      name="lat"
                      value={latitude}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["lat"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  longitude
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="long"
                      name="long"
                      value={longtitude}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["long"]}
                  </span>
                </div>
              </div>

              {isEditApi === true && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Status
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    {userInfo?.role === "admin" ? (
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                          name="status"
                          defaultValue={storeData?.status}
                        >
                          <option value="select for status" selected disabled>
                            select Status
                          </option>
                          <option value="active">Active</option>
                          <option value="suspended">Suspended</option>
                        </select>
                      </div>
                    ) : (
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                          name="status"
                          defaultValue={storeData?.status}
                        >
                          <option value="select for status" selected disabled>
                            select Status
                          </option>
                          <option value="active">Active</option>
                          <option value="deactivated">De-Active</option>
                        </select>
                      </div>
                    )}
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["status"]}
                    </span>
                  </div>
                </div>
              )}

              {/* <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Open Time
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="time"
                      className={`form-control rounded-lg custom-style`}
                      id="openTime"
                      name="openTime"
                      value={storeData?.openTime}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["openTime"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Close Time
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="time"
                      className={`form-control rounded-lg custom-style`}
                      id="closeTime"
                      name="closeTime"
                      value={storeData?.closeTime}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["closeTime"]}
                  </span>
                </div>
              </div> */}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button
                      className="btn position-relative center"
                      type="button"
                    >
                      <img src={AddPrizeImg} alt={AddPrizeImg} />
                      <img
                        src={StoreHomeImg}
                        alt="StoreHomeImg"
                        className="add-prizes-child-img"
                      />
                      {isEditApi ? "Edit  Store" : "Add Store"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className="button-details-alignment">
                  <button
                    className="btn position-relative center"
                    onClick={(e) => {
                      isEditApi ? editStoreData(e) : addStoreData(e);
                    }}
                  >
                    <img src={AddPrizeImg} alt={AddPrizeImg} />
                    <img
                      src={StoreHomeImg}
                      alt="StoreHomeImg"
                      className="add-prizes-child-img"
                    />
                    {isEditApi ? "Edit  Store" : "Add Store"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}

      {showItamData === true ? (
        <Dialog fullScreen open={showItamData}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => ShowItamDataModelClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <>
              <Storeitem getAllStore={getAllStore} />
            </>
          </List>
        </Dialog>
      ) : null}

      {openMoreStoreData === true ? (
        <>
          <div
            class="modal fade"
            id="exampleModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            style={{ backdropFilter: "brightness(0.5)" }}
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h2 class="modal-title" id="exampleModalLongTitle">
                    More Information
                  </h2>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h4>Description</h4>
                  <p>{showMoreStoreData?.description}</p>

                  <h4>Details</h4>
                  <p>{showMoreStoreData?.email}</p>

                  <h4>Phone</h4>
                  <p>{showMoreStoreData?.phone}</p>

                  <h4>Address</h4>
                  <p>{showMoreStoreData?.address}</p>

                  <h4>State</h4>
                  <p>{showMoreStoreData?.state}</p>

                  <h5>Zipcode</h5>
                  <p>{showMoreStoreData?.zipCode}</p>

                  <h4>Image</h4>
                  <img
                    style={{ width: 200, height: 200 }}
                    src={showMoreStoreData.storeImage}
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={(e) => setOpenMoreStoreData(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {openOrderData === true ? (
        <Dialog fullScreen open={openOrderData}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => ShoworderModelClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <>
              <StoreWiseOrderData storeOrderData={storeOrderData} />
            </>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
