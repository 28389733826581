import React from "react";
import SettingIcon from "../../_assets/plugins/keenthemes-icons/svg/setting.png";
import StartIcon from "../../_assets/plugins/keenthemes-icons/svg/starIcon.png";
import CouponsIcon from "../../_assets/plugins/keenthemes-icons/svg/CouponCategories.png";
import compationIcon from "../../_assets/plugins/keenthemes-icons/svg/Package_Icon.png";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import { useState } from "react";
import Category from "../category/Category";
import CompetitionCategories from "../CompetitionCategories/CompetitionCategories";
import CouponCategories from "../CouponCategories/CouponCategories";


export default function Setting() {
  const [prizeCategory, setPrizeCategory] = useState(false);
  const [competitionCategory, setCompetitionCategory] = useState(false);
  const [couponCategory, setCouponCategory] = useState(false);
  return (
    <div>
      <div className="heading-banner d-flex justify-content-center">
        <div className="align-items-center d-flex">
          <img src={SettingIcon} alt="SettingIcon" className="exchangeImg" />
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-items-center py-10 flex-wrap"
        style={{ gap: "80px" }}
      >
        <div
          onClick={() => setPrizeCategory(true)}
          className="child-box-alignment py-6 d-flex flex-column justify-content-around align-items-center"
          style={{ width: "252px", height: "225px", cursor: "pointer" }}
        >
          <div className="text-center">
            <img src={StartIcon} alt="StartIcon" />
          </div>
          <div onClick={() => setPrizeCategory(true)}>
            <span style={{ fontSize: "18px", fontWeight: "600" }}>
              Prize Categories
            </span>
          </div>
        </div>

        <div
          onClick={() => setCompetitionCategory(true)}
          className="child-box-alignment  py-6 d-flex flex-column justify-content-around align-items-center"
          style={{ width: "252px", height: "225px", cursor: "pointer" }}
        >
          <div className="text-center">
            <img src={compationIcon} alt="compationIcon" />
          </div>
          <div onClick={() => setCompetitionCategory(true)}>
            <span style={{ fontSize: "18px", fontWeight: "600" }}>
              Competition Categories
            </span>
          </div>
        </div>

        <div
          onClick={() => setCouponCategory(true)}
          className="child-box-alignment py-6 d-flex flex-column justify-content-around align-items-center"
          style={{ width: "252px", height: "225px", cursor: "pointer" }}
        >
          <div className="text-center">
            <img src={CouponsIcon} alt="CouponsIcon" />
          </div>
          <div onClick={() => setCouponCategory(true)}>
            <span style={{ fontSize: "18px", fontWeight: "600" }}>
              Coupon Categories
            </span>
          </div>
        </div>
      </div>

      {prizeCategory && (
        <Dialog fullScreen open={prizeCategory}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setPrizeCategory(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <>
              <Category />
            </>
          </List>
        </Dialog>
      )}

      {competitionCategory && (
        <Dialog fullScreen open={competitionCategory}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setCompetitionCategory(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <>
              <CompetitionCategories />
            </>
          </List>
        </Dialog>
      )}

      {couponCategory && (
        <Dialog fullScreen open={couponCategory}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setCouponCategory(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <>
             <CouponCategories/>
            </>
          </List>
        </Dialog>
      )}
    </div>
  );
}
