import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import { getUserInfo } from "../../../utils/user.util";

export default function CouponsReport(props) {
  const [getAllCoupon, setgetAllCoupon] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [downloadLink, setDownloadLink] = useState();
  const userInfo = getUserInfo();
  const storeId = props?.storeId;

  useEffect(() => {
    getAllcouponData();
  }, [page, countPerPage]);

  const getAllcouponData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        userInfo?.role === "owner"
          ? `coupon/get-coupon?storeId=${storeId}&page=${page}&limit=${countPerPage}`
          : `coupon/get-coupon?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setgetAllCoupon(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        userInfo?.role === "owner"
          ? `coupon/get-coupon?storeId=${storeId}&page=${page}&limit=${countPerPage}`
          : `coupon/get-coupon?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setgetAllCoupon(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const couponReportDownload = async () => {
    await ApiGet(
      userInfo?.role === "owner"
        ? `coupon/download-report?storeId=${storeId}&page=${page}&limit=${countPerPage}`
        : `coupon/download-report?page=${page}&limit=${countPerPage}`
    )
      .then((res) => {
        // window.open(res?.data?.data)
        setDownloadLink(res?.data?.payload);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };
  useEffect(() => {
    couponReportDownload();
  }, []);

  //   const handleSearch = (e) => {
  //     let val = e.target.value.replace(/[^\w\s]/gi, "");
  //     setSearch(val);
  //   };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.type?.type,
      sortable: true,
    },
    {
      name: "Coupon Limit",
      selector: (row) => row?.limit,
      sortable: true,
    },

    {
      name: "Coupon Value",
      selector: (row) => row?.value,
      sortable: true,
    },

    {
      name: "ExpiryDate",
      selector: (row) => moment(row?.expiryDate).format("Do MMM YYYY"),
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Coupons</h2>
            </div>
            {/* <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div> */}
            <div className="col">
              <a href={downloadLink}>
                <button className="btn btn-color mr-2">Download</button>
              </a>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllCoupon}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
    </>
  );
}
