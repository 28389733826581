
// const protocol = "https";
// const host = "690e-2405-201-200d-1c68-b00f-fcd9-85b0-d58.ngrok.io/gstreak/api/v1";


// live
const protocol = "https";
const host = "api.gstreak.co/api/v1"; 


// staging
// const protocol = "https";
// const host = "api.gstreak.rejoicehub.com/api/v1"; 



// local

const port = "";
const trailUrl = "";

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}/`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
