import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyAilEtXT6oZLeevvlikYIXjJM2Y_BFkPGM",
  authDomain: "gstreak-3b023.firebaseapp.com",
  projectId: "gstreak-3b023",
  storageBucket: "gstreak-3b023.appspot.com",
  messagingSenderId: "1021876511404",
  appId: "1:1021876511404:web:d6246de7d7801952673509",
  measurementId: "G-CF6GWN1414",
};
// Initialize Firebase
initializeApp(firebaseConfig);
const messaging = getMessaging();
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BFa-QmxN_IIgl7QWEVB5m4LFJ1urPRAHD_3nlCegX-Ca2yLfq8aJUk0Qi0fY8k4MwGMQvR4k2NFhJRtrEZXNLSU",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("firebaseToken", currentToken);
      } else {
        console.log(
          "No registration token available. Request permission     to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token.", err)
    });
};
