import React from "react";
import { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { ApiGet } from "../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { getUserInfo } from "../../../utils/user.util";
import GetOrderOfOwner from "../GetOrderOfOwner/GetOrderOfOwner";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import GetPrizeForOwner from "../GetPrizeForOwner/GetPrizeForOwner";
import { useHistory, useLocation } from "react-router-dom";

export default function PrizeForOwner() {
  const userInfo = getUserInfo();
  const router = useLocation();
  const [getAllStore, setGetAllStore] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [openPrize,setOpenPrize] = useState(false);
  const [prizeData, setPrizeData] = useState({});
  useEffect(() => {
    getAllStoreData();
  }, [page, countPerPage]);
 
  const getAllStoreData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet((userInfo?.role === "owner" ?`store/find?userId=${userInfo?._id}`:`/store/find?page=${page}&limit=${countPerPage}` ))
        .then((res) => {
          setGetAllStore(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet((userInfo?.role === "owner" ?`store/find?userId=${userInfo?._id}`:`store/find?letter=${search}&page=${page}&limit=${countPerPage}` ))
        .then((res) => {
          setGetAllStore(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };
  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: " Store Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Store Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Prizes Information",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-center m-auto">
              <div className="cursor-pointer pl-2" onClick={()=>{
                setOpenPrize(true);
                setPrizeData(row?._id);
              }}>
                <Tooltip title="Order Details" arrow>
                  <InfoOutlinedIcon
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
      width: "10%",
    },
  ];
  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
        </div>

        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <GetPrizeForOwner prizeData={prizeData} />
        )}
      </div>
      

    </>
  );
}

