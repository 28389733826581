import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { ApiGet } from "../../../helpers/API/ApiData";
import moment from "moment";
import TransactionIcon from "../../_assets/plugins/keenthemes-icons/svg/Transaction_Icon.png";
function Transaction() {
  const [users, setUsers] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getUserData();
  }, [page, countPerPage]);

  const getUserData = async () => {
    setLoadingData(true);
    let url = search ? `&letter=${search}` : ""
      await ApiGet(`transaction/find?type=inApp&page=${page}&limit=${countPerPage}&days=30${url}`)
        .then((res) => {
          setLoadingData(false);
          setUsers(res?.data?.payload[0]?.data);
          setCount(res?.data?.count);
        })
        .catch((err) => {
          setLoadingData(false);
        });
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      sortable: true,
    },
    {
      name: "Transaction Amount",
      selector: (row) => (row?.amount ? row?.amount : "-"),
      sortable: true,
    },
    {
      name: "Transaction coins",
      selector: (row) => (row?.coins ? row?.coins : "-"),
      sortable: true,
    },
    {
      name: "Transaction Type",
      selector: (row) => (row?.type ? row?.type : "-"),
      sortable: true,
    },
    {
      name: "Transaction Status",
      selector: (row) => (row?.status ? row?.status : "-"),
      sortable: true,
    },

  ];

  return (
    <>
      <ToastContainer />
      <div className="card">

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={TransactionIcon} alt="TransactionIcon" className="exchangeImg" />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">All Transactions</h6>
            </div>
          </div>
        </div>


        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3  justify-content-between">
            <div className="col d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Transactions</h2> */}
            </div>
            <div>
              
            </div>
            <div className="">
              {/* <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Search"
                />
              </div> */}
            </div>
            <div className="cus-medium-button-style button-height"></div>
          </div>

          {loadingData ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={users}
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default Transaction;
