import React from "react";
import { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import AddCompetitionIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import CompetitionIcon from "../../_assets/plugins/keenthemes-icons/svg/Package_Icon.png";
import CompetitionIcon2 from "../../_assets/plugins/keenthemes-icons/svg/D_Competitions.png";
import CompetitionIcon3 from "../../_assets/plugins/keenthemes-icons/svg/comImg.png";
export default function Competition(props) {
  const { gameIdForCompitition } = props;
  const [compititionData, setCompititionData] = useState({});
  const [createCompitition, setCreateCompitition] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllCompitition, setGetAllCompitition] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [compititionIdForEdit, setCompititionIdForEdit] = useState();
  const [compititionIdForDelete, setCompititionIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);
  const [gameData, setGameData] = useState([]);
  const [storeGameData, setStoreGameData] = useState([]);


  useEffect(() => {
    getAllCompititionData();



  }, [page, countPerPage]);
  useEffect(() => {}, [compititionData]);

  const addPackageModelClose = (e) => {
    setCreateCompitition(false);
    setIsEditApi(false);
    setErrors(false);
    setCompititionData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;

    setCompititionData({ ...compititionData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name === "GeoSeries") {
      setCompititionData({ ...compititionData, [name]: e.target.checked });
      return;
    }
    setCompititionData({ ...compititionData, [name]: value });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setCompititionData({ ...compititionData, [name]: e.target.files[0] });
    // setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!compititionData?.name?.trim() || compititionData?.name === "") {
      formvalied = false;
      errors["name"] = "*Please enter name.";
    }
    if (
      !compititionData?.description?.trim() ||
      compititionData?.description === ""
    ) {
      formvalied = false;
      errors["description"] = "*Please enter description.";
    }
    if (compititionData && !compititionData?.image) {
      formvalied = false;
      errors["image"] = "*Please select image.";
    }
    if (!compititionData?.entryFee || compititionData?.entryFee === "") {
      formvalied = false;
      errors["entryFee"] = "*Please enter entry fee.";
    }
    if (
      !compititionData?.startingDate ||
      compititionData?.startingDate === ""
    ) {
      formvalied = false;
      errors["startingDate"] = "*Please enter starting date";
    }
    if (!compititionData?.endingDate || compititionData?.endingDate === "" || compititionData?.startingDate > compititionData?.endingDate) {
      formvalied = false;
      errors["endingDate"] = "*Please enter valied ending date";
    }
    // if (!compititionData?.payOutDate || compititionData?.payOutDate === "") {
    //   formvalied = false;
    //   errors["payOutDate"] = "*Please enter payOut Date";
    // }
    if (!compititionData?.prizePool || compititionData?.prizePool === "") {
      formvalied = false;
      errors["prizePool"] = "*Please enter prize pool.";
    }
    if (!compititionData?.prizeAmount || compititionData?.prizeAmount === "" || compititionData?.prizeAmount <= "0") {
      formvalied = false;
      errors["prizeAmount"] = "*Please enter valied prize amount.";
    }

    if (!compititionData?.winnerCount || compititionData?.winnerCount === "") {
      formvalied = false;
      errors["winnerCount"] = "*Please enter winner count";
    }
    if (!compititionData?.winningType || compititionData?.winningType === "") {
      formvalied = false;
      errors["winningType"] = "*Please enter winning type.";
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(()=>{
    getAllUser();
  },[])

  // const getAllUser = async () => {
  //   await ApiGet(`game/find`)
  //     .then((res) => {
  //       ApiGet(`game/find?limit=${res?.data?.count}`).then((res) => {
  //         setGameData(res?.data?.payload);
  //         setCompititionData({"gameId" : res?.data?.payload?.find((e) => e?._id === gameIdForCompitition)?._id})
  //       });
  //     })

  //     .catch((err) => {});
  // };
  const getAllUser = async () => {
    try {
      const res = await ApiGet(`game/find`);
      const gameDataRes = await ApiGet(`game/find?limit=${res?.data?.count}`);
      setGameData(gameDataRes?.data?.payload);
      setCompititionData({
        gameId: gameDataRes?.data?.payload?.find(
          (e) => e?._id == gameIdForCompitition
        )?._id,
      });
    } catch (err) {
      console.error(err);
    }
  };
  const getAllCompititionData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        `competation/find?gameId=${gameIdForCompitition}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllCompitition(res?.data.payload[0].data);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `competation/find?gameId=${gameIdForCompitition}&letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllCompitition(res?.data.payload[0].data);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const addPackageData = async (e) => {
    setIsEditApi(false);

    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("gameId", compititionData?.gameId);
      formData.append("name", compititionData?.name);
      formData.append("description",compititionData?.description);
      formData.append("image",compititionData?.image);
      formData.append("entryFee",compititionData?.entryFee);
      formData.append("startingDate", moment(compititionData?.startingDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSSS[Z]"
      ));
      formData.append("endingDate", moment(compititionData?.endingDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSSS[Z]"
      ));
      formData.append("prizePool",compititionData?.prizePool);
      formData.append("prizeAmount",compititionData?.prizeAmount);
      formData.append("winnerCount",compititionData?.winnerCount);
      formData.append("winningType",compititionData?.winningType);
      formData.append("GeoSeries", compititionData?.GeoSeries === true ? true : false);
      formData.append("diamond", compititionData?.diamond ==="diamond" ? true : false);
      formData.append("platinum", compititionData?.diamond ==="platinum" ? true : false);
      formData.append("gold", compititionData?.diamond ==="gold" ? true : false);
      formData.append("silver", compititionData?.diamond ==="silver" ? true : false);
      formData.append("bronze", compititionData?.diamond ==="bronze" ? true : false);
      formData.append("iron", compititionData?.diamond ==="iron" ? true : false);
      formData.append("stone", compititionData?.diamond ==="stone" ? true : false);
      formData.append("wood", compititionData?.diamond ==="wood" ? true : false);
      formData.append("paper", compititionData?.diamond ==="paper" ? true : false);


      await ApiPost(`competation/create`, formData)
        .then((res) => {
          toast.success("competition add successfully");
          setCreateCompitition(false);
          setIsEditApi(false);
          getAllCompititionData();
          setCompititionData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.messgae)
          setLoader(false);
        });
    }
  };

  const editPackageData = async (e) => {
    let formData = new FormData();
    formData.append("gameId", compititionData?.gameId?._id ?compititionData?.gameId?._id  :compititionData?.gameId);
    formData.append("name", compititionData?.name);
    formData.append("description",compititionData?.description);
    formData.append("image",compititionData?.image);
    formData.append("entryFee",compititionData?.entryFee);
    formData.append("startingDate", moment(compititionData?.startingDate).format(
      "YYYY-MM-DDTHH:mm:ss.SSSS[Z]"
    ));
    formData.append("endingDate", moment(compititionData?.endingDate).format(
      "YYYY-MM-DDTHH:mm:ss.SSSS[Z]"
    ));
    formData.append("prizePool",compititionData?.prizePool);
    formData.append("prizeAmount",compititionData?.prizeAmount);
    formData.append("winnerCount",compititionData?.winnerCount);
    formData.append("winningType",compititionData?.winningType);
    formData.append("GeoSeries", compititionData?.GeoSeries === true ? true : false);
    formData.append("diamond", compititionData?.diamond ==="diamond" ? true : false);
    formData.append("platinum", compititionData?.diamond ==="platinum" ? true : false);
    formData.append("gold", compititionData?.diamond ==="gold" ? true : false);
    formData.append("silver", compititionData?.diamond ==="silver" ? true : false);
    formData.append("bronze", compititionData?.diamond ==="bronze" ? true : false);
    formData.append("iron", compititionData?.diamond ==="iron" ? true : false);
    formData.append("stone", compititionData?.diamond ==="stone" ? true : false);
    formData.append("wood", compititionData?.diamond ==="wood" ? true : false);
    formData.append("paper", compititionData?.diamond ==="paper" ? true : false);

    setLoader(true);
    await ApiPut(`competation/update?id=${compititionIdForEdit}`, formData)
      .then((res) => {
        toast.success("competition update successfully");
        getAllCompititionData();
        setCreateCompitition(false);
        setIsEditApi(false);
        setCompititionData([]);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
        setLoader(false);
      });
  };

  const deletePackageData = async () => {
    await ApiDelete(`competation/delete?id=${compititionIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("competition  delete successfully");
          setShow(false);
          getAllCompititionData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCompititionData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCompititionData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },

    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Prize Amount",
      selector: (row) => row?.prizeAmount,
      sortable: true,
    },

    {
      name: "Starting Date",
      selector: (row) => moment.utc(row?.startingDate).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Ending Date",
      selector: (row) => moment.utc(row?.endingDate).format("Do MMM YYYY"),
      width: "10%",
    },
    // {
    //   name: "PayOut Date",
    //   selector: (row) => moment(row?.payOutDate).format("Do MMM YYYY"),
    //   width: "10%",
    // },
    {
      name: "Season Duration",
      selector: (row) => row?.seasonDuration,
      sortable: true,
    },
    {
      name: "Geo-Series",
      cell: (row) => {
        return (
          <>
            {row?.diamond === true ? (
              <label className="deleviryBoxAlignment blue">DIAMOND</label>
            ) : row?.platinum === true ? (
              <label className="deleviryBoxAlignment browb">PLATINUM</label>
            ) : row?.gold === true ? (
              <label className="deleviryBoxAlignment digital">GOLD</label>
            ) : row?.silver === true ? (
              <label className="deleviryBoxAlignment black">SILVER</label>
            ) : row?.bronze === true ? (
              <label className="deleviryBoxAlignment browb">BRONZE</label>
            ) : row?.iron === true ? (
              <label className="deleviryBoxAlignment black">IRON</label>
            ) : row?.stone === true ? (
              <label className="deleviryBoxAlignment black">STONE</label>
            ) : row?.wood === true ? (
              <label className="deleviryBoxAlignment red">WOOD</label>
            ) : row?.paper === true ? (
              <label className="deleviryBoxAlignment red">PAPER</label>
            ) : (
              "-"
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setCreateCompitition(true);
                  setCompititionIdForEdit(row?._id);
                  setCompititionData({
                    gameId:row?.gameId,
                    name: row?.name,
                    description: row?.description,
                    image:row?.image,
                    entryFee: row?.entryFee,
                    prizeAmount: row?.prizeAmount,
                    startingDate: moment.utc(row?.startingDate).format(
                      "yyyy-MM-DDTHH:mm"
                    ),
                    endingDate: moment.utc(row?.endingDate).format(
                      "yyyy-MM-DDTHH:mm"
                    ),
                    // payOutDate: moment(row?.payOutDate).format(
                    //   "yyyy-MM-DDTHH:mm"
                    // ),
                    winnerCount: row?.winnerCount,
                    prizePool: row?.prizePool,
                    prizeAmount:
                      row?.prizePool === "increment" ? "0" : row?.prizeAmount,
                    winningType: row?.winningType,
                    GeoSeries: row?.GeoSeries,
                    diamond: row?.diamond,
                    platinum: row?.platinum,
                    gold: row?.gold,
                    silver: row?.silver,
                    bronze: row?.bronze,
                    iron: row?.iron,
                    stone: row?.stone,
                    wood: row?.wood,
                    paper: row?.paper,
                  });
                }}
              >
                <Tooltip title="edit competition" arrow>
                  <button
                    style={{
                      background: "#002a5a",
                      color: "white",
                      borderRadius: "20px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) => {
                      setIsEditApi(true);
                      setCreateCompitition(true);
                      setCompititionIdForEdit(row?._id);
                      setCompititionData({
                        name: row?.name,
                        description: row?.description,
                        entryFee: row?.entryFee,
                        prizeAmount: row?.prizeAmount,
                        startingDate: moment(row?.startingDate).format(
                          "yyyy-MM-DDTHH:mm"
                        ),
                        endingDate: moment(row?.endingDate).format(
                          "yyyy-MM-DDTHH:mm"
                        ),
                        // payOutDate: moment(row?.payOutDate).format(
                        //   "yyyy-MM-DDTHH:mm"
                        // ),
                        winnerCount: row?.winnerCount,
                        prizePool: row?.prizePool,
                        prizeAmount:
                          row?.prizePool === "increment"
                            ? "0"
                            : row?.prizeAmount,
                        winningType: row?.winningType,
                        GeoSeries: row?.GeoSeries,
                        diamond: row?.diamond,
                        platinum: row?.platinum,
                        gold: row?.gold,
                        silver: row?.silver,
                        bronze: row?.bronze,
                        iron: row?.iron,
                        stone: row?.stone,
                        wood: row?.wood,
                        paper: row?.paper,
                      });
                    }}
                  >
                    Manage
                  </button>
                </Tooltip>
              </div>

              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCompititionIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete competition" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  //For Tied Method
  const handlePrizePool = (winnerCount, prizepool) => {
    var amount = prizepool;
    var per = 100;
    var answerArray = [];
    var total = 0;
    for (let index = 1; index <= winnerCount; index++) {
      if (index <= winnerCount - 1) {
        amount = amount / 2;
        per = per / 2;
        answerArray.push({ amount: amount, percentage: per });
        total = total + amount;
      } else {
        answerArray.push({ amount: amount, percentage: per });
        total = total + amount;
      }
    }
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-lg-5 col-form-label label-alignment"></label>
        {compititionData?.prizeAmount <= "0" ? "" : 
        <div className="col-lg-7 col-xl-7">
          <h4>
            winnner count={winnerCount} <br />
            prize amount={prizepool}
            <div className="game-table pt-4">
              <div className="d-flex justify-content-between">
                <p>Sr.no</p>
                <p>Prize</p>
                <p>Percentage</p>
              </div>

              {answerArray.map((data, i) => (
                <div className="d-flex justify-content-between">
                  <>
                    <p>{i + 1}</p>
                    <p>{data.amount}</p>
                    <p>{data.percentage}</p>
                  </>
                </div>
              ))}

              {/*               
                <p>
                  ------
                </p>
            */}
            </div>
          </h4>
        </div>}
      </div>
    );
  };

  //For Even Method
  const handlePrizePoolEven = (winnerCount, prizepool) => {
    var amount = prizepool / winnerCount;
    var per = 100 / winnerCount;
    var answerArray = [];
    for (let index = 1; index <= winnerCount; index++) {
      if (index <= winnerCount) {
        answerArray.push({ amount: amount, percentage: per });
      }
    }

    return (
      <div className="form-group row">
        <label className="col-xl-5 col-lg-5 col-form-label label-alignment"></label>
        {compititionData?.prizeAmount <= "0" ? "" : 

        <div className="col-lg-7 col-xl-7">
          <h4>
            winnner count={winnerCount ? winnerCount : "0"} <br />
            prize amount={prizepool ? prizepool : "0"}
            <div className="game-table pt-4">
              <div className="d-flex justify-content-between">
                <p>Sr.no</p>
                <p>Prize</p>
                <p>Percentage</p>
              </div>

              {answerArray.map((data, i) => (
                <div className="d-flex justify-content-between">
                  <>
                    <p>{i + 1}</p>
                    <p>{data.amount}</p>
                    <p>{data.percentage}</p>
                  </>
                </div>
              ))}

              {/*               
                <p>
                  ------
                </p>
            */}
            </div>
          </h4>
        </div>}
      </div>
    );
  };


  return (
    <>
      <div className="card">
        <ToastContainer />

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={CompetitionIcon} alt="CompetitionIcon" />

            <div className="client-details pl-2">
              <h6 className="text-white child-heading">All Competitions</h6>
            </div>
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 align-items-center">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">competitions</h2>
            </div>
            <div className="col-lg-3 col-xl-3">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height button-details-alignment">
              <button
                className="btn"
                onClick={() => {
                  setCompititionData({gameId : gameIdForCompitition})
                  setCreateCompitition(true);
                }}
              >
                <img src={AddCompetitionIcon} alt={AddCompetitionIcon} />
                <img src={CompetitionIcon2} alt={CompetitionIcon2} />
                Add competition
              </button>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllCompitition}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this competition?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deletePackageData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {createCompitition === true ? (
        <Dialog fullScreen open={createCompitition}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addPackageModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="m-auto w-1000px pt-10">

            <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Game
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <select
                        name="gameId"
                        id="gameId"
                        // defaultValue={compititionData?.gameId?._id  ? compititionData?.gameId?._id : compititionDat  a?.gameId}
                        value={compititionData?.gameId?._id  ? compititionData?.gameId?._id : compititionData?.gameId}
                        className="form-control rounded-lg custom-style"
                        aria-label="Default select example"
                        onChange={(e) => onhandleChange(e)}
                      >
                        <option selected disabled>
                          Select Game
                        </option>
                        {gameData &&
                          gameData?.map((data) => (
                            <option value={data._id}>{data?.name}</option>
                          ))}
                      </select>

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userId"]}
                      </span>
                    </div>
                  </div>
                </div>

              
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="name"
                      value={compititionData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>
              
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Description
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      // type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="description"
                      name="description"
                      value={compititionData?.description}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>


              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Image
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <div className="labelMainDiv">
                      <label
                        htmlFor="uploadStoreImage"
                        className="lableForImage"
                      >
                        select file
                      </label>
                      {compititionData?.image ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid`}
                      name="image"
                      id="uploadStoreImage"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                   
                    {compititionData?.image?.length != 0  ? (
                      <>
                      { compititionData?.image
                            ?.toString()
                            ?.includes(".com") === true
                            ? <img src={compititionData?.image} alt="img"    style={{
                              height: "150px",
                              width: "270px",
                              margin: "15px 0",
                            }}/> 

                            : compititionData?.image ? <img src={URL.createObjectURL(compititionData?.image) } alt="img"    style={{
                              height: "150px",
                              width: "270px",
                              margin: "15px 0",
                            }}/>  :null}

                    
                      </>
                    ):null}
                  </div>
                 
                    <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["image"]}
                  </span>
                </div>
              </div>


              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Start Date
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="startingDate"
                      value={compititionData?.startingDate}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["startingDate"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  End Date
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="endingDate"
                      value={compititionData?.endingDate}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["endingDate"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Entry Fee
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="entryFee"
                      name="entryFee"
                      value={compititionData?.entryFee}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["entryFee"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  prize Pool Type
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      className={`form-control rounded-lg custom-style`}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                      defaultValue={compititionData?.prizePool}
                      name="prizePool"
                    >
                      <option value="select for prizePool" selected disabled>
                        Select prizePool
                      </option>
                      <option value="fixed">Fixed</option>
                      <option value="incrementing">Incrementing</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["prizePool"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Prize Pool Amount
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      placeholder="0"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="prizeAmount"
                      value={
                        compititionData?.prizePool === "incrementing"
                          ? "0"
                          : compititionData?.prizeAmount
                      }
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["prizeAmount"]}
                  </span>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  payOut Date
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control rounded-lg custom-style`}
                      id="payOutDate"
                      name="payOutDate"
                      value={compititionData?.payOutDate}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["payOutDate"]}
                  </span>
                </div>
              </div> */}

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Tiered / even Payment
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <select
                      className={`form-control rounded-lg custom-style`}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                      defaultValue={compititionData?.winningType}
                      name="winningType"
                    >
                      <option value="select for winningType" selected disabled>
                        Select winningType
                      </option>
                      <option value="tiered">Tiered</option>
                      <option value="even">Even</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["winningType"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  No. of winners
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="winnerCount"
                      value={compititionData?.winnerCount}
                      // value={(compititionData?.prizePool === "increment" ? "0" :compititionData?.winnerCount)}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["winnerCount"]}
                  </span>
                </div>
              </div>

              {compititionData?.winningType === "tiered" ? (
                <>
                  {compititionData?.winnerCount !== "" &&
                  compititionData?.prizeAmount !== ""
                    ? handlePrizePool(
                        +compititionData?.winnerCount,
                        +compititionData?.prizeAmount
                      )
                    : ""}
                </>
              ) : (
                <>
                  {compititionData?.winnerCount !== "" &&
                  compititionData?.prizeAmount !== ""
                    ? handlePrizePoolEven(
                        +compititionData?.winnerCount,
                        +compititionData?.prizeAmount
                      )
                    : ""}
                </>
              )}

              <div className="form-group row">
                <label
                  htmlFor="coinIcon"
                  className="col-xl-5 col-lg-5 col-form-label label-alignment"
                >
                  Enable Geo-Series
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    {isEditApi === false ? (
                      <input
                        type="checkbox"
                        className={`form-control form-control-lg `}
                        id="GeoSeries"
                        name="GeoSeries"
                        value={compititionData?.GeoSeries}
                        defaultChecked={compititionData?.GeoSeries}
                        onChange={(e) => onhandleChange(e)}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        className={`form-control form-control-lg `}
                        id="GeoSeries"
                        name="GeoSeries"
                        value={compititionData?.GeoSeries}
                        defaultChecked={compititionData?.GeoSeries}
                        // onChange={(e) => onhandleChange(e)}
                        disabled
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["GeoSeries"]}
                  </span>
                </div>
              </div>

              {compititionData?.GeoSeries === true && (
                <>
                  {isEditApi === false && (
                    <>
                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment blue">
                            DIAMOND
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="diamond"
                              name="diamond"
                              value="diamond"
                              // defaultChecked={compititionData?.diamond}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["diamond"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment browb">
                            PLATINUM
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="platinum"
                              name="diamond"
                              // value={compititionData?.platinum}
                              value="platinum"
                              // defaultChecked={compititionData?.platinum}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["platinum"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment digital">
                            GOLD
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="gold"
                              name="diamond"
                              value="gold"
                              // defaultChecked={compititionData?.gold}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["gold"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment black">
                            SILVER
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="silver"
                              name="diamond"
                              value="silver"
                              // defaultChecked={compititionData?.silver}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["silver"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment browb">
                            BRONZE
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="bronze"
                              name="diamond"
                              value="bronze"
                              // defaultChecked={compititionData?.bronze}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["bronze"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment black">
                            IRON
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="iron"
                              name="diamond"
                              value="iron"
                              // defaultChecked={compititionData?.iron}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["iron"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment black">
                            STONE
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="stone"
                              name="diamond"
                              value="stone"
                              // defaultChecked={compititionData?.stone}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["stone"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment red">
                            WOOD
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="wood"
                              name="diamond"
                              value="wood"
                              // defaultChecked={compititionData?.wood}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["wood"]}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row align-items-center">
                        <div
                          htmlFor="digital"
                          className="col-xl-5 col-lg-5 col-form-label"
                        >
                          <label className="deleviryBoxAlignment red">
                            PAPER
                          </label>
                        </div>
                        <div className="col-lg-7 col-xl-7 align-items-center">
                          <div>
                            <input
                              type="radio"
                              className={`form-control form-control-lg form-control-solid `}
                              id="paper"
                              name="diamond"
                              value="paper"
                              // defaultChecked={compititionData?.paper}
                              onChange={(e) => onhandleChange(e)}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors["paper"]}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button className="btn" type="button">
                      <img src={AddCompetitionIcon} alt={AddCompetitionIcon} />
                      <img src={CompetitionIcon2} alt={CompetitionIcon2} />
                      {isEditApi ? "Edit  competition" : "Add competition"}
                    </button>
                  </div>
                </>
              ) : (
                <div className="button-details-alignment">
                  <button
                    className="btn center"
                    onClick={(e) => {
                      isEditApi ? editPackageData(e) : addPackageData(e);
                    }}
                  >
                    <img src={AddCompetitionIcon} alt={AddCompetitionIcon} />
                    <img src={CompetitionIcon2} alt={CompetitionIcon2} />
                    {isEditApi ? "Edit  competition" : "Add competition"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
