import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import PackageIcon from "../../_assets/plugins/keenthemes-icons/svg/discountimg.png";
import AddPackageIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
export default function DiscountPackage() {
  const [couponData, setCouponData] = useState({});
  const [addCoupon, setAddCoupon] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllCoupon, setgetAllCoupon] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [categoryIdForEdit, setCategoryIdForEdit] = useState();
  const [couponIdForDelete, setCouponIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});

  useEffect(() => {
    getAllcouponData();
  }, [page, countPerPage]);

  const addCouponModelClose = (e) => {
    setAddCoupon(false);
    setIsEditApi(false);
    setErrors(false);
    setCouponData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "unlimited" || name === "isExpired") {
      setCouponData({ ...couponData, [name]: e.target.checked });
      return;
    }
    setCouponData({ ...couponData, [name]: value });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (
      !couponData?.name ||
      couponData?.name === "" ||
      !couponData?.name?.trim()
    ) {
      formvalied = false;
      errors["name"] = "*Please enter Name";
    }

    // if (
    //   !couponData?.type ||
    //   couponData?.type === "" ||
    //   !couponData?.type?.trim()
    // ) {
    //   formvalied = false;
    //   errors["type"] = "*Please enter type";
    // }

    if (
      !couponData?.expiryDate ||
      couponData?.expiryDate === "" ||
      !couponData?.expiryDate
    ) {
      formvalied = false;
      errors["expiryDate"] = "*Please enter expirydate";
    }
    if (
      !couponData?.message ||
      couponData?.message === "" ||
      !couponData?.message?.trim()
    ) {
      formvalied = false;
      errors["message"] = "*Please enter message";
    }
    if (!couponData?.amount) {
      formvalied = false;
      errors["amount"] = "*Please enter amount";
    }
    // if (!couponData?.qtyPerAcc || couponData?.qtyPerAcc === "") {
    //   formvalied = false;
    //   errors["qtyPerAcc"] = "*Please enter amount";
    // }
    if (!couponData?.gsc_tokens) {
      formvalied = false;
      errors["gsc_tokens"] = "*Please enter gsc tokens";
    }
    if (!couponData?.currency || couponData?.currency === "") {
      formvalied = false;
      errors["currency"] = "*Please enter currency";
    }
    if (!couponData?.image || couponData?.image === "") {
      formvalied = false;
      errors["image"] = "*Please select image";
    }
    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  const getAllcouponData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : "";
    await ApiGet(
      `package/find?type=discount&page=${page}&limit=${countPerPage}&days=30${url}`
    )
      .then((res) => {
        setgetAllCoupon(res?.data?.payload);
        setCount(res?.data?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const addcouponData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("name", couponData?.name);
      formData.append("message", couponData?.message);
      formData.append("amount", couponData?.amount);
      formData.append("image", couponData?.image);
      formData.append("gsc_tokens", couponData?.gsc_tokens);
      formData.append("currency", couponData?.currency);
      formData.append("type", "discount");
      formData.append("qtyPerAcc", couponData?.qtyPerAcc ? couponData?.qtyPerAcc : 0);
      formData.append("expiryDate", couponData?.expiryDate);
      formData.append(
        "unlimited",
        couponData?.unlimited === true ? true : false
      );
      formData.append(
        "isExpired",
        couponData?.isExpired === true ? true : false
      );

      await ApiPost(`package/create`, formData)
        .then((res) => {
          toast.success("Package add successfully");
          setAddCoupon(false);
          setIsEditApi(false);
          getAllcouponData();
          setCouponData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editcouponData = async (e) => {
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("packageId", categoryIdForEdit);
      formData.append("name", couponData?.name);
      formData.append("message", couponData?.message);
      formData.append("amount", couponData?.amount);
      formData.append("image", couponData?.image);
      formData.append("gsc_tokens", couponData?.gsc_tokens);
      formData.append("currency", couponData?.currency);
      // formData.append("type", couponData?.type);
      formData.append("qtyPerAcc", couponData?.qtyPerAcc);
      formData.append("expiryDate", couponData?.expiryDate);

      await ApiPut(`package/update?id=${categoryIdForEdit}`, formData)
        .then((res) => {
          toast.success("Package update successfully");
          getAllcouponData();
          setAddCoupon(false);
          setIsEditApi(false);
          setCouponData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const deletecouponData = async () => {
    await ApiDelete(`package/delete?id=${couponIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("package delete successfully");
          setShow(false);
          getAllcouponData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllcouponData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllcouponData();
    }
  }, [debouncedSearchTerm]);

  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setCouponData({ ...couponData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => {
        return (
          <div className="showmore-class  p-2 ">
            <img src={row?.image} height="60px" width="100px" />
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => {
        return (
          <>
            {row?.message?.length > 200
              ? row?.message?.substring(0, 200) + "..."
              : row?.message}
          </>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Type",
    //   selector: (row) => row?.type,
    //   sortable: true,
    // },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      sortable: true,
    },
    {
      name: "QtyPerAcc",
      selector: (row) => row?.qtyPerAcc,
      sortable: true,
    },
    {
      name: "Expiry Date",
      selector: (row) => moment(row?.expiryDate).format("DD-MM-YYYY"),
      sortable: true,
    },

    {
      name: "Gsc tokens",
      selector: (row) => row?.gsc_tokens,
      sortable: true,
    },

    {
      name: "Currency",
      selector: (row) => row?.currency,
      sortable: true,
    },

    {
      name: "Edit",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div className="cursor-pointer pl-2">
                <Tooltip title="edit package" arrow>
                  {/* <CreateIcon /> */}
                  <button
                    style={{
                      background: "#002a5a",
                      color: "white",
                      borderRadius: "20px",
                      border: "2px solid #939393",
                      padding: "5px 10px",
                      whiteSpace: "nowrap"
                    }}
                    onClick={(e) => {
                      setIsEditApi(true);
                      setAddCoupon(true);
                      setCategoryIdForEdit(row?._id);
                      setCouponData({
                        name: row?.name?.toString(),
                        type: row?.type?.toString(),
                        message: row?.message?.toString(),
                        amount: row?.amount?.toString(),
                        gsc_tokens: row?.gsc_tokens?.toString(),
                        currency: row?.currency?.toString(),
                        image: row?.image,
                        unlimited: row?.unlimited,
                        isExpired: row?.isExpired,
                        qtyPerAcc: row?.qtyPerAcc.toString(),
                        expiryDate: moment(row?.expiryDate).format(
                          "YYYY-MM-DD"
                        ),
                      });
                    }}
                  >
                    Manage
                  </button>
                </Tooltip>
              </div>

              {/* <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCouponIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete package" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div> */}
            </div>
          </>
        );
      },
      width: "10%",
    },

    {
      name: "Delete",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">

              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCouponIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete package" arrow>
                  <button
                    style={{
                      background: "#ff0000",
                      color: "white",
                      borderRadius: "20px",
                      border: "2px solid #939393",
                      padding: "5px 10px"
                    }}>
                    Delete
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={PackageIcon} alt="PackageIcon" className="discountImg" />

            {/* <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">All Packages</h6>
            </div> */}
          </div>
        </div>
        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Packages</h2> */}
            </div>
            <div className="cus-medium-button-style button-height  button-details-alignment">
              <button
                className="btn position-relative center"
                onClick={() => {
                  setAddCoupon(true);
                }}
              >
                <img
                  src={AddPackageIcon}
                  alt={AddPackageIcon}
                />
                {/* <img /> */}
                Add Package
              </button>
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>

          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllCoupon}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this package?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deletecouponData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addCoupon === true ? (
        <Dialog fullScreen open={addCoupon}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addCouponModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="heading-banner d-flex justify-content-center">
              <div className="align-items-center d-flex">
                <img
                  src={PackageIcon}
                  alt="PackageIcon"
                  className="exchangeImg"
                />
                <div className="client-details pl-2">
                  <h6 className="text-white child-heading">Packages</h6>
                </div>
              </div>
            </div>
            <div className="m-auto w-1000px pt-10">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="name"
                      name="name"
                      value={couponData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Message
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      className={`form-control rounded-lg custom-style`}
                      id="message"
                      name="message"
                      value={couponData?.message}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["message"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Amount
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="amount"
                      name="amount"
                      value={couponData?.amount}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["amount"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Image
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <div className="labelMainDiv">
                      <label
                        htmlFor="uploadStoreImage"
                        className="lableForImage"
                      >
                        select file
                      </label>
                      {couponData?.storeImage ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid`}
                      name="image"
                      id="uploadStoreImage"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {couponData?.image && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          couponData?.image?.toString()?.includes(".com") ===
                            true
                            ? couponData?.image
                            : URL.createObjectURL(couponData?.image)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["image"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="coinIcon"
                  className="col-xl-3 col-lg-3 col-form-label"
                >
                  Unlimited
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="checkbox"
                      className={`form-control form-control-lg form-control-solid `}
                      id="unlimited"
                      name="unlimited"
                      value={couponData?.unlimited}
                      defaultChecked={couponData?.unlimited}
                      onChange={(e) => onhandleChange(e)}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["unlimited"]}
                  </span>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Type
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="type"
                      name="type"
                      value={couponData?.type}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["type"]}
                  </span>
                </div>
              </div> */}
              {couponData?.unlimited === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    QtyperAcc
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="number"
                        className={`form-control rounded-lg custom-style`}
                        id="qtyPerAcc"
                        name="qtyPerAcc"
                        value={couponData?.qtyPerAcc}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["qtyPerAcc"]}
                    </span>
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Expirydate
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="date"
                      max="9999-12-31"
                      className={`form-control rounded-lg custom-style`}
                      id="expiryDate"
                      name="expiryDate"
                      value={couponData?.expiryDate}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["expiryDate"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="coinIcon"
                  className="col-xl-3 col-lg-3 col-form-label"
                >
                  IsExpired
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="checkbox"
                      className={`form-control form-control-lg form-control-solid `}
                      id="isExpired"
                      name="isExpired"
                      value={couponData?.isExpired}
                      defaultChecked={couponData?.isExpired}
                      onChange={(e) => onhandleChange(e)}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["isExpired"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Gsc tokens
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="gsc_tokens"
                      name="gsc_tokens"
                      value={couponData?.gsc_tokens}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["gsc_tokens"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Currency
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="currency"
                      name="currency"
                      value={couponData?.currency}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["currency"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center  button-details-alignment">
                    <button className="btn" type="button">
                      <img
                        src={AddPackageIcon}
                        alt={AddPackageIcon}
                      />
                      {isEditApi ? "Edit Package" : "Add Package"}
                      {/* <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span> */}
                    </button>
                  </div>
                </>
              ) : (
                <div className=" button-details-alignment">
                  <button
                    className="btn"
                    onClick={(e) => {
                      isEditApi ? editcouponData(e) : addcouponData(e);
                    }}
                  >
                    <img
                      src={AddPackageIcon}
                      alt={AddPackageIcon}
                    />
                    {isEditApi ? "Edit Package" : "Add Package"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
