import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from 'faker';

import ReportIcon from "../../_assets/plugins/keenthemes-icons/svg/Reports_Icon.png";
export default function NumberOfBoughtToken() {
  const [getNumberOfToken, setGetNumberOfToken] = useState([]);

useEffect (()=>{
    getNumberOfBoughtToken();
},[])

 const getNumberOfBoughtToken = async() =>{
    await ApiGet(`transaction/find?type=inApp`)
    .then((res)=>{
        setGetNumberOfToken(res?.data?.payload);
    })
 }


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
 const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
      },
    },
  };

   const data = {
    labels: getNumberOfToken?.map((data)=>  moment(getNumberOfToken?.createdAt).format("Do MMM YYYY")),
    datasets: [
      {
        label: 'Coins',
        data: getNumberOfToken?.map((res) => faker?.datatype.number({ min:res?.coins, max: res?.coins })),
        backgroundColor: 'rgb(0, 42, 90)',
      },
      {
        label: 'Amount',
        data: getNumberOfToken?.map((res) => faker?.datatype.number({ min: res?.amount, max: res?.amount })),
        backgroundColor: '#00b8ff',
      },
    ],
  };
 

  return (
    <>
      <div className="card">
        <ToastContainer />
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={ReportIcon} alt="ReportIcon" className="client" />
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Number of tokens bought</h2>
            </div>
           
          </div>
        <div style={{height:"800px", width:"800px", display:"flex", justifyContent:"center" }}>
          <Bar options={options} data={data} />
        </div>
            
        </div>
      </div>
    </>
  );
}
