import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { getUserInfo } from "../../../utils/user.util";

import AddCompetitionIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import CompetitionIcon from "../../_assets/plugins/keenthemes-icons/svg/Package_Icon.png";
export default function CompetitionForOwner(props) {
  const { gameIdForCompitition } = props;
  const [compititionData, setCompititionData] = useState({});
  const [createCompitition, setCreateCompitition] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllCompitition, setGetAllCompitition] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [compititionIdForEdit, setCompititionIdForEdit] = useState();
  const [compititionIdForDelete, setCompititionIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);
  const userInfo = getUserInfo();

  useEffect(() => {
    getAllCompititionData();
  }, [page, countPerPage]);

  const storeId = JSON.parse(localStorage.getItem("localStoreId"))

  useEffect(() => { }, [compititionData]);

  const addPackageModelClose = (e) => {
    setCreateCompitition(false);
    setIsEditApi(false);
    setErrors(false);
    setCompititionData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setCompititionData({ ...compititionData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!compititionData?.name?.trim() || compititionData?.name === "") {
      formvalied = false;
      errors["name"] = "*Please enter Name.";
    }
    if (
      !compititionData?.description?.trim() ||
      compititionData?.description === ""
    ) {
      formvalied = false;
      errors["description"] = "*Please enter description.";
    }
    if (!compititionData?.entryFee || compititionData?.entryFee === "") {
      formvalied = false;
      errors["entryFee"] = "*Please enter entryFee.";
    }
    if (
      !compititionData?.startingDate ||
      compititionData?.startingDate === ""
    ) {
      formvalied = false;
      errors["startingDate"] = "*Please enter starting Date";
    }
    if (!compititionData?.endingDate || compititionData?.endingDate === "") {
      formvalied = false;
      errors["endingDate"] = "*Please enter ending Date";
    }
    if (!compititionData?.payOutDate || compititionData?.payOutDate === "") {
      formvalied = false;
      errors["payOutDate"] = "*Please enter payOut Date";
    }
    if (!compititionData?.prizePool || compititionData?.prizePool === "") {
      formvalied = false;
      errors["prizePool"] = "*Please enter prizePool.";
    }
    if (!compititionData?.prizeAmount || compititionData?.prizeAmount === "") {
      formvalied = false;
      errors["prizeAmount"] = "*Please enter prizeAmount.";
    }

    if (!compititionData?.winnerCount || compititionData?.winnerCount === "") {
      formvalied = false;
      errors["winnerCount"] = "*Please enter winner Count";
    }

    if (!compititionData?.winningType || compititionData?.winningType === "") {
      formvalied = false;
      errors["winningType"] = "*Please enter winningType.";
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };
  const getAllCompititionData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        `competation/find?userId=${userInfo?._id}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllCompitition(res?.data?.payload?.[0]?.data);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `competation/find?userId=${userInfo?._id}&page=${page}&limit=${countPerPage}&letter=${search}`
      )
        .then((res) => {
          setGetAllCompitition(res?.data?.payload?.[0]?.data);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const addPackageData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let data = {
        storeId: storeId,
        gameId: gameIdForCompitition,
        name: compititionData?.name,
        description: compititionData?.description,
        entryFee: compititionData?.entryFee,
        startingDate: compititionData?.startingDate,
        endingDate: compititionData?.endingDate,
        payOutDate: compititionData?.payOutDate,
        prizePool: compititionData?.prizePool,
        prizeAmount: compititionData?.prizeAmount,
        winnerCount: compititionData?.winnerCount,
        winningType: compititionData?.winningType,
      };

      await ApiPost(`competation/create`, data)
        .then((res) => {
          toast.success("compitition add successfully");
          setCreateCompitition(false);
          setIsEditApi(false);
          getAllCompititionData();
          setCompititionData({});
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const editPackageData = async (e) => {
    let data = {
      name: compititionData?.name,
      startingDate: compititionData?.startingDate,
      endingDate: compititionData?.endingDate,
      prizeAmount: compititionData?.prizeAmount,
      winnerCount: compititionData?.winnerCount,
    };

    setLoader(true);

    await ApiPut(`competation/update?id=${compititionIdForEdit}`, data)
      .then((res) => {
        toast.success("compitition update successfully");
        getAllCompititionData();
        setCreateCompitition(false);
        setIsEditApi(false);
        setCompititionData([]);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
        setLoader(false);
      });
  };

  const deletePackageData = async () => {
    await ApiDelete(`competation/delete?id=${compititionIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("compitition  delete successfully");
          setShow(false);
          getAllCompititionData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCompititionData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCompititionData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },

    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Prize Amount",
      selector: (row) => row?.prizeAmount,
      sortable: true,
    },

    {
      name: "Starting Date",
      selector: (row) => moment(row?.startingDate).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Ending Date",
      selector: (row) => moment(row?.endingDate).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "PayOut Date",
      selector: (row) => moment(row?.payOutDate).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Season Duration",
      selector: (row) => row?.seasonDuration,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setCreateCompitition(true);
                  setCompititionIdForEdit(row?._id);
                  setCompititionData({
                    name: row?.name,
                    description: row?.description,
                    entryFee: row?.entryFee,
                    prizeAmount: row?.prizeAmount,
                    startingDate: moment(row?.startingDate).format(
                      "yyyy-MM-DDTHH:mm"
                    ),
                    endingDate: moment(row?.endingDate).format(
                      "yyyy-MM-DDTHH:mm"
                    ),
                    payOutDate: moment(row?.payOutDate).format(
                      "yyyy-MM-DDTHH:mm"
                    ),
                    winnerCount: row?.winnerCount,
                    prizePool: row?.prizePool,
                    prizeAmount:
                      row?.prizePool === "increment" ? "0" : row?.prizeAmount,
                    winningType: row?.winningType,
                  });
                }}
              >
                <Tooltip title="edit compitition" arrow>
                  <button
                    style={{
                      background: "#002a5a",
                      color: "white",
                      borderRadius: "20px",
                    }}
                    onClick={(e) => {
                      setIsEditApi(true);
                      setCreateCompitition(true);
                      setCompititionIdForEdit(row?._id);
                      setCompititionData({
                        name: row?.name,
                        description: row?.description,
                        entryFee: row?.entryFee,
                        prizeAmount: row?.prizeAmount,
                        startingDate: moment(row?.startingDate).format(
                          "yyyy-MM-DDTHH:mm"
                        ),
                        endingDate: moment(row?.endingDate).format(
                          "yyyy-MM-DDTHH:mm"
                        ),
                        payOutDate: moment(row?.payOutDate).format(
                          "yyyy-MM-DDTHH:mm"
                        ),
                        winnerCount: row?.winnerCount,
                        prizePool: row?.prizePool,
                        prizeAmount:
                          row?.prizePool === "increment"
                            ? "0"
                            : row?.prizeAmount,
                        winningType: row?.winningType,
                      });
                    }}
                  >
                    Manage
                  </button>
                </Tooltip>
              </div>

              {/* <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCompititionIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete compitition" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div> */}
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  //For Tied Method
  const handlePrizePool = (winnerCount, prizepool) => {
    var amount = prizepool;
    var per = 100;
    var answerArray = [];
    var total = 0;
    for (let index = 1; index <= winnerCount; index++) {
      if (index <= winnerCount - 1) {
        amount = amount / 2;
        per = per / 2;
        answerArray.push({ amount: amount, percentage: per });
        total = total + amount;
      } else {
        answerArray.push({ amount: amount, percentage: per });
        total = total + amount;
      }
    }
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-lg-5 col-form-label label-alignment"></label>
        <div className="col-lg-7 col-xl-7">
          <h4>
            Demo testing {winnerCount} {prizepool}
            <p>Sr.no---Prize---Percentage</p>
            {answerArray.map((data, i) => (
              <div>
                <p>{i + 1} </p>
                <p>{data.amount}</p>
                <p>{data.percentage}</p>
              </div>
            ))}
          </h4>
        </div>
      </div>
    );
  };

  //For Even Method
  const handlePrizePoolEven = (winnerCount, prizepool) => {
    var amount = prizepool / winnerCount;
    var per = 100 / winnerCount;
    var answerArray = [];
    for (let index = 1; index <= winnerCount; index++) {
      if (index <= winnerCount) {
        answerArray.push({ amount: amount, percentage: per });
      }
    }

    return (
      <div className="form-group row">
        <label className="col-xl-5 col-lg-5 col-form-label label-alignment"></label>
        <div className="col-lg-7 col-xl-7">
          <h4>
            winnner count={winnerCount} <br />
            prize amount={prizepool}
            {/* <div className="">

              <p></p>
              <p></p>
              <p></p>
            </div> */}
            <div className="game-table pt-4">
              <div className="d-flex justify-content-between">
                <p>Sr.no</p>
                <p>Prize</p>
                <p>Percentage</p>
              </div>

              {answerArray.map((data, i) => (
                <div className="d-flex justify-content-between">
                  <>
                    <p>{i + 1}</p>
                    <p>{data.amount}</p>
                    <p>{data.percentage}</p>
                  </>
                </div>
              ))}

              {/*               
                <p>
                  ------
                </p>
            */}
            </div>
          </h4>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="card">
        <ToastContainer />

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={CompetitionIcon} alt="CompetitionIcon" />

            <div className="client-details pl-2">
              <h6 className="text-white child-heading">All Compititions</h6>
            </div>
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 align-items-center">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Competition</h2>
            </div>
            <div className="col-lg-3 col-xl-3">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height button-details-alignment">
              <button
                className="btn position-relative center"
                onClick={() => {
                  setCreateCompitition(true);
                }}
              >
                <img
                  src={AddCompetitionIcon}
                  alt={AddCompetitionIcon}
                />
                  Add compitition
              </button>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllCompitition}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this compitition?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deletePackageData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {createCompitition === true ? (
        <Dialog fullScreen open={createCompitition}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addPackageModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="m-auto w-1000px pt-10">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Name
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="name"
                      value={compititionData?.name}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              {isEditApi === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Description
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <textarea
                        // type="text"
                        className={`form-control rounded-lg custom-style`}
                        id="description"
                        name="description"
                        value={compititionData?.description}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["description"]}
                    </span>
                  </div>
                </div>
              )}
              {isEditApi === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Entry Fee
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="number"
                        className={`form-control rounded-lg custom-style`}
                        id="entryFee"
                        name="entryFee"
                        value={compititionData?.entryFee}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["entryFee"]}
                    </span>
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Starting Date
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="startingDate"
                      value={compititionData?.startingDate}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["startingDate"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Ending Date
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="datetime-local"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="endingDate"
                      value={compititionData?.endingDate}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["endingDate"]}
                  </span>
                </div>
              </div>
              {isEditApi === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    payOut Date
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="datetime-local"
                        className={`form-control rounded-lg custom-style`}
                        id="payOutDate"
                        name="payOutDate"
                        value={compititionData?.payOutDate}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["payOutDate"]}
                    </span>
                  </div>
                </div>
              )}

              {isEditApi === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Tiered or even
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <select
                        className={`form-control rounded-lg custom-style`}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                        defaultValue={compititionData?.winningType}
                        name="winningType"
                      >
                        <option
                          value="select for winningType"
                          selected
                          disabled
                        >
                          select winningType
                        </option>
                        <option value="tiered">tiered</option>
                        <option value="even">even</option>
                      </select>
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["winningType"]}
                    </span>
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Prize Amount
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="prizeAmount"
                      value={
                        compititionData?.prizePool === "increment"
                          ? "0"
                          : compititionData?.prizeAmount
                      }
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["prizeAmount"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Winner Count
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="winnerCount"
                      value={compititionData?.winnerCount}
                      // value={(compititionData?.prizePool === "increment" ? "0" :compititionData?.winnerCount)}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["winnerCount"]}
                  </span>
                </div>
              </div>

              {isEditApi === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    prize Pool
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <select
                        className={`form-control rounded-lg custom-style`}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                        defaultValue={compititionData?.prizePool}
                        name="prizePool"
                      >
                        <option value="select for prizePool" selected disabled>
                          select prizePool
                        </option>
                        <option value="fixed">Fixed</option>
                        <option value="increment">increment</option>
                      </select>
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["prizePool"]}
                    </span>
                  </div>
                </div>
              )}
              {compititionData?.winningType === "tiered" ? (
                <>
                  {compititionData?.winnerCount !== "" &&
                    compititionData?.prizeAmount !== ""
                    ? handlePrizePool(
                      +compititionData?.winnerCount,
                      +compititionData?.prizeAmount
                    )
                    : ""}
                </>
              ) : (
                <>
                  {compititionData?.winnerCount !== "" &&
                    compititionData?.prizeAmount !== ""
                    ? handlePrizePoolEven(
                      +compititionData?.winnerCount,
                      +compititionData?.prizeAmount
                    )
                    : ""}
                </>
              )}
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button className="btn" type="button">
                      <img
                        src={AddCompetitionIcon}
                        alt={AddCompetitionIcon}
                      />
                      {isEditApi ? "Edit compitition" : "Add compitition"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className=" button-details-alignment">
                  <button
                    className="btn center"
                    onClick={(e) => {
                      isEditApi ? editPackageData(e) : addPackageData(e);
                    }}
                  >
                    <img
                      src={AddCompetitionIcon}
                      alt={AddCompetitionIcon}
                    />
                    {isEditApi ? "Edit compitition" : "Add compitition"}
                  </button>
                </div>

              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
