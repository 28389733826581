import React, { useState } from "react";
import {
  ApiPost,
  ApiPostNoAuth,
  ApiPut,
} from "../../../../helpers/API/ApiData";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as authUtil from "../../../../utils/auth.util";

function ForgotPassword() {
  const history = useHistory();
  // const [isRequested] = useState(false);
  const [verify, setVerify] = useState(false);
  const [forgotpass, setForgotpass] = useState(true);
  const [errors, setErrors] = useState({});
  const [inputValue, setInputValue] = useState({});
  const [changePass, setChangePass] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    let data = {
      email: inputValue?.email1,
    };
    if (validationData()) {
      await ApiPost(`user/ChangePasswordMail`, data)
        .then((res) => {
          toast.success("email send successfully");
          history.push("/login");
          // console.log("dsdfdfssffffffffffffffff",res?.data?.otpToken);
          // authUtil.setAuthForgetPassToken(res?.data?.otpToken);
          // toast.success("Email is Verify");
          // setForgotpass(false);
          // setVerify(true);
          // setLoader(false)
        })
        .catch((err) => {
          toast.error("Something went wrong please try again");
          setLoader(false);
        });
    }
  };

  const handleOtp = async (e) => {
    setLoader(true);
    let data = {
      otpCode: inputValue?.OTP,
    };
    if (validationDataOTP()) {
      await ApiPost(`user/verify`, data)
        .then((res) => {
          if (res?.status === 200) {
            toast.success("OTP is Verify");
            localStorage.removeItem("Forgetpasstoken");
            authUtil.setPasswordReetToken(res?.data?.userVerify);
            setChangePass(true);
            setForgotpass(false);
            setVerify(false);
            setLoader(false);
          } else {
            toast.error("Something went wrong please try again");
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try again");
          setLoader(false);
        });
    }
  };

  const handlesetSubmit = async (e) => {
    setLoader(true);
    let data = {
      password: inputValue?.password,
    };
    if (validationDataChangepass()) {
      await ApiPost(`user/reset-password`, data)
        .then((res) => {
          if (res?.status === 200) {
            toast.success("New password update successfully");
            localStorage.removeItem("passwordResetToken");
            history.push("/auth/login");
            setLoader(false);
          } else {
            toast.error("Sorry");
          }
        })
        .catch((err) => {
          toast.error("Something went wrong please try again");
          setLoader(false);
        });
    }
  };
  const validationData = () => {
    let formIsValid = true;
    let errors = {};
    if (!inputValue?.email1 || inputValue?.email1 === "") {
      formIsValid = false;
      errors["email1"] = "Email is required*!";
    }
    setErrors(errors);
    return formIsValid;
  };
  const validationDataOTP = () => {
    let formIsValid = true;
    let errors = {};
    if (!inputValue?.OTP || inputValue?.OTP === "") {
      formIsValid = false;
      errors["OTP"] = "OTP is required*!";
    }
    setErrors(errors);
    return formIsValid;
  };
  const validationDataChangepass = () => {
    let formIsValid = true;
    let errors = {};
    if (!inputValue?.password || inputValue?.password === "") {
      formIsValid = false;
      errors["password"] = "Password is required*!";
    }
    setErrors(errors);
    return formIsValid;
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <>
        {forgotpass == true && (
          <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className="text-center pb-8">
              <h3 className="font-size-h1">Forgot Password ?</h3>
              <div className="text-muted font-weight-bold">
                Please enter your email address. We will send you an email to
                reset your password.
              </div>
            </div>

            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                name="email1"
                placeholder="Email"
                onChange={(e) => {
                  handleOnChange(e);
                }}
              />
              <span className="text-danger">{errors.email1}</span>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="d-flex btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 "
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit{" "}
                {loader && (
                  <div className="d-flex justify-content-center ml-2">
                    <div
                      className="spinner-border text-white "
                      role="status"
                    ></div>
                    {/* <span className="sr-only">Loading...</span> */}
                  </div>
                )}
              </button>
            </div>
            <div>
              <Link to="/auth" style={{ marginLeft: "130px" }}>
                Have an account ? Sigin
              </Link>
            </div>
          </div>
        )}
        <>
          {verify && (
            <div
              className="login-form login-forgot"
              style={{ display: "block" }}
            >
              <div className="text-center pb-8">
                <h3 className="font-size-h1"> Varify OTP ?</h3>
                <div className="text-muted font-weight-bold">
                  Please enter your OTP address. We will send you an email to
                  reset your password.
                </div>
              </div>

              <div className="form-group fv-plugins-icon-container">
                <input
                  type="OTP"
                  className={`form-control form-control-solid h-auto py-5 px-6 `}
                  name="OTP"
                  placeholder="OTP"
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
                <span className="text-danger">{errors.OTP}</span>
              </div>

              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="d-flex btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  onClick={(e) => {
                    handleOtp(e);
                  }}
                >
                  OTP
                  {loader && (
                    <div
                      className="d-flex justify-content-center ml-2"
                      style={{ marginTop: "20px" }}
                    >
                      <div
                        className="spinner-border text-white "
                        role="status"
                      ></div>
                      {/* <span className="sr-only">Loading...</span> */}
                    </div>
                  )}
                </button>
              </div>
              <div>
                <Link to="/auth" style={{ marginLeft: "130px" }}>
                  Have an account ? Sigin
                </Link>
              </div>
            </div>
          )}
        </>
        <>
          {changePass && (
            <>
              <div
                className="login-form login-forgot"
                style={{ display: "block" }}
              >
                <div className="text-center pb-8">
                  <h3 className="font-size-h1">Create New Password </h3>
                  <div className="text-muted font-weight-bold">
                    Please enter your new password
                  </div>
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    name="password"
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                  <span className="text-danger">{errors.password}</span>
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="d-flex btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    onClick={(e) => {
                      handlesetSubmit(e);
                    }}
                  >
                    Submit
                    {loader && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: "5px" }}
                      >
                        <div
                          className="spinner-border text-white "
                          role="status"
                        ></div>
                        {/* <span className="sr-only">Loading...</span> */}
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      </>
    </>
  );
}

export default ForgotPassword;
