import React from "react";
import { useState } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { getUserInfo } from "../../../utils/user.util";
import CompititionReport from "../CompititionReport/CompititionReport";
import CouponsReport from "../CouponsReport/CouponsReport";
import PrizesReport from "../PrizesReport/PrizesReport";
import ReservationReport from "../ReservationReport/ReservationReport";
import ClientReport from "./ClientReport";
import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import DataTable, { defaultThemes } from "react-data-table-component";
import ReportIcon from "../../_assets/plugins/keenthemes-icons/svg/Reports_Icon.png";
import ReportIcon1 from "../../_assets/plugins/keenthemes-icons/svg/report1.png";
import ReportIcon2 from "../../_assets/plugins/keenthemes-icons/svg/report2.png";
import ReportIcon3 from "../../_assets/plugins/keenthemes-icons/svg/report3.png";
import ReportIcon4 from "../../_assets/plugins/keenthemes-icons/svg/report4.png";
import ReportIcon5 from "../../_assets/plugins/keenthemes-icons/svg/report5.png";

const Report = () => {
  const [clientReport, setClientReport] = useState(false);
  const [reportCompitition, setReportCompitition] = useState(false);
  const [reportReservation, setReportReservation] = useState(false);
  const [priceReport, setPriceReport] = useState(false);
  const [couponReport, setCouponReport] = useState(false);

  const [getAllStore, setGetAllStore] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [openGetOrder, setOpengetOrder] = useState(false);
  const [storeOrderData, setStoreOrderData] = useState({});

  const userInfo = getUserInfo();

  useEffect(() => {
    getAllStoreData();
  }, [page, countPerPage]);

  const getAllStoreData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        userInfo?.role === "owner"
          ? `store/find?userId=${userInfo?._id}`
          : `/store/find?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStore(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        userInfo?.role === "owner"
          ? `store/find?userId=${userInfo?._id}`
          : `store/find?letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStore(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: " Store Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Store Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Report Information",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-center m-auto">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setOpengetOrder(true);
                  setStoreOrderData(row?._id);
                }}
              >
                <Tooltip title="Order Details" arrow>
                  <InfoOutlinedIcon
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];

  return (
    <>
      <div className="card">
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={ReportIcon} alt="ReportIcon" className="client" />
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          {userInfo?.role === "owner" ? (
            <>
             
              <>
                {/* <GetOrderOfOwner storeOrderData={storeOrderData} /> */}
                <div className="cus-medium-button-style button-height d-flex flex-wrap px-9">
                  <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                    <img src={ReportIcon2} alt="ReportIcon2" />
                    <button
                      className="btn btn-color mr-2"
                      onClick={() => {
                        setClientReport(true);
                        setReportCompitition(false);
                        setReportReservation(false);
                        setPriceReport(false);
                        setCouponReport(false);
                      }}
                    >
                      <div style={{ fontWeight: "bold" ,color:"#002A5A"}}>CLIENT REPORTS</div>
                    </button>
                  </div>
                 
                  <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                    <img
                      src={ReportIcon3}
                      alt="ReportIcon3"
                      className="small-img"
                    />

                    <button
                      onClick={() => {
                        setReportReservation(true);
                        setClientReport(false);
                        setReportCompitition(false);
                        setPriceReport(false);
                        setCouponReport(false);
                      }}
                      className="btn btn-color mr-2"
                    >
                      <div style={{ fontWeight: "bold" ,color:"#002A5A"}}>
                        RESERVATION REPORTS{" "}
                      </div>
                    </button>
                  </div>
                  <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                    <img
                      src={ReportIcon4}
                      alt="ReportIcon4"
                      className="small-img"
                    />
                    <button
                      onClick={() => {
                        setPriceReport(true);
                        setReportReservation(false);
                        setClientReport(false);
                        setReportCompitition(false);
                        setCouponReport(false);
                      }}
                      className="btn btn-color mr-2"
                    >
                      <div style={{ fontWeight: "bold" ,color:"#002A5A"}}> PRIZE REPORTS</div>
                    </button>
                  </div>
                  {/* <button
                          onClick={() => {
                            setPriceReport(false);
                            setReportReservation(false);
                            setClientReport(false);
                            setReportCompitition(false);
                            setCouponReport(true);
                          }}
                          className="btn btn-color mr-2"
                        >
                          Coupons
                        </button> */}
                </div>
                <br />
                <br />
                {clientReport && (
                  <Dialog fullScreen open={clientReport}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setClientReport(false)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Toolbar>
                    <List>
                      <>
                        <ClientReport storeId={storeOrderData} />
                      </>
                    </List>
                  </Dialog>
                )}
                {reportCompitition && (
                  <Dialog fullScreen open={clientReport}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setClientReport(false)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Toolbar>
                    <List>
                      <>
                        <CompititionReport storeId={storeOrderData} />
                      </>
                    </List>
                  </Dialog>
                )}
                {reportReservation && (
                  <Dialog fullScreen open={reportReservation}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setReportReservation(false)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Toolbar>
                    <List>
                      <>
                        <ReservationReport storeId={storeOrderData} />
                      </>
                    </List>
                  </Dialog>
                )}

                {priceReport && (
                  <Dialog fullScreen open={priceReport}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setPriceReport(false)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Toolbar>
                    <List>
                      <>
                        <PrizesReport storeId={storeOrderData} />
                      </>
                    </List>
                  </Dialog>
                )}
                {couponReport && <CouponsReport storeId={storeOrderData} />}
              </>

              {openGetOrder === true && (
                <Dialog fullScreen open={openGetOrder}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => setOpengetOrder(false)}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                  <List>
                    <>
                      {/* <GetOrderOfOwner storeOrderData={storeOrderData} /> */}
                      <div className="cus-medium-button-style button-height d-flex flex-wrap px-9">
                        <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                          <img src={ReportIcon2} alt="ReportIcon2" />
                          <button
                            className="btn btn-color mr-2"
                            onClick={() => {
                              setClientReport(true);
                              setReportCompitition(false);
                              setReportReservation(false);
                              setPriceReport(false);
                              setCouponReport(false);
                            }}
                          >
                            Client
                          </button>
                        </div>
                       
                        <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                          <img
                            src={ReportIcon3}
                            alt="ReportIcon3"
                            className="small-img"
                          />

                          <button
                            onClick={() => {
                              setReportReservation(true);
                              setClientReport(false);
                              setReportCompitition(false);
                              setPriceReport(false);
                              setCouponReport(false);
                            }}
                            className="btn btn-color mr-2"
                          >
                            Reservation
                          </button>
                        </div>
                        <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                          <img
                            src={ReportIcon4}
                            alt="ReportIcon4"
                            className="small-img"
                          />
                          <button
                            onClick={() => {
                              setPriceReport(true);
                              setReportReservation(false);
                              setClientReport(false);
                              setReportCompitition(false);
                              setCouponReport(false);
                            }}
                            className="btn btn-color mr-2"
                          >
                            Prizes
                          </button>
                        </div>
                        
                      </div>
                      <br />
                      <br />
                      {clientReport && (
                        <Dialog fullScreen open={clientReport}>
                          <Toolbar>
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={() => setClientReport(false)}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Toolbar>
                          <List>
                            <>
                              <ClientReport storeId={storeOrderData} />
                            </>
                          </List>
                        </Dialog>
                      )}
                      {reportCompitition && (
                        <Dialog fullScreen open={reportCompitition}>
                          <Toolbar>
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={() => setClientReport(false)}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Toolbar>
                          <List>
                            <>
                              <CompititionReport storeId={storeOrderData} />
                            </>
                          </List>
                        </Dialog>
                      )}
                      {reportReservation && (
                        <Dialog fullScreen open={reportReservation}>
                          <Toolbar>
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={() => setReportReservation(false)}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Toolbar>
                          <List>
                            <>
                              <ReservationReport storeId={storeOrderData} />
                            </>
                          </List>
                        </Dialog>
                      )}

                      {priceReport && (
                        <Dialog fullScreen open={priceReport}>
                          <Toolbar>
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={() => setPriceReport(false)}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </Toolbar>
                          <List>
                            <>
                              <PrizesReport storeId={storeOrderData} />
                            </>
                          </List>
                        </Dialog>
                      )}
                      {couponReport && (
                        <CouponsReport storeId={storeOrderData} />
                      )}
                    </>
                  </List>
                </Dialog>
              )}
            </>
          ) : (
            <>
              <div className="cus-medium-button-style button-height d-flex flex-wrap px-9">
                <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10 ">
                  <img src={ReportIcon1} alt="ReportIcon1" />
                  <button
                    className="btn w-100 mr-2"
                    onClick={() => {
                      setClientReport(true);
                      setReportCompitition(false);
                      setReportReservation(false);
                      setPriceReport(false);
                      setCouponReport(false);
                    }}
                  >
                    <div style={{ fontWeight: "bold" ,color:"#002A5A"}}>CLIENT REPORTS</div>
                  </button>
                </div>
                <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                  <img src={ReportIcon2} alt="ReportIcon2" />

                  <button
                    className="btn w-100 mr-2"
                    onClick={() => {
                      setReportCompitition(true);
                      setClientReport(false);
                      setReportReservation(false);
                      setPriceReport(false);
                      setCouponReport(false);
                    }}
                  >
                   
                    <div style={{ fontWeight: "bold" ,color:"#002A5A"}}>COMPETITION REPORTS</div>
                  </button>
                </div>
                <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                  <img
                    src={ReportIcon3}
                    alt="ReportIcon3"
                    className="small-img"
                  />

                  <button
                    className="btn w-100 mr-2"
                    onClick={() => {
                      setReportReservation(true);
                      setClientReport(false);
                      setReportCompitition(false);
                      setPriceReport(false);
                      setCouponReport(false);
                    }}
                  >
                    <div style={{ fontWeight: "bold" ,color:"#002A5A"}}>RESERVATION REPORTS</div>
                  </button>
                </div>
                <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                  <img
                    src={ReportIcon4}
                    alt="ReportIcon4"
                    className="small-img"
                  />

                  <button
                    className="btn w-100 mr-2"
                    onClick={() => {
                      setPriceReport(true);
                      setReportReservation(false);
                      setClientReport(false);
                      setReportCompitition(false);
                      setCouponReport(false);
                    }}
                  >
                    <div style={{ fontWeight: "bold" ,color:"#002A5A"}}>PRIZE REPORTS</div>
                  </button>
                </div>
                <div className="d-flex flex-column justify-content-between report-box-alignment mb-8 mr-10">
                  <img
                    src={ReportIcon5}
                    alt="ReportIcon5"
                    className="small-img"
                  />

                  <button
                    className="btn w-100 mr-2"
                    onClick={() => {
                      setPriceReport(false);
                      setReportReservation(false);
                      setClientReport(false);
                      setReportCompitition(false);
                      setCouponReport(true);
                    }}
                  >
                    <div style={{ fontWeight: "bold" ,color:"#002A5A"}}>COUPON REPORTS</div>
                  </button>
                </div>
              </div>
              <br />
              <br />
              {clientReport && (
                <Dialog fullScreen open={clientReport}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => setClientReport(false)}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                  <List>
                    <>
                      <ClientReport />
                    </>
                  </List>
                </Dialog>
              )}

              {reportCompitition && (
                <Dialog fullScreen open={reportCompitition}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => setReportCompitition(false)}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                  <List>
                    <>
                      <CompititionReport />
                    </>
                  </List>
                </Dialog>
              )}

              {reportReservation && (
                <Dialog fullScreen open={reportReservation}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => setReportReservation(false)}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                  <List>
                    <>
                      <ReservationReport />
                    </>
                  </List>
                </Dialog>
              )}

              {priceReport && (
                <Dialog fullScreen open={priceReport}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => setPriceReport(false)}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                  <List>
                    <>
                      <PrizesReport />
                    </>
                  </List>
                </Dialog>
              )}

              {couponReport && (
                <Dialog fullScreen open={couponReport}>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => setCouponReport(false)}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                  <List>
                    <>
                      <CouponsReport />
                    </>
                  </List>
                </Dialog>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Report;
