import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { ApiDelete, ApiGet, ApiPost, ApiPut, } from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import AddCtegoryIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import CategoryIcon from "../../_assets/plugins/keenthemes-icons/svg/Competition.png";
import CTGYIcon from "../../_assets/plugins/keenthemes-icons/svg/categoryctgyicon.png";
export default function CompetitionCategories() {

  const [competitionData, setCompetitionData] = useState({});
  const [addCompetition, setAddCompetition] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllCompetitionType, setGetAllCompetitionType] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [competitionIdForEdit, setCompetitionIdForEdit] = useState();
  const [competitionIdForDelete, setCompetitionIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getAllCompetitionforData();
  }, [page, countPerPage]);

  const addPackageModelClose = (e) => {
    setAddCompetition(false);
    setIsEditApi(false);
    setErrors(false);
    setCompetitionData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setCompetitionData({ ...competitionData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!competitionData?.type?.trim() || competitionData?.type === "") {
      formvalied = false;
      errors["type"] = "*Please enter Type.";
    }
    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  const getAllCompetitionforData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : ""
    await ApiGet(`gameType/find?page=${page}&limit=${countPerPage}&days=30${url}`)
      .then((res) => {
        setGetAllCompetitionType(res?.data?.payload);
        setCount(res?.data?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });

  };

  const addCompetitionforData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let data = {
        type: competitionData?.type,
      };

      await ApiPost(`gameType/create`, data)
        .then((res) => {
          toast.success("Category add successfully");
          setAddCompetition(false);
          setIsEditApi(false);
          getAllCompetitionforData();
          setCompetitionData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editCompetitionforData = async (e) => {
    if (formValidation()) {
      setLoader(true);
      await ApiPut(`gameType/update?id=${competitionIdForEdit}&type=${competitionData?.type}`)
        .then((res) => {
          toast.success("Category update successfully");
          getAllCompetitionforData();
          setAddCompetition(false);
          setIsEditApi(false);
          setCompetitionData([]);
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const deleteCompetitionData = async () => {
    await ApiDelete(`gameType/delete?id=${competitionIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Category  delete successfully");
          setShow(false);
          getAllCompetitionforData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCompetitionforData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCompetitionforData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setAddCompetition(true);
                  setCompetitionIdForEdit(row?._id);
                  setCompetitionData({
                    type: row?.type,
                  });
                }}
              >
                <Tooltip title="edit blog" arrow>
                  <button style={{
                    background: "#002a5a", color: "white", borderRadius: "20px", border: "2px solid #939393",
                    padding: "5px 10px",  whiteSpace: "nowrap"
                  }} onClick={(e) => {
                    setIsEditApi(true);
                    setAddCompetition(true);
                    setCompetitionIdForEdit(row?._id);
                    setCompetitionData({
                      type: row?.type,
                    });
                  }}>Manage</button>
                </Tooltip>
              </div>

              {/* <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCompetitionIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete blog" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div> */}
            </div>
          </>
        );
      },
      width: "10%",
    },

    {
      name: "Delete",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between mr-3">
              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  setCompetitionIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete blog" arrow>
                  <button
                    style={{
                      background: "#ff0000",
                      color: "white",
                      borderRadius: "20px",
                      border: "2px solid #939393",
                      padding: "5px 10px"
                    }}>
                    Delete
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />
        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={CategoryIcon} alt="CategoryIcon" className="exchangeImg" />
            {/* <div className="client-details pl-2"> */}
            {/* <h1 className="text-white">{count}</h1>
              <h6 className="text-white">All Competition Categories</h6> */}
            {/* </div> */}
          </div>
        </div>
        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Categories</h2> */}
            </div>

            <div className="cus-medium-button-style button-height  button-details-alignment">
              <button
                className="btncenter"
                onClick={() => {
                  setAddCompetition(true);
                }}
              >
                <img src={AddCtegoryIcon} alt={AddCtegoryIcon} />
                <img src={CTGYIcon} alt="CTGYIcon" className="prizeCategory-child-img" />
                Add CTGY
              </button>
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style`}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllCompetitionType}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteCompetitionData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addCompetition === true ? (
        <Dialog fullScreen open={addCompetition}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addPackageModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="heading-banner d-flex justify-content-center">
              <div className="align-items-center d-flex">
                <img src={CategoryIcon} alt="CategoryIcon" className="exchangeImg" />
                <div className="client-details pl-2">
                  <h6 className="text-white child-heading">Competition Categories</h6>
                </div>
              </div>
            </div>
            <div className="m-auto w-1000px pt-10">
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">Type</label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="title"
                      name="type"
                      value={competitionData?.type}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["type"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center  button-details-alignment">
                    <button className="btn" type="button">
                      <img src={AddCtegoryIcon} alt={AddCtegoryIcon} />
                      <img src={CTGYIcon} alt="CTGYIcon" />
                      {isEditApi ? "Edit CTGY" : "Add CTGY"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <div className=" button-details-alignment">
                  <button
                    className="btn center"
                    onClick={(e) => {
                      isEditApi ? editCompetitionforData(e) : addCompetitionforData(e);
                    }}
                  >
                    <img src={AddCtegoryIcon} alt={AddCtegoryIcon} />
                    <img src={CTGYIcon} alt="CTGYIcon" />
                    {isEditApi ? "Edit CTGY" : "Add CTGY"}

                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
