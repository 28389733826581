import React from "react";

const CommonRadioButton = ({ imgSrc, imgAlt, id, name, value, label, storeData, errors, onhandleChange }) => {
  return (
    <div className="form-group row align-items-center" style={{ height: "80px" }}>
      <img src={imgSrc} height={"40px"} width={"40px"} alt={imgAlt} />
      <div className="col-lg-4 col-xl-4">
        <div>
          <input
            type="radio"
            className={`form-control form-control-lg form-control-solid `}
            id={id}
            name={name}
            value={value}
            checked={storeData.category === value}
            onChange={(e) => onhandleChange(e)}
          />
        </div>
        <span
          style={{
            color: "red",
            top: "5px",
            fontSize: "12px",
          }}
        >
          {errors[value]}
        </span>
      </div>
    </div>
  );
};

export default CommonRadioButton;
  