import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import StoreClientImg from "../../_assets/plugins/keenthemes-icons/svg/Store_Clients.png";

export default function GetClientForOwner(props) {
  const { clientData } = props;

  const [itamData, setItamData] = useState({});
  const [addItam, setAddItam] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllStoreData, setGetAllStoreData] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [itemIdForEdit, setItemIdForEdit] = useState();
  const [itamIdForDelete, setItamIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [show, setShow] = useState(false);
  const [getCategoryData, setGetCategoryData] = useState({});
  let item = localStorage.getItem("localStoreId");
  item = item && JSON.parse(item);
  useEffect(() => {
    getAllStoreItamData();
  }, [page, countPerPage]);
  useEffect(() => {
    getAllCategory();
  }, []);

  const addItemModelClose = (e) => {
    setAddItam(false);
    setIsEditApi(false);
    setErrors(false);
    setItamData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setItamData({ ...itamData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setItamData({ ...itamData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!itamData?.itemName?.trim() || itamData?.itemName === "") {
      formvalied = false;
      errors["itemName"] = "*Please enter itemName.";
    }
    if (
      !itamData?.itemDescription?.trim() ||
      itamData?.itemDescription === ""
    ) {
      formvalied = false;
      errors["itemDescription"] = "*Please enter item Description.";
    }
    if (itamData && !itamData?.storeItemImage) {
      formvalied = false;
      errors["storeItemImage"] = "*Please select Image.";
    }
    if (!itamData?.itemPrice || itamData?.itemPrice === "") {
      formvalied = false;
      errors["itemPrice"] = "*Please enter item Price.";
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  const getAllCategory = async () => {
    await ApiGet(`category/find`)
      .then((res) => {
        setGetCategoryData(res?.data?.payload);
      })
      .catch((err) => { });
  };

  const getAllStoreItamData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        `order/findUser?storeId=${(item).trim()}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStoreData(res?.data?.data[0]?.data);
          setCount(res?.data?.data?.[0]?.count[0]?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `order/findUser?storeId=${(item).trim()}&letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllStoreData(res?.data?.data[0]?.data);
          setCount(res?.data?.data?.[0]?.count[0]?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const addStoreItemData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("storeId", (item).trim());
      formData.append("itemName", itamData?.itemName);
      formData.append("itemDescription", itamData?.itemDescription);
      formData.append("storeItemImage", itamData?.storeItemImage);
      formData.append("itemPrice", itamData?.itemPrice);
      formData.append("isForAdult", itamData?.isForAdult);
      formData.append("categoryId", itamData?.categoryId);

      await ApiPost(`storeItem/create`, formData)
        .then((res) => {
          toast.success("item add successfully");
          setAddItam(false);
          setIsEditApi(false);
          getAllStoreItamData();
          setItamData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editStoreItemData = async (e) => {
    if (formValidation()) {
      let formData = new FormData();
      formData.append("storeId", (item).trim());
      formData.append("itemName", itamData?.itemName);
      formData.append("itemDescription", itamData?.itemDescription);
      formData.append("storeItemImage", itamData?.storeItemImage);
      formData.append("itemPrice", itamData?.itemPrice);
      formData.append("isForAdult", itamData?.isForAdult);
      formData.append("categoryId", itamData?.categoryId);

      setLoader(true);

      await ApiPut(`storeItem/update?id=${itemIdForEdit}`, formData)
        .then((res) => {
          toast.success("item update successfully");
          getAllStoreItamData();
          setAddItam(false);
          setIsEditApi(false);
          setItamData([]);
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  // For Delete game
  const deleteStoreItemData = async () => {
    await ApiDelete(`storeItem/delete?id=${itamIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Delete item successfully");
          setShow(false);
          getAllStoreItamData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };

  //For search

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreItamData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllStoreItamData();
    }
  }, [debouncedSearchTerm]);

  // -------------------------DATA TABLE--------------------
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },

    {
      name: "User Name",
      selector: (row) => (row?.userId?.username ? row?.userId?.username : "-"),
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => (row?.userId?.email ? row?.userId?.email : "-"),
      sortable: true,
    },
    {
      name: "Order Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "pending" && (
              <span class="btn" style={{ background: "yellow" }}>
                {row?.status}
              </span>
            )}
            {row?.status === "canceled" && (
              <span class="btn btn-danger">{row?.status}</span>
            )}
            {row?.status === "complete" && (
              <span class="btn" style={{ background: " #22cc00" }}>
                {row?.status}
              </span>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row?.userId?.address ? row?.userId?.address : "-"),
      sortable: true,
    },
    {
      name: "city",
      selector: (row) => (row?.userId?.city ? row?.userId?.city : "-"),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => (row?.userId?.state ? row?.userId?.state : "-"),
      sortable: true,
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img src={StoreClientImg} alt="StoreClientImg" className="client" />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">Total Clients</h6>
            </div>
          </div>
        </div>
        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 justify-content-between">
            <div className=" d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Clients</h2> */}
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          //Data Table

          <DataTable
            columns={columns}
            data={getAllStoreData}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Prize?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteStoreItemData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addItam === true ? (
        <Dialog fullScreen open={addItam}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addItemModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="ml-40">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Category
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      name="categoryId"
                      id="categoryId"
                      defaultValue={itamData?.categoryId}
                      className="form-control"
                      aria-label="Default select example"
                      onChange={(e) => onhandleChange(e)}
                    >
                      <option selected disabled>
                        select Category
                      </option>
                      {getCategoryData &&
                        getCategoryData?.map((data) => (
                          <option value={data._id}>{data?.name}</option>
                        ))}
                    </select>

                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["userId"]}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Item Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="title"
                      name="itemName"
                      value={itamData?.itemName}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemName"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Item Description
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      className={`form-control form-control-lg form-control-solid `}
                      id="itemDescription"
                      name="itemDescription"
                      value={itamData?.itemDescription}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemDescription"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Item Image
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <div className="labelMainDiv">
                      <label htmlFor="itamImage" className="lableForImage">
                        select file
                      </label>
                      {itamData?.companyLogo ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid `}
                      name="storeItemImage"
                      id="itamImage"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {itamData?.storeItemImage && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          itamData?.storeItemImage
                            ?.toString()
                            ?.includes(".com") === true
                            ? itamData?.storeItemImage
                            : URL.createObjectURL(itamData?.storeItemImage)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["storeItemImage"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Item Price
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="number"
                      className={`form-control form-control-lg form-control-solid `}
                      id="itemPrice"
                      name="itemPrice"
                      value={itamData?.itemPrice}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemPrice"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Is For Adult
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      className={`form-control form-control-lg form-control-solid `}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                      defaultValue={itamData?.isForAdult}
                      name="isForAdult"
                    >
                      <option value="select for adult" selected disabled>
                        select for adult
                      </option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["itemPrice"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center">
                    <button className="btn btn-color" type="button">
                      {isEditApi ? "Edit  item" : "Add item"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-color center"
                  onClick={(e) => {
                    isEditApi ? editStoreItemData(e) : addStoreItemData(e);
                  }}
                >
                  {isEditApi ? "Edit  item" : "Add item"}
                </button>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
