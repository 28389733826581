import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import DashboardPage from "./pages/DashboardPage";
import User from "../_metronic/components/User/User";
// import Package from "../_metronic/components/Package/Package";
import Transaction from "../_metronic/components/Transaction/Transaction";
import Coupon from "../_metronic/components/Coupon/Coupon";
import Category from "../_metronic/components/category/Category";
import Currency from "../_metronic/components/Currency/Currency";
import Game from "../_metronic/components/Game/Game";
import Store from "../_metronic/components/Store/Store";
import Order from "../_metronic/components/Order/Order";
import NumberOfBoughtToken from "../_metronic/components/NumberOfBoughtToken/NumberOfBoughtToken";
import StoreForOwner from "../_metronic/components/StoreForOwner/StoreForOwner";
import PrizeForOwner from "../_metronic/components/PrizeForOwner/PrizeForOwner";
import ClientForOwner from "../_metronic/components/ClientForOwner/ClientForOwner";
import Packages from "../_metronic/components/packages/Packages";
import Client from "../_metronic/components/Client/Client";
import Report from "../_metronic/components/Report/Report";
import DiscountPackage from "../_metronic/components/DiscountPackage/DiscountPackage";
import Compitition from "../_metronic/components/Compitition/Compitition";
import GameForOwner from "../_metronic/components/GameForOwner/GameForOwner";
import Setting from "../_metronic/components/Setting/Setting";
import Storeitem from "../_metronic/components/StoreItam/StoreItam";
import OwnerCoupon from "../_metronic/components/OwnerCoupon/OwnerCoupon";
import CompetitionForOwner from "../_metronic/components/CompetitionForOwner/CompetitionForOwner";
import EditStore from "../_metronic/components/EditStore/EditStore";
import GameCompetition from "../_metronic/components/GameCompetition/GameCompetition";
import Announcements from "../_metronic/components/announcements/Announcements";
import AssignStoreMsg from "../_metronic/components/AssignStoreMsg/AssignStoreMsg";

export default function BasePage() {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          

          <Redirect exact from="/" to="/dashboard" />
          <ContentRoute exact path="/dashboard" component={DashboardPage} />
          <ContentRoute exact path="/user" component={User} />
          <ContentRoute exact path="/store" component={Store} />
          <ContentRoute exact path="/game" component={Game} />
          <ContentRoute exact path="/order" component={Order} />
          <ContentRoute exact path="/client" component={Client} />
          <ContentRoute exact path="/coupon" component={Coupon} />
          <ContentRoute exact path="/package" component={Packages} />
          <ContentRoute exact path="/transaction" component={Transaction} />
          <ContentRoute exact path="/category" component={Category} />
          <ContentRoute exact path="/currency" component={Currency} />
          <ContentRoute exact path="/owner-order" component={StoreForOwner} />
          <ContentRoute exact path="/owner-prize" component={PrizeForOwner} />
          <ContentRoute exact path="/owner-client" component={ClientForOwner} />
          <ContentRoute exact path="/owner-game" component={GameForOwner} />
          <ContentRoute exact path="/owner-coupon" component={OwnerCoupon} />
        <ContentRoute exact path="/prizes" component={Storeitem} />
        <ContentRoute exact path="/edit-store" component={EditStore} />
        <ContentRoute exact path="/game-competition" component={GameCompetition} />
        <ContentRoute exact path="/Announcements" component={Announcements} />
        {/* <ContentRoute exact path="/owner-competition" component={CompetitionForOwner} /> */}

          <ContentRoute
            exact
            path="/discount-Package"
            component={DiscountPackage}
          />
          <ContentRoute exact path="/report" component={Report} />
          <ContentRoute exact path="/compitition" component={Compitition} />
          <ContentRoute exact path="/setting" component={Setting} />
          <ContentRoute
            exact
            path="/number-of-tokens-bought"
            component={NumberOfBoughtToken}
          />
         
          <Redirect to="error/error-v6" />
        </Switch>
      </Suspense>
    </>
  );
}
