import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import { getUserInfo } from "../../../utils/user.util";

const ClientReport = (props) => {
  const [getAllOrder, setGetAllOrder] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [downloadLink, setDownloadLink] = useState();

  const userInfo = getUserInfo();
  let item = localStorage.getItem("localStoreId");
  item = item && JSON.parse(item);

  const storeId = props?.storeId;

  useEffect(() => {
    getAllOrderData();
  }, [page, countPerPage]);

  const getAllOrderData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        userInfo?.role === "owner"
          ? `order/findUser?storeId=${(item).trim()}&page=${page}&limit=${countPerPage}`
          : `order/findUser?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllOrder(res?.data?.data[0]?.data);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        userInfo?.role === "owner"
          ? `order/findUser?storeId=${(item).trim()}&page=${page}&limit=${countPerPage}`
          : `order/findUser?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllOrder(res?.data?.data);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      selector: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "Created Date",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "UserName",
      selector: (row) => row?.userId?.username,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => (row?.userId?.email ? row?.userId?.email : "-"),
      sortable: true,
    },
    {
      name: "Order Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "pending" && (
              <span class="btn" style={{ background: "yellow" }}>
                {row?.status}
              </span>
            )}
            {row?.status === "canceled" && (
              <span class="btn btn-danger">{row?.status}</span>
            )}
            {row?.status === "complete" && (
              <span class="btn" style={{ background: " #22cc00" }}>
                {row?.status}
              </span>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row?.userId?.address ? row?.userId?.address : "-"),
      sortable: true,
    },
    {
      name: "city",
      selector: (row) => (row?.userId?.city ? row?.userId?.city : "-"),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => (row?.userId?.state ? row?.userId?.state : "-"),
      sortable: true,
    },
  ];


  const clientReportDownload = async () => {
    await ApiGet(
      userInfo?.role === "owner"
        ? `order/download-report?storeId=${(item).trim()
        }&page=${page}&limit=${countPerPage}`
        : `order/download-report?page=${page}&limit=${countPerPage}`
    )
      .then((res) => {
        // window.open(res?.data?.data)
        setDownloadLink(res?.data?.data);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };
  useEffect(() => {
    clientReportDownload();
  }, []);

  return (
    <div className="card p-1">
      <ToastContainer />
      <div className="p-2 mb-2">
        <div className="row mb-4 pr-3">
          <div className="col d-flex justify-content-between">
            <h2 className="pl-3 pt-2">Client</h2>
          </div>
          <div className="col">
            <div>
              {/* <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                /> */}

              <a href={downloadLink}>
                <button className="btn btn-color mr-2">Download</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      {loaderForGetAll ? (
        <div className="text-center">
          <div className="spinner-border"></div>
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={getAllOrder}
          customStyles={customStyles}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={count}
          paginationPerPage={countPerPage}
          paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
          paginationDefaultPage={page}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(rowPerPage) => {
            setCountPerPage(rowPerPage);
          }}
        />
      )}
    </div>
  );
};

export default ClientReport;
