import React from "react";
import round21 from "../../_assets/plugins/keenthemes-icons/svg/21Round.png";
import RightSignResavation from "../../_assets/plugins/keenthemes-icons/svg/right-sign-resavation.png";
import Details12 from "../../_assets/plugins/keenthemes-icons/svg/12Details.png";
import moment from "moment/moment";
import { ApiPut } from "../../../helpers/API/ApiData";
import Details21Plus from "../../_assets/plugins/keenthemes-icons/svg/21+img.png";
import CanceledImg from "../../_assets/plugins/keenthemes-icons/svg/cancelledimg.png";

export default function OrderDetails(props) {
  const {
    storeOrderData,
    completeresrvation,
    canceledresrvation,
    getAllOrder,
    indexNumber
  } = props;
  return (
    <div>
      <div className="heading-banner d-flex justify-content-center">
        <div className="align-items-center d-flex">
          <div className="client-details pl-2 pr-3">
            <h6
              className="text-white child-heading"
              style={{ textAlign: "end" }}
            >
              Reservation
            </h6>
            <h6
              className="text-white child-heading"
              style={{ textAlign: "end" }}
            >
              Number
            </h6>
          </div>
          {/* <img src={Details12} alt="Details12" className="coupon" /> */}
          <div className="number-details-alignment">
            <span>{storeOrderData?.order_Id}</span>
          </div>
          {/* {indexNumber? indexNumber : "-"} */}
          
        </div>

        <div className="align-items-center d-flex ml-40">
          <div className="client-details pl-2 pr-3">
            <h6
              className="text-white child-heading"
              style={{ textAlign: "end", cursor: "pointer" }}
            >
              Complete
            </h6>
            <h6
              className="text-white child-heading"
              style={{ textAlign: "end", cursor: "pointer" }}
            >
              Reservation
            </h6>
          </div>
          {storeOrderData?.status === "canceled" ? (
            <img src={CanceledImg} alt="CanceledImg" className="coupon" />
          ) : (
            <img
              src={RightSignResavation}
              alt="RightSignResavation"
              className="coupon"
            />
          )}
        </div>

        <div className="align-items-center d-flex ml-40">
          <div className="client-details pl-2 pr-3">
            <h6
              className="text-white child-heading"
              style={{ textAlign: "end" }}
            >
              U21
            </h6>
            <h6
              className="text-white child-heading"
              style={{ textAlign: "end" }}
            >
              Reservation
            </h6>
          </div>

          {storeOrderData?.isUserAdult === true ? (
            <img src={Details21Plus} alt="Details21Plus" className="coupon" />
          ) : (
            <img src={round21} alt="round21" className="coupon" />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center gap-1 mt-5">
        <div className="reservation-button">
          <button onClick={(e) => completeresrvation(e)}>Compelete</button>
        </div>
        <div className="reservation-button">
          <button onClick={(e) => canceledresrvation(e)}>Canceled</button>
        </div>
      </div>
      <div className="align-items-center d-flex py-10 gap-3 w-1000px m-auto">
        <div className="main-img-alignment">

          <img
            src={storeOrderData?.Item[0]?.storeId?.storeImage[0]}
            alt="Demo1"
            className="order-product-detaild p-4"
          />
        </div>
        <div className="order-details-alignment text-center">
          <h5>Store Name</h5>
          <h6>{storeOrderData?.Item[0]?.storeId?.name}</h6>
        </div>
      </div>
      <div className="w-1000px m-auto pt-14">
        <div className="row">

          <div className="col-4">
            <div className="d-flex align-items-center flex-column">
              {storeOrderData?.Item[0]?.itemId?.storeItemImage?.map((item) => {
                return (
                  <>
                    <div className="order-product-detaild">
                      <img
                        src={item}
                        alt="Demo1"
                        className=" p-4"
                      />
                      <div className="symbol-img-alignment">
                        {storeOrderData?.isUserAdult === true ? (
                          <img src={Details21Plus} alt="Details21Plus" className="coupon" />
                        ) : (
                          <img src={round21} alt="round21" className="coupon" />
                        )}
                      </div>
                    </div>
                    <span className="img-text pt-3">Item Image</span>
                  </>
                );
              })}
            </div>
          </div>

          <div className="col-4">
            <div className="order-details-alignment text-center mb-20">
              <h5>Username</h5>
              <h6>{storeOrderData?.userName}</h6>
            </div>
            <div className="order-details-alignment text-center mb-20">
              <h5>Phone</h5>
              <h6>{storeOrderData?.phone}</h6>
            </div>
            <div className="order-details-alignment text-center mb-20">
              <h5>Email</h5>
              <h6>{storeOrderData?.email}</h6>
            </div>
          </div>
          <div className="col-4">
            <div className="order-details-alignment text-center mb-20">
              <h5>Date Placed</h5>
              <h6>{moment(storeOrderData?.createdAt).format("Do MMM YYYY")}</h6>
            </div>
            <div className="order-details-alignment text-center mb-20">
              <h5>Num Price</h5>
              <h6>{storeOrderData?.itemCount}</h6>
            </div>
            <div className="order-details-alignment text-center mb-20">
              <h5>Price</h5>
              <h6>{storeOrderData?.totalAmount}</h6>
            </div>
          </div>
        </div>

        {/* <div className="threeGrid mb-10">
          <div className="d-flex align-items-center flex-column">
            {storeOrderData?.Item[0]?.itemId?.storeItemImage?.map((item) => {
              return (
                <>
                  <img
                    src={item}
                    alt="Demo1"
                    className="order-product-detaild p-4"
                  />
                  <span className="img-text pt-3">Item Image</span>
                </>
              );
            })}
          </div>

          <div className="order-details-alignment text-center">
            <h5>Username</h5>
            <h6>{storeOrderData?.userName}</h6>
          </div>
          <div className="order-details-alignment text-center">
            <h5>Date Placed</h5>
            <h6>{moment(storeOrderData?.createdAt).format("Do MMM YYYY")}</h6>
          </div>
        </div>
        <div className="threeGrid mb-10">
          <div className="d-flex align-items-center flex-column">

          </div>

          <div className="order-details-alignment text-center">
            <h5>State</h5>
            <h6>{storeOrderData?.state}</h6>
          </div>
          <div className="order-details-alignment text-center">
            <h5>City</h5>
            <h6>{storeOrderData?.city}</h6>
          </div>
        </div> */}
        {/* <div className="d-flex justify-content-between align-items-center mb-10">
            <div className="d-flex align-items-center flex-column">
              <img
                src={Demo3}
                alt="Demo3"
                className="order-product-detaild p-4"
              />
              <span className="img-text pt-3">Age Cancellation Booster</span>
            </div>

            <div className="order-details-alignment text-center">
              <h5>Username</h5>
              <h6>admin_321_123</h6>
            </div>
            <div className="order-details-alignment text-center">
              <h5>Date Placed</h5>
              <h6>Oct 17th, 2022</h6>
            </div>
          </div> */}
      </div>
    </div>
  );
}
