/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { useState, useEffect } from "react";
import { createContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Routes } from "../app/Routes";
// import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import "react-toastify/dist/ReactToastify.css";
import { getUserInfo } from "../utils/user.util";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../helpers/API/ApiData";

export default function App() {
  const userInfo = getUserInfo();
  const [storeData, setStoreData] = useState({});
  const [getStoreId, setGetStoreId] = useState(storeData?._id);

  const storeDataContext = createContext();

  const getStoreData = async () => {
    await ApiGet(`store/find?userId=${userInfo?._id}`).then((res) => {
      setStoreData(res?.data?.payload);
    });
  };

  return (
    <>
      <React.Suspense fallback={<LayoutSplashScreen />}>
        <ToastContainer />
        <BrowserRouter>
          <MaterialThemeProvider>
            {/* <I18nProvider> */}
            <Routes />
            {/* </I18nProvider> */}
          </MaterialThemeProvider>
        </BrowserRouter>
      </React.Suspense>
    </>
  );
}
