import React from "react";
import { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Competition from "../Compitition/Compitition";
import "./Game.scss";
import AddGameIcon from "../../_assets/plugins/keenthemes-icons/svg/buttonImg.png";
import GameIcon from "../../_assets/plugins/keenthemes-icons/svg/rupeeIcon.png";
import WinningIcon from "../../_assets/plugins/keenthemes-icons/svg/cupIcon.png";
import MyPagination from "../Pagination/MyPagination";
import TicketsCollectionIcon from "../../_assets/plugins/keenthemes-icons/svg/Tickets_Collected.png";
import CompetitionIcon from "../../_assets/plugins/keenthemes-icons/svg/Package_Icon.png";
import CompetitionIcon3 from "../../_assets/plugins/keenthemes-icons/svg/comImg.png";

export default function Game() {
  const [gameData, setGameData] = useState({});
  const [gameImage, setGameImage] = useState([]);
  const [editImg, setEditImg] = useState();
  const [addGame, setAddGame] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllGame, setgetAllGame] = useState([]);
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(12);
  const [count, setCount] = useState(0);
  const [runningcount, setRunningCount] = useState(0);
  const [gameIdForEdit, setGameIdForEdit] = useState();
  const [gameIdForDelete, setgameIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [show, setShow] = useState(false);
  const [showCompitition, setShowCompition] = useState(false);
  const [gameIdForCompitition, setGameIdForCompitition] = useState();
  const [openAction, setOpenAction] = useState(false);
  const [defineIndeKey, setDefineIndexKey] = useState();
  const [getCategoryData, setGetCategoryData] = useState([]);

  useEffect(() => {
    getAllgameData();
    getAllCategory();
  }, [page]);
  function isValidURL(string) {
    var res = string.match(
      /(https?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }
  const addGameModelClose = (e) => {
    setAddGame(false);
    setIsEditApi(false);
    setErrors(false);
    setGameData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setGameData({ ...gameData, [name]: value });

    if (name === "geoSeries") {
      setGameData({ ...gameData, [name]: e.target.checked });
      return;
    }
    setErrors({ ...errors, [name]: "" });
  };

  const getAllCategory = async () => {
    await ApiGet(`gameType/find`)
      .then((res) => {
        setGetCategoryData(res?.data?.payload);
      })
      .catch((err) => {});
  };

  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setGameImage(Array.from(e.target.files));
    setErrors({ ...errors, [name]: "" });
  };

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!gameData?.name?.trim() || gameData?.name === "") {
      formvalied = false;
      errors["name"] = "*Please enter name.";
    }
    if (!isEditApi) {
      if (gameImage?.length <= 0) {
        formvalied = false;
        errors["file"] = "*Please select image.";
      }
    }
    if (!gameData?.description?.trim() || gameData?.description === "") {
      formvalied = false;
      errors["description"] = "*Please enter description.";
    }
    let isValidiosStoreLink = isValidURL(gameData?.iosStoreLink || "");
    if (!isValidiosStoreLink) {
      formvalied = false;
      errors["iosStoreLink"] = "*Please enter Valid url";
    }
    let isValidandroidStoreLink = isValidURL(gameData?.androidStoreLink || "");
    if (!isValidandroidStoreLink) {
      formvalied = false;
      errors["androidStoreLink"] = "*Please enter Valid url";
    }

    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };
  const compititionModelClose = () => {
    setShowCompition(false);
  };
  const handlePage = (data) => {
    setPage(data);
  };
  const getAllgameData = async () => {
    setLoaderForGetAll(true);
    let url = search ? `&letter=${search}` : "";
    await ApiGet(`game/find?page=${page}&limit=${limit}&days=30${url}`)
      .then((res) => {
        setgetAllGame(res?.data?.payload);
        setCount(res?.data?.count);
        setLoaderForGetAll(false);
        setTotal(Math.ceil((res?.data?.count || 1) / limit));
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  useEffect(() => {
    getAllCompititionCount();
  }, []);

  const getAllCompititionCount = async () => {
    setLoaderForGetAll(true);

    await ApiGet(`competation/find`)
      .then((res) => {
        setCount(res?.data?.completedCount);
        setRunningCount(res?.data?.runningCount);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };
  const addgameData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("name", gameData?.name);
      let fileArr = Array.from(gameImage);
      fileArr.forEach((file) => {
        formData.append("pictures", file);
      });
      formData.append("iosStoreLink", gameData?.iosStoreLink);
      formData.append("androidStoreLink", gameData?.androidStoreLink);
      formData.append("description", gameData?.description);
      formData.append("categoryId", gameData?.categoryId);
      formData.append("entryFee", gameData?.entryFee);
      formData.append("geoSeries", gameData?.geoSeries === true ? true : false);

      await ApiPost(`game/create`, formData)
        .then(async (res) => {
          let body = {
            gameId: res?.data?.data?._id,
          };
          await ApiPost(`game/thumbCreate`, body).then((res) => {
            toast.success("Game add successfully");
            setAddGame(false);
            setIsEditApi(false);
            getAllgameData();
            setGameData({});
            setGameImage([]);
            setLoader(false);
          });
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const editgameData = async (e) => {
    if (formValidation()) {
      let formData = new FormData();
      formData.append("name", gameData?.name);
      let fileArr = Array.from(gameImage ?? editImg);
      fileArr.forEach((file) => {
        formData.append("pictures", file);
      });
      formData.append("iosStoreLink", gameData?.iosStoreLink);
      formData.append("androidStoreLink", gameData?.androidStoreLink);
      formData.append("description", gameData?.description);
      if (gameData?.categoryId == undefined) {
        let categoryId =  getCategoryData?.find((data)=> data?.type == gameData?.category)?._id
        formData.append("categoryId", categoryId);
      }else{
        formData.append("categoryId", gameData?.categoryId);
      }
      formData.append("geoSeries", gameData?.geoSeries === true ? true : false);
      setLoader(true);

      await ApiPut(`game/update?id=${gameIdForEdit}`, formData)
        .then((res) => {
          toast.success("Game update successfully");
          getAllgameData();
          setAddGame(false);
          setIsEditApi(false);
          setGameData([]);
          setLoader(false);
          setGameImage([]);
        })
        .catch((err) => {
          toast.error(err?.response?.statusText);
          setLoader(false);
        });
    }
  };

  const deletegameData = async () => {
    await ApiDelete(`game/delete?id=${gameIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Delete Game successfully");
          setShow(false);
          getAllgameData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.statusText);
      });
  };
  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllgameData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllgameData();
    }
  }, [debouncedSearchTerm]);

  return (
    <>
      <div className="heading-banner d-flex justify-content-center">
        <div className="align-items-center d-flex">
          <img src={CompetitionIcon} alt="CompetitionIcon" className="coupon" />
          <div className="client-details pl-2">
            <h1 className="text-white">{runningcount}</h1>
            <h6 className="text-white">In-Progress Competitions</h6>
          </div>
        </div>

        <div className="align-items-center d-flex ml-40">
          <img
            src={CompetitionIcon3}
            alt="TicketsCollectionIcon"
            className="coupon"
          />
          <div className="client-details pl-2">
            <h1 className="text-white">{count}</h1>
            <h6 className="text-white">Completed Competitions</h6>
          </div>
        </div>
      </div>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3 align-items-center justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Game</h2> */}
            </div>

            <div className="cus-medium-button-style button-height button-details-alignment">
              <button
                className="btn"
                onClick={() => {
                  setAddGame(true);
                }}
              >
                <img src={AddGameIcon} alt={AddGameIcon} />
                <img src={GameIcon} alt="GameIcon" />
                Add Game
              </button>
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <div>
            <div className="gallery-box">
              {getAllGame?.length <= 0 ? (
                <div style={{ textAlign: "center" }}>
                  There are no records to display
                </div>
              ) : (
                <div className="gallery-grid">
                  {getAllGame?.map((data, index) => {
                    console.log(`getAllGame`,getAllGame)
                    return (
                      <>
                        <div>
                          <div className="gallery-grid-items">
                            <div className="card-image">
                              <img src={data?.pictures[0]} alt="img" />
                            </div>
                          </div>
                          <div className="card-details m-10 p-5">
                            <div>
                              <div className="d-flex ">
                                <div className="d-flex align-items-center ">
                                  <p className="badge badge-warning ">
                                    {" "}
                                    <a
                                      style={{ color: "black" }}
                                      href={data?.iosStoreLink}
                                      target="_blank"
                                    >
                                      iOS
                                    </a>
                                  </p>
                                  <p className="badge badge-warning ml-4">
                                    <a
                                      style={{ color: "black" }}
                                      href={data?.androidStoreLink}
                                      target="_blank"
                                    >
                                      android
                                    </a>
                                  </p>

{data?.geoSeries === true &&(

                                  <div
                                    className="ml-4 cus-medium-button-style button-details-alignment d-flex justify-content-center"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <button
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        padding: "5px",
                                      }}
                                    >
                                      <img
                                        src={WinningIcon}
                                        alt={WinningIcon}
                                      />
                                      <img
                                        src={GameIcon}
                                        alt="GameIcon"
                                        className="child-img"
                                      />
                                      Geo-Series
                                    </button>
                                  </div>
)}

                                </div>
                              </div>
                              <h1>{data?.name}</h1>

                              <p>
                                {" "}
                                {data?.description?.length > 50
                                  ? data?.description?.substring(0, 50) + "..."
                                  : data?.description}
                              </p>
                              <div className="d-flex align-items-center justify-content-around">
                                <div className="cus-medium-button-style button-details-alignment d-flex justify-content-center">
                                  <button
                                    onClick={(e) => {
                                      setShowCompition(true);
                                      setGameIdForCompitition(data?._id);
                                    }}
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    <img src={WinningIcon} alt={WinningIcon} />
                                    <img
                                      src={GameIcon}
                                      alt="GameIcon"
                                      className="child-img"
                                    />
                                    Add comp
                                  </button>
                                </div>
                                <div className="game-action-button-alignment d-flex justify-content-center">
                                  <button
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {data?.category}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className="d-flex dropdown justify-content-end"
                              onClick={(e) => {
                                setOpenAction(!openAction);
                                setDefineIndexKey(index);
                              }}
                            >
                              <div>
                                <svg
                                  width="4"
                                  height="16"
                                  cursor="pointer"
                                  viewBox="0 0 4 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.96721 3.93443C3.05367 3.93443 3.93443 3.05367 3.93443 1.96721C3.93443 0.880751 3.05367 0 1.96721 0C0.880751 0 0 0.880751 0 1.96721C0 3.05367 0.880751 3.93443 1.96721 3.93443Z"
                                    fill="#606060"
                                  />
                                  <path
                                    d="M1.96721 9.96763C3.05367 9.96763 3.93443 9.08688 3.93443 8.00042C3.93443 6.91395 3.05367 6.0332 1.96721 6.0332C0.880751 6.0332 0 6.91395 0 8.00042C0 9.08688 0.880751 9.96763 1.96721 9.96763Z"
                                    fill="#606060"
                                  />
                                  <path
                                    d="M1.96721 16.0008C3.05367 16.0008 3.93443 15.1201 3.93443 14.0336C3.93443 12.9472 3.05367 12.0664 1.96721 12.0664C0.880751 12.0664 0 12.9472 0 14.0336C0 15.1201 0.880751 16.0008 1.96721 16.0008Z"
                                    fill="#606060"
                                  />
                                </svg>
                              </div>
                              {defineIndeKey === index &&
                                openAction === true && (
                                  <ul className="dropdown-main">
                                    <li className="nav-items">
                                      <span
                                        className="active"
                                        onClick={(e) => {
                                          setIsEditApi(true);
                                          setAddGame(true);
                                          setGameIdForEdit(data?._id);
                                          setGameData({
                                            name: data?.name,
                                            pictures: data?.file,
                                            description: data?.description,
                                            category: data?.category,
                                            iosStoreLink: data?.iosStoreLink,
                                            androidStoreLink:
                                              data?.androidStoreLink,
                                            entryFee: data?.entryFee,
                                            geoSeries: data?.geoSeries,
                                          });
                                          setEditImg(data?.pictures);
                                        }}
                                      >
                                        Edit 
                                      </span>
                                      <span
                                        className="text-danger"
                                        onClick={(e) => {
                                          setgameIdForDelete(data?._id);
                                          setShow(true);
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </li>
                                  </ul>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
            <MyPagination pages={total} current={page} onClick={handlePage} />
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you delete this game, lost all the compititions data of this game ,
          are you sure that you want to delete ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deletegameData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addGame === true ? (
        <Dialog fullScreen open={addGame}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addGameModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {/* <h3>{getAllGame?.name}</h3> */}
            <div className="m-auto w-1000px pt-10">
              {isEditApi === false && (
                <div className="form-group row">
                  <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                    Name
                  </label>
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <input
                        type="text"
                        className={`form-control rounded-lg custom-style`}
                        id="title"
                        name="name"
                        value={gameData?.name}
                        onChange={(e) => {
                          onhandleChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div>
              )}
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Category
                </label>

                {getCategoryData && (
                  <div className="col-lg-7 col-xl-7">
                    <div>
                      <select
                        name="categoryId"
                        id="categoryId"
                        // defaultValue={gameData?.category}
                        defaultValue={getCategoryData?.find((data)=> data?.type == gameData?.category)?._id}
                        className="form-control rounded-lg custom-style"
                        aria-label="Default select example"
                        onChange={(e) => onhandleChange(e)}
                      >
                        <option selected disabled>
                          Select Category
                        </option>
                        {getCategoryData &&
                          getCategoryData?.map((data) => (
                            <option value={data?._id}>{data?.type}</option>
                          ))}
                      </select>

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userId"]}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {isEditApi === false ? (
                <>
                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      Pictures
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="file"
                          multiple
                          className={`form-control rounded-lg custom-style`}
                          name="file"
                          id="imageUpload"
                          onChange={(e) => {
                            handleOnChnageAddImg(e);
                          }}
                        />
                        {gameImage &&
                          gameImage?.map((imgs, i) => {
                            return (
                              <>
                                <img
                                  src={
                                    imgs?.slice(-5) === ".webp" &&
                                    imgs?.slice(-5) === ".jpeg" &&
                                    imgs?.slice(-5) === ".png" &&
                                    imgs?.slice(-5) === ".jpg"
                                      ? imgs
                                      : URL.createObjectURL(imgs)
                                  }
                                  alt=""
                                  // src={img}
                                  height="100px"
                                  width="100px"
                                />
                              </>
                            );
                          })}
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["file"]}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                      Edit Pictures
                    </label>
                    <div className="col-lg-7 col-xl-7">
                      <div>
                        <input
                          type="file"
                          multiple
                          className={`form-control rounded-lg custom-style`}
                          name="file"
                          id="imageUpload"
                          onChange={(e) => {
                            handleOnChnageAddImg(e);
                          }}
                        />
                        {gameImage && gameImage?.length > 0 ? (
                          <>
                            {gameImage?.map((imgs, i) => {
                              return (
                                <>
                                  <img
                                    src={
                                      imgs?.slice(-5) === ".webp" &&
                                      imgs?.slice(-5) === ".jpeg" &&
                                      imgs?.slice(-5) === ".png" &&
                                      imgs?.slice(-5) === ".jpg"
                                        ? imgs
                                        : URL.createObjectURL(imgs)
                                    }
                                    alt=""
                                    // src={img}
                                    height="100px"
                                    width="100px"
                                  />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {editImg?.map((imgs, i) => {
                              return (
                                <>
                                  <img
                                    src={imgs}
                                    alt=""
                                    // src={img}
                                    height="100px"
                                    width="100px"
                                  />
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["file"]}
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Description
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <textarea
                      className={`form-control rounded-lg custom-style`}
                      id="description"
                      name="description"
                      value={gameData?.description}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  iOS Store Link
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="iosStoreLink"
                      name="iosStoreLink"
                      value={gameData?.iosStoreLink}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["iosStoreLink"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Android Store Link
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="text"
                      className={`form-control rounded-lg custom-style`}
                      id="androidStoreLink"
                      name="androidStoreLink"
                      value={gameData?.androidStoreLink}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["androidStoreLink"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-5 col-lg-5 col-form-label label-alignment">
                  Entry Fees
                </label>
                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="number"
                      className={`form-control rounded-lg custom-style`}
                      id="entryFee"
                      name="entryFee"
                      value={gameData?.entryFee}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["entryFee"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="coinIcon"
                  className="col-xl-5 col-lg-5 col-form-label label-alignment"
                >
                  Geo-Series compatible{" "}
                </label>

                <div className="col-lg-7 col-xl-7">
                  <div>
                    <input
                      type="checkbox"
                      className={`form-control form-control-lg `}
                      id="geoSeries"
                      name="geoSeries"
                      value={gameData?.geoSeries}
                      defaultChecked={gameData?.geoSeries}
                      onChange={(e) => onhandleChange(e)}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["GeoSeries"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center button-details-alignment">
                    <button
                      className="btn position-relative center"
                      type="button"
                    >
                      <img src={AddGameIcon} alt={AddGameIcon} />

                      {isEditApi ? "Edit Game" : "Add Game"}
                    </button>
                  </div>
                </>
              ) : (
                <div className="button-details-alignment">
                  <button
                    className="btn"
                    onClick={(e) => {
                      isEditApi ? editgameData(e) : addgameData(e);
                    }}
                  >
                    <img src={AddGameIcon} alt={AddGameIcon} />
                    {isEditApi ? "Edit Game" : "Add Game"}
                  </button>
                </div>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}

      {showCompitition === true ? (
        <Dialog fullScreen open={showCompitition}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => compititionModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <>
              <Competition
                getAllGame={getAllGame}
                gameIdForCompitition={gameIdForCompitition}
              />
            </>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
