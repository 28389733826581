import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import ReservationsIcon from "../../_assets/plugins/keenthemes-icons/svg/Reservations_Icon.png";
import ReservationsIconComplete from "../../_assets/plugins/keenthemes-icons/svg/Reservations_Completed.png";
export default function GetOrderOfOwner(props) {
  const { storeOrderData } = props;
  const [getAllOrder, setGetAllOrder] = useState({});
  const [, setGetAllCompleteOrder] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  let item = localStorage.getItem("localStoreId");
  item = item && JSON.parse(item);
  useEffect(() => {
    getAllOrderData();
    getAllOrderCompleteData();
  }, [page, countPerPage]);

  const getAllOrderData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(`store/get-ordered-store-items?storeId=${item.trim()}`)
        .then((res) => {
          setGetAllOrder(res?.data?.payload);
          setCount(res?.data?.orderCount);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `store/get-ordered-store-items?storeId=${item.trim()}&letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllOrder(res?.data?.payload);
          setCount(res?.data?.orderCount);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const getAllOrderCompleteData = async () => {
    setLoaderForGetAll(true);
    await ApiGet(`order/find?status=complete&storeId=${item.trim()}`)
      .then((res) => {
        setGetAllCompleteOrder(res?.data?.payload);
        setCount1(res?.data?.count);
        setLoaderForGetAll(false);
      })
      .catch((err) => {
        setLoaderForGetAll(false);
      });
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "User Name",
      selector: (row) => row?.user?.username,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row?.user?.email,
      sortable: true,
    },

    {
      name: "Item  Name",
      selector: (row) => row?.Item[0].itemId.itemName,
      sortable: true,
    },
    {
      name: "Item Price",
      selector: (row) => row?.Item[0].itemId.itemPrice,
      sortable: true,
    },
    {
      name: "Item quentity",
      selector: (row) => row?.itemCount,
      sortable: true,
    },
    {
      name: "Total Price",
      selector: (row) => row?.totalAmount,
      sortable: true,
    },
    {
      name: "Item Image",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img
                src={row?.Item[0]?.itemId?.storeItemImage[0]}
                width={100}
                height={50}
              />
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <>
            {row?.status === "pending" && (
              <div
                style={{
                  background: "yellow",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  {row?.status}
                </p>
              </div>
            )}
            {row?.status === "canceled" && (
              <div
                class="btn"
                style={{
                  background: "red",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  {row?.status}
                </p>
              </div>
            )}
            {row?.status === "complete" && (
              <div
                style={{
                  background: "#22cc00",
                  height: "50px",
                  width: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0",
                  }}
                >
                  {row?.status}
                </p>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => (row?.address ? row?.address : "-"),
      sortable: true,
    },
    {
      name: "city",
      selector: (row) => (row?.city ? row?.city : "-"),
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => (row?.state ? row?.state : "-"),
      sortable: true,
    },
  ];

  return (
    <>
      <div className="card">
        <ToastContainer />

        <div className="heading-banner d-flex justify-content-center">
          <div className="align-items-center d-flex">
            <img
              src={ReservationsIcon}
              alt="ReservationsIcon"
              className="coupon"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">{count}</h1>
              <h6 className="text-white">All Reservation</h6>
            </div>
          </div>

          <div className="align-items-center d-flex ml-40">
            <img
              src={ReservationsIconComplete}
              alt="ReservationsIconComplete"
              className="coupon"
            />
            <div className="client-details pl-2">
              <h1 className="text-white">{count1}</h1>
              <h6 className="text-white">Complete Reservation</h6>
            </div>
          </div>
        </div>

        <div className="p-2 mb-2 pt-10">
          <div className="row mb-4 pr-3 justify-content-between">
            <div className="d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Reservations</h2> */}
            </div>
            <div className="">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>

        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={getAllOrder}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
    </>
  );
}
