import React from "react";
import { useState, useEffect } from "react";
import { ApiGet } from "../../../helpers/API/ApiData";
import { ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";

export default function StoreWiseOrderData(props) {
  const { storeOrderData, showMoreStoreData } = props;
  const [getAllOrder, setGetAllOrder] = useState({});
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);

  useEffect(() => {
    getAllOrderData();
  }, [page, countPerPage]);

  const getAllOrderData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(
        `/store/get-ordered-store-items?storeId=${storeOrderData?._id}&${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllOrder(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(
        `store/get-ordered-store-items?storeId=${storeOrderData?._id}&letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setGetAllOrder(res?.data?.payload);
          setCount(res?.data?.count);
          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllOrderData();
    }
  }, [debouncedSearchTerm]);

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
      name: "item name",
      selector: (row) => row?.Item[0]?.itemId?.itemName,
      sortable: true,
    },
    {
      name: "Description",
      cell: (row) => {
        return (
          <div className="showmore-class  p-2 ">
            {row?.Item[0]?.itemId?.itemDescription?.length > 200
              ? row?.Item[0]?.itemId?.itemDescription?.substring(0, 200) + "..."
              : row?.Item[0]?.itemId?.itemDescription}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "item Price",
      selector: (row) => row?.Item[0]?.itemId?.itemPrice,
      width: "8%",
    },
    {
      name: "item Quentity",
      selector: (row) => row?.Item[0]?.qty,
      width: "5%",
    },
    {
      name: "Total Price",
      selector: (row) => row?.Item[0]?.totalPrice,
      width: "8%",
    },
    {
      name: "Itam Image",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img
                src={row?.Item[0]?.itemId?.storeItemImage[0]}
                width={100}
                height={50}
              />
            </div>
          </>
        );
      },
      width: "11%",
    },
    {
      name: "User Name",
      selector: (row) => row?.user?.username,
      sortable: true,
    },
    {
      name: "User Email",
      selector: (row) => row?.user?.email,
      sortable: true,
    },

    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
  ];

  return (
    <div>
      <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Order Details</h2>
            </div>
            <div className="col-lg-3 col-xl-3">
              <div>
                <input
                  type="text"
                  className={`form-control rounded-lg custom-style `}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height"></div>
          </div>

          {loaderForGetAll  ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
            columns={columns}
            data={getAllOrder}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
          )}
        </div>
      </div>
    </>
    </div>
  );
}
